<template>
    <div class="level-right columns is-vcentered">
        <div class="level-item field"> 
            <input id="showAllDataFlag" type="checkbox" @change="updateShowAllDataFlag(showAllDataFlag)"
            v-model="showAllDataFlag" name="showAllData" class="switch is-large is-rtl is-rounded">
            <label for="showAllDataFlag" class="is-relative">Available only</label>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {                    
                showAllDataFlag: false
            };
        },
        props: ['model'],
        methods: {
            updateShowAllDataFlag(notAvailableOnlyData) {
                this.$emit('update-only-available-flag', notAvailableOnlyData);
            }  
        }
    }
</script>