var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "itinerary-dayHighlights" },
    [
      _c("h4", { staticClass: "itinerary-dayHighlights__title" }, [
        _vm._v("Highlights"),
      ]),
      _vm._v(" "),
      _vm._l(_vm.dayHighlights, function (highlights) {
        return _c("div", { staticClass: "itinerary-dayHighlights__item" }, [
          highlights.imageUrls.length > 0
            ? _c(
                "div",
                { staticClass: "itinerary-dayHighlights__item-image" },
                [
                  _c("img", {
                    attrs: {
                      src: highlights.imageUrls[0],
                      alt: "highlights.title",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "itinerary-dayHighlights__item-desc" }, [
            _c("h4", { staticClass: "itinerary-dayHighlights__item-title" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(highlights.title) + "\n\t\t\t"),
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "itinerary-dayHighlights__item-text",
              domProps: { innerHTML: _vm._s(highlights.text) },
            }),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }