import * as searchConstants from '../../search-constants.js';
// function purpose is to construct a top level search term from the various places in the app
// filterSearch calls this
// exitToMainSearch also calls
function constructSearchTerm() {
    let searchTerm = this.$data.settings.currentSearchPrefix;

    if(this.$data.settings.currentSearchPrefix === searchConstants.BROCHURECODESSEARCHTERM && this.$data.settings.currentDestinations.length > 0) {
        // added in this facility to search on multiple top level params for brochure codes & destinations
        searchTerm += this.$data.currentBrochureCodes + '&' + searchConstants.DEFAULTSEARCHTERM + this.$data.settings.currentDestinations;
    } 
    else if (this.$data.settings.currentSearchPrefix === searchConstants.BROCHURECODESSEARCHTERM && this.$data.settings.currentContinent.length > 0) {
        //also ability to search brochure codes and a continent
        searchTerm +=  this.$data.currentBrochureCodes + '&' + searchConstants.REGIONSSEARCHTERM + this.$data.settings.currentContinent;
    } 
    else if(this.$data.settings.currentSearchPrefix === searchConstants.BROCHURECODESSEARCHTERM) {
        // for brochure codes alone
        searchTerm +=  this.$data.currentBrochureCodes;
    } 
    else if (this.$data.settings.currentSearchPrefix === searchConstants.REGIONSSEARCHTERM) {
    // if we are in region search
        searchTerm +=  this.$data.settings.currentContinent;
    } 
    else {
        // here we are in destination search
        // construct the search term base on the current prefix, and the current destinations
        searchTerm +=  this.$data.settings.currentDestinations;
    }
    return searchTerm;
}
module.exports = constructSearchTerm;