import Vue from 'vue';
import SecundaryMenu from '../vue-components/generic/secondary-menu.vue';
function secundaryMenuView() {
    new Vue({
        el: '#secundary-menu',
        data() {
            return {
                datamenu: window.Cms.subNavModel,
            };
        },
        components: {
            'secundary-menu': SecundaryMenu,
        }
    });
};
module.exports = secundaryMenuView;