import * as searchConstants from '../../search-constants.js';
function getSelectedMonths(selectedMonthsArray) {
    this.$data.settings.filtersValues.monthsVal = selectedMonthsArray;
    this.setMonthFilterText(selectedMonthsArray);
    
    if(selectedMonthsArray.length > 0) {
        // receive selected months array from month cal and converts to string
        let monthsString = selectedMonthsArray.join();
        // pass the string to the data for use in search
        this.$data.settings.filters.monthsSelected = searchConstants.MONTHSSELECTEDPREFIX + monthsString;
    } else {

        this.$data.settings.filters.monthsSelected = false;
    }
}
module.exports = getSelectedMonths;
