<template>
	<div>
		<section
			class="brands subsection"
			v-for="brandSection in brandsdata">
			<div class="site-section-header">
				<div class="site-section-headerTop">
					<h2
						class="site-section-title"
						v-if="brandSection.title">
						{{ brandSection.title }}
					</h2>
					<p
						class="site-section-subtitle"
						v-if="brandSection.subTitle">
						{{ brandSection.subTitle }}
					</p>
				</div>
				<div class="brands__cta">
					<a
						data-type="general interaction"
						data-name="button"
						class="button is-primary is-fixed-width cta"
						target="_self"
						:href="brandSection.linkUrl"
						>{{ brandSection.linkName }}</a
					>
				</div>
			</div>

			<div class="brands__content">
				<div
					data-type="navigation"
					data-name="brand-pod"
					class="brands__content-item is-full-mobile"
					v-for="brand in brandSection.brands">
					<a
						:href="brand.brandLinkUrl"
						:aria-label="brand.brandLinkName"
						target="_self">
						<div class="brands__content-section">
							<img
								class="brands__content-image"
								:src="brand.brandLogoUrl"
								:alt="
									brand.brandLogoAltText
										? brand.brandLogoAltText
										: brand.brandLinkName
								" />
							<button
								class="button is-primary brands__content-cta">
								{{ brandSection.hoverButton }}
							</button>
						</div>
						<div
							class="brands__content-copy"
							v-if="brand.brandDescriptionText">
							<p>{{ brand.brandDescriptionText }}</p>
						</div>
					</a>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	props: ["brandsdata"],
};
</script>
