import * as searchConstants from '../../search-constants.js';

function setDurationFilterText(valObj) {
    // function expects an object with 1 or 2 values minDuration, and or maxDuration
    // if those values are set in the object
    // we set the duration filter text ( button ) and it's associated vales to those received
    let filterText = '';
    if (!valObj.maxDuration) {
        filterText += 'Min-Days: ';
    }
    if (!valObj.minDuration) {
        filterText += 'Max-Days: ';
    }
    if (valObj.minDuration && !valObj.maxDuration) {

        filterText += valObj.minDuration + searchConstants.FILTERTEXTDAYS;
    }
    if (valObj.maxDuration && !valObj.minDuration) {
        filterText += valObj.maxDuration + searchConstants.FILTERTEXTDAYS;
    }
    if (valObj.minDuration && valObj.maxDuration) {
        filterText += valObj.minDuration + searchConstants.FILTERTEXTDAYS + ' - ' + valObj.maxDuration + searchConstants.FILTERTEXTDAYS;
    }
    // if both values are undefined, set the durationText val to false
    if (!valObj.minDuration && !valObj.maxDuration) {
        this.$data.settings.filtersText.durationText = false;
        // or as per the string created above in this function
    } else {
        this.$data.settings.filtersText.durationText = filterText;
    }
}
module.exports = setDurationFilterText;