var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal modal-geq modal-map",
      class: { "is-active": _vm.active },
      attrs: { id: "mapmodal" },
    },
    [
      _c("div", {
        staticClass: "modal-background",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.closeForm()
          },
        },
      }),
      _vm._v(" "),
      !_vm.mapTypeImage
        ? _c("div", {
            staticClass: "modal-card modal-map modal-map-image",
            style: { backgroundImage: "url(" + _vm.mapurl + ")" },
            attrs: { alt: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.mapTypeImage
        ? _c("img", {
            staticClass: "fullimage",
            class: _vm.tourtype.isV4Tour
              ? "v4map"
              : _vm.tourtype.isC1Tour
              ? "c1Map"
              : "",
            attrs: { src: _vm.mapurl, alt: _vm.itinerarymodel?.mapImageAltTag },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "modal-card-head modal-card-close",
          attrs: { href: "#", "aria-label": "close" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeForm()
            },
          },
        },
        [_c("i", { staticClass: "fa fa-times fa-3x" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }