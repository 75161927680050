import Vue from 'vue';
import HeroGeneric from '../../vue-components/heros/hero-generic.vue';
function heroGenericView() {
    new Vue({
        el: '#hero-generic-section',
        data () {
            return {
                heroModel: window.Cms.heroModel
            };
        },
        components: {
            'hero-generic': HeroGeneric,
        }
    });
};
module.exports = heroGenericView;