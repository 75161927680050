var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "special-offers-filter is-flex slider-toggle",
      class: { disabled: _vm.settings.loading },
    },
    [
      _c(
        "label",
        {
          staticClass: "switch inline-center is-relative",
          attrs: { for: "special-offers", "aria-labelledby": "special offers" },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isSpecialOffers,
                expression: "isSpecialOffers",
              },
            ],
            attrs: {
              type: "checkbox",
              id: "special-offers",
              name: "specialOffers",
            },
            domProps: {
              checked: Array.isArray(_vm.isSpecialOffers)
                ? _vm._i(_vm.isSpecialOffers, null) > -1
                : _vm.isSpecialOffers,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.isSpecialOffers,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isSpecialOffers = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isSpecialOffers = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isSpecialOffers = $$c
                  }
                },
                function ($event) {
                  return _vm.setSpecialOffersFlag($event)
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "slider round" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "inline-center",
          class: { filterChecked: _vm.filterCheckbox },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.settings.filterTripsContent.specialOffersDisplayText
                  ? _vm.settings.filterTripsContent.specialOffersDisplayText
                  : "Special Offers"
              ) +
              "\n\t"
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }