<template>
	<div class="secondarySubnav-wrapper">
		<div class="subnav-list">
			<vue-horizontal class="subnav-list-wrap horizontal">
				<template v-slot:btn-prev>
					<span class="direction-btn btn-prev"></span>
				</template>
				<template v-slot:btn-next>
					<span class="direction-btn btn-next"></span>
				</template>

				<div
					class="subnav-listItem"
					v-for="(item, index) in datamenu.navItems"
					:key="index">
					<a
						:href="'#' + item.url"
						data-name="in-page-menu"
						:data-target="item.url"
						class="subnav-listItem__link"
						>{{ item.name }}</a
					>
				</div>
			</vue-horizontal>
		</div>
	</div>
</template>

<script>
import VueHorizontal from "vue-horizontal";
export default {
	props: ["datamenu"],
	data() {
		return {};
	},
	components: {
		VueHorizontal,
	},
};
</script>
