import Vue from 'vue';

import VueDeals from '../vue-components/deals/deals-list.vue';

function websiteOngoingDeals() {
    new Vue({
        el: '#app-ongoing-deals',
        data: window.Cms.deals,
        components: {
            'vue-deals': VueDeals,
        },
        beforeCreate: function () {
            if(typeof this.ongoingDeals === 'undefined')
            {
                this.ongoingDeals = {};
            }
        }
    });
};
module.exports = websiteOngoingDeals;