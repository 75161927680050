<template>
  <div class="tourOptions_wrap">
    <div class="tourOptions-content-wrap">
      <div class="tourOptions-option-header">
        <p class="tourOptions-option-header-title">Trip</p>
        <p class="tourOptions-option-header-price">From Price</p>
        <p class="tourOptions-option-header-selected"></p>
      </div>
      <div
        class="tourOptions-option"
        v-for="(
          { tourId, tripName, price, originalPrice, tourOptionId, link }, index
        ) in tourOptionsData.slice(0, count)"
        :key="index"
        :data-tripId="tourId"
        tabindex="0"
        @click="sendTourId(tourOptionId, link)"
        @keyup.enter="sendTourId(tourOptionId, link)"
      >
        <div
          class="tourOptions"
          :class="{ 'is-selected ': isSelected === tourOptionId }"
        >
          <div class="tourOptions-data">
            <p class="tourOptions-title">{{ tripName }}</p>
            <p class="tourOptions-price">{{ price }}<br/><span class="strikethrough" v-if="originalPrice">{{ originalPrice }}</span></p>
          </div>
          <div class="tourOptions-status">
            <span class="icon-status">
              <i class="fa fa-check"></i>
            </span>
          </div>
        </div>
        <div
          class="tourFilters-section__loader"
          v-if="isSelected === tourOptionId && tourLoading"
        >
          <div class="loader-bar"></div>
        </div>
      </div>
    </div>
    <div v-if="tourOptionsData.length == 0" class="tourOptions__nodata">
      Looks like we couldn't find any trips matching your search criteria.
      Please try again.
    </div>
    <button
      class="tourOptions__showmore"
      @click="showMore()"
      v-if="tourOptionsData.length > count"
    >
      Load more...
    </button>
  </div>
</template>

<script>
export default {
  props: ["touroptions", "count", "tourLoading"],
  data() {
    return {
      isSelected: window.Cms?.raqModal.tour.tourOptionId || null,
      tourUpdated: false,
    };
  },
  methods: {
    sendTourId(tourOptionId, tourLink) {
      this.isSelected = tourOptionId;
      this.$emit("tourId", tourLink);
      this.tourUpdate = true;
    },
    showMore() {
      this.count += 5;
    },
  },
  computed: {
    tourOptionsData: function () {
      let withSelectedTourOptionId = this.touroptions.filter((item)=>item.tourOptionId==this.isSelected);
      let withOutSelectedTourOptionId = this.touroptions.filter((item)=>item.tourOptionId!=this.isSelected);
      return [...withSelectedTourOptionId,...withOutSelectedTourOptionId];
    },
  }, 
   mounted() {
    
   }
};
</script>
