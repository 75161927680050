<template>
  <div
    class="modal modal-geq"
    id="geqmodalterms"
    :class="{ 'is-active': active }"
  >
    <div class="modal-background" @click.prevent="closeForm()"></div>
    <div class="modal-card">
      <div class="modal-form">
        <div class="modal-card-head">
          <p class="modal-card-title" v-text="title"></p>
          <a
            class="modal-card-close bg-transparent"
            href="#"
            @click.prevent="closeForm()"
            aria-label="close"
          >
            <i class="fa fa-times fa-lg"></i>
          </a>
        </div>
        <form @submit.prevent="validateBeforeSubmit">
          <section class="modal-card-body">
            <h3 v-text="labels.labelTerms"></h3>
            <br />
            <div v-html="terms"></div>
          </section>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../../vue-functions/event-bus";
import { closeModal } from "../../../js/modals/closeModal";
export default {
  props: ["labels"],
  data() {
    return {
      active: false,
      terms: "",
      title: "",
    };
  },
  methods: {
    show() {
      this.active = true;
    },
    closeForm() {
      this.submitted = false;
      this.active = false;
      document.documentElement.classList.remove("is-clipped");
      document
        .querySelector(".modal-card-body")
        .parentElement.classList.remove("scroll-more");
    },
    closeModal,
  },
  created: function() {
    EventBus.$on("TOGGLE_TERMS_MODAL", (terms, title) => {
      this.terms = terms;
      this.title = title;
      document.documentElement.classList.add("is-clipped");
      this.show();
    });
  },
  mounted() {
    this.closeModal(this.closeForm);
  },
};
</script>
