<template>
    <button 
        v-if="!settings.filtersLocked" 
        aria-label="MainSearch"                            
        @click.prevent="resetFiltersClicked()" 
        class="searchbar-reset-action">
        {{settings.filterTripsContent.clearAllText}}
    </button>
</template>
<script>
    export default {
        props:{
            settings: {
                type: Object
            }
        },
        methods: {
            resetFiltersClicked() {
                this.$emit('reset-all-filters')
            }
        }
    }
</script>