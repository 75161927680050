var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "highlitghts" }, [
    this.dayHighlights.length > 0
      ? _c(
          "div",
          { staticClass: "highlitghts__daylights" },
          [
            _c(
              "carousel-3d",
              {
                attrs: {
                  count: this.dayHighlights.length,
                  "controls-visible": true,
                  "controls-prev-html": "prev",
                  "controls-next-html": "next",
                  "controls-width": 30,
                  "controls-height": 60,
                  autoplay: true,
                  clickable: false,
                  space: 1300,
                  "inverse-scaling": 1800,
                  width: 640,
                  height: 400,
                  perspective: 0,
                  display: 3,
                  border: 0,
                  loop: true,
                },
                on: { "after-slide-change": _vm.onAfterSlideChange },
              },
              _vm._l(this.dayHighlights, function (item, i) {
                return _c("slide", { attrs: { index: i } }, [
                  _c("figure", [
                    _c("img", { attrs: { src: item.url, alt: item.alt } }),
                  ]),
                ])
              }),
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "highlitghts__daylights__bullets" }, [
              _c(
                "button",
                { staticClass: "prev", on: { click: _vm.prevClick } },
                [_c("i", { staticClass: "fas fa-arrow-left" })]
              ),
              _vm._v(" "),
              this.dayHighlights.length <= 9
                ? _c(
                    "ul",
                    _vm._l(this.dayHighlights, function (slide, i) {
                      return _c("li", { attrs: { index: i } }, [
                        _vm._v("\n\t\t\t\t\t" + _vm._s(i) + "\n\t\t\t\t"),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              this.dayHighlights.length > 9
                ? _c(
                    "div",
                    { staticClass: "highlitghts__daylights__bullets-roller" },
                    [
                      _c("div", { staticClass: "bg" }),
                      _vm._v(" "),
                      _c("span", { style: { width: _vm.width + "%" } }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "next", on: { click: _vm.nextClick } },
                [_c("i", { staticClass: "fas fa-arrow-right" })]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }