<template>
	<section class="generic-banner subsection container">
		<div
			class="has-text-centered section"
			v-if="bannerdata?.showBanner"
			:class="[
				bannerdata?.brandLogoHasValue &&
				!bannerdata?.bannerBackgroundColour
					? `${bannerdata?.brandUrlSegment}`
					: 'partner-banner',
			]"
			:style="{
				backgroundColor: '#' + bannerdata?.bannerBackgroundColour,
			}">
			<h2
				class="hero-marketing-banner__header"
				:style="{ color: '#' + bannerdata?.bannerTextColour }"
				v-if="bannerdata?.bannerHeaderText">
				{{ bannerdata?.bannerHeaderText }}
			</h2>
			<p
				class="hero-marketing-banner__subheader has-text-weight-normal"
				:style="{ color: '#' + bannerdata?.bannerTextColour }"
				v-if="bannerdata?.bannerSubHeaderText">
				{{ bannerdata?.bannerSubHeaderText }}
			</p>
		</div>
	</section>
</template>
<script>
export default {
	data() {
		return {};
	},
	props: ["bannerdata"],
};
</script>
