<template>
	<div>
		<div
			class="highlitghts"
			v-if="raqmodel.tour.isC1Tour || raqmodel.tour.isV4Tour">
			<div
				v-if="highlitghts.length > 0"
				id="highlights-section"
				class="highlitghts__wrapper">
				<h3 class="highlitghts__wrapper-title">
					{{ dictionarymodel.highlights }}
				</h3>
				<div class="highlitghts__box">
					<div
						class="highlitghts__box-column"
						:class="{ columnOne: highlitghts.length === 1 }"
						v-for="({ type, highlitghts }, index) in highlitghts">
						<h4
							v-if="
								!(
									highlitghts[0].text == '' &&
									highlitghts.length == 1
								) && type
							">
							{{ type }}
						</h4>
						<ul>
							<li v-for="({ title, text }, index) in highlitghts">
								<i
									v-if="text"
									class="fas fa-check"
									:class="[
										type.replace(' ', '').toLowerCase() ===
										'iconicexperience'
											? 'fa-map-marker'
											: 'fa-check',
									]"
									aria-hidden="true"></i>
								<div>
									<p
										v-if="
											title &&
											type
												.replace(' ', '')
												.toLowerCase() ===
												'iconicexperience'
										"
										class="strong-text">
										<strong>{{ title }} </strong>
									</p>
									<div v-html="text"></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div
			class="highlitghts"
			v-if="raqmodel.tour.isCmsTour">
			<div
				v-if="highlitghts.length > 0"
				id="highlights-section"
				class="highlitghts__wrapper">
				<h3 class="highlitghts__wrapper-title">
					{{ dictionarymodel.highlights }}
				</h3>
				<div class="highlitghts__box">
					<div
						class="highlitghts__box-column"
						:class="{ columnOne: highlitghts.length === 1 }"
						v-for="({ type, highlitghts }, index) in highlitghts">
						<h4
							v-if="
								!(
									highlitghts[0].text == '' &&
									highlitghts.length == 1
								) && type
							">
							{{ type }}
						</h4>
						<ul>
							<li v-for="({ title, text }, index) in highlitghts">
								<i
									v-if="text"
									class="fas fa-check"
									aria-hidden="true"></i>
								<div>
									<p
										v-if="title"
										class="subtitle">
										{{ title }}
									</p>
									<div
										v-if="!title"
										v-html="text"></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["dictionarymodel", "highlitghts", "raqmodel"],
	components: {},
};
</script>
