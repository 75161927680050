import Vue from 'vue';
import HeroBrand from '../../vue-components/heros/hero-brand.vue';
function heroBrandView() {
    new Vue({
        el: '#hero-brand-section',
        data () {
            return {
                herobranddata: window.Cms.heroModel
            };
        },
        components: {
            'hero-brand': HeroBrand,
        }
    });
};
module.exports = heroBrandView;