<template>
	<div class="itinerary-dayHighlights">
		<h4 class="itinerary-dayHighlights__title">Highlights</h4>
		<div
			class="itinerary-dayHighlights__item"
			v-for="highlights in dayHighlights">
			<div
				v-if="highlights.imageUrls.length > 0"
				class="itinerary-dayHighlights__item-image">
				<img
					:src="highlights.imageUrls[0]"
					alt="highlights.title" />
			</div>
			<div class="itinerary-dayHighlights__item-desc">
				<h4 class="itinerary-dayHighlights__item-title">
					{{ highlights.title }}
				</h4>
				<p
					v-html="highlights.text"
					class="itinerary-dayHighlights__item-text"></p>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["dayHighlights"],
	data() {
		return {};
	},
	methods: {},
};
</script>
