<template>
  <div class="modal modal-geq" id="ftcmodal" :class="{ 'is-active': active }">
    <div class="modal-background" @click.prevent="closeForm()"></div>
    <div class="modal-card">
      <div class="modal-form" v-show="!submitted">
        <div class="modal-card-head">
          <p class="modal-card-title">{{ labels.modalTitle }}</p>
          <a
            class="modal-card-close bg-transparent"
            href="#"
            @click.prevent="closeForm()"
            aria-label="close"
          >
            <i class="fa fa-times fa-lg"></i>
          </a>
        </div>
        <div class="modal-card-image">
          <img
            class="is-background"
            :src="labels.backgroundImage"
            :alt="
              labels.backgroundImageAltText
                ? labels.backgroundImageAltText
                : 'modal-background-image'
            "
          />
        </div>
        <form @submit.prevent="validateBeforeSubmit">
          <section class="modal-card-body">
            <h2 v-text="labels.modalHeading"></h2>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label" :class="{'required-label': labels.isFirstNameMandatory}">
                    {{ labels.labelFirstname }}
                  </label>
                  <div class="control">
                    <input
                        v-model="quoterequest.firstname"
                        :data-vv-as="labels.labelFirstname"
                        id="ftcfirstname"
                        aria-label="labelfirstname"
                        name="firstname"
                        :v-validate="firstNameValidationRules"
                        class="input input-subtle"
                        type="text"
                    />
                    <span
                      class="field-validation-error"
                      v-if="errors.has('firstname')"
                      >{{ errors.first("firstname") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label required-label">{{ labels.labelLastname }}</label>
                  <div class="control">
                    <input
                      v-model="quoterequest.lastname"
                      :data-vv-as="labels.labeLastname"
                      id="ftclastname"
                      aria-label="labellastname"
                      name="lastname"
                      v-validate="'required'"
                      class="input input-subtle"
                      type="text"
                    />
                    <span
                      class="field-validation-error"
                      v-if="errors.has('lastname')"
                      >{{ errors.first("lastname") }}</span
                    >
                  </div>
                </div>
              </div>
              <input
                v-text="quoterequest.toemailaddress"
                id="toemailaddress"
                name="toemailaddress"
                type="hidden"
              />
              <input
                v-text="quoterequest.ccemailaddress"
                id="ccemailaddress"
                name="ccemailaddress"
                type="hidden"
              />
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label required-label">{{ labels.labelEmail }}</label>
                  <div class="control">
                    <input
                      v-model="quoterequest.email"
                      :data-vv-as="labels.labelEmail"
                      id="ftcEmail"
                      aria-label="labelEmail"
                      name="Email"
                      v-validate="'required|email'"
                      class="input input-subtle"
                      type="email"
                      novalidate
                    />
                    <span
                      class="field-validation-error"
                      v-if="errors.has('Email')"
                      >{{ errors.first("Email") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label" v-text="labels.labelPhone"></label>
                  <div class="control">
                    <input
                      v-model="quoterequest.phone"
                      v-bind:placeholder="labels.placeholderOptional"
                      id="ftcPhone"
                      aria-label="labelPhone"
                      name="Phone"
                      class="input input-subtle"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
             <div class="columns" v-show="labels.showZipCode">
							<div class="column">
								<div class="field">
									<label class="label required-label">
                    {{ labels.labelZipOrPostalCode }}
                  </label>
									<div class="control">
										<input
											v-model="quoterequest.zipCode"
											id="zipCode"
											name="zipCode"
											aria-label="labelZipCode"
											v-validate="zipCodeValidationRules"
											class="input input-subtle" />
                      <input
                        v-text="labels.showZipCode"
                        id="showZipCode"
                        name="showZipCode"
                        type="hidden"
                      />
										<span
											class="field-validation-error"
											v-if="errors.has('zipCode')"
											>{{ errors.first("zipCode") }}</span
										>
									</div>
								</div>
							</div>
							<div class="column">
							</div>
						</div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">{{ labels.labelComments }}</label>
                  <div class="control">
                    <textarea
                      v-model="quoterequest.comments"
                      type="text"
                      aria-label="labels.placeholderComments"
                      :placeholder="labels.placeholderComments"
                      class="input input-subtle"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="modal-card-foot">
            <button
              type="submit"
              class="button is-secondary show-confirm">
              {{ labels.submitButton }}
            </button>
          </div>
        </form>
      </div>
      <div class="modal-confirm" id="model-confirm-ftc" v-show="submitted">
        <div class="modal-card-head">
          <p class="modal-card-title">{{ labels.modalTitle }}</p>
        </div>
        <div class="modal-card-image">
          <img
            :src="labels.submittedBackgroundImage"
            :alt="
              labels.submittedBackgroundImageAltText
                ? labels.submittedBackgroundImageAltText
                : 'modal-confirm-image'
            "
            class="is-background"
          />
          <div class="card-content">
            <h2 v-show="!submittederror">{{ labels.submittedTitle }}</h2>
            <h2 v-show="submittederror">{{ labels.submittedErrorTitle }}</h2>
          </div>
        </div>
        <div class="modal-card-body">
          <p v-show="!submittederror">{{ labels.submittedText }}</p>
          <p v-show="submittederror">{{ labels.submittedErrorText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../../vue-functions/event-bus";
import axios from "axios";
import { closeModal } from "../../modals/closeModal";
export default {
  data() {
    return {
      active: false,
      modal: false,
      submitted: false,
      submittederror: false,
      quoterequest: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        zipCode : "",
        showZipCode: false,
        comments: "",
        toemailaddress: "",
        ccemailaddress: ""
      },
    };
  },
  props: ["labels", "api", "pageid", "url", "toemailaddress", "ccemailaddress"],
  methods: {
    validateBeforeSubmit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.handleSubmit();
        }
      });
    },
    show() {
      this.active = true;
    },
    submittedForm() {
      this.submitted = true;
    },
    closeForm() {
      document.documentElement.classList.remove("is-clipped");
      this.submitted = false;
      this.active = false;
    },
    handleSubmit() {
      self = this;
      axios
        .post(this.api, this.quoterequest, {
          headers: { "Content-Type": "application/json" },
        })
        .then(function(response) {
          self.submittederror = false;
          self.submittedForm();
        })
        .catch(function(error) {
          self.submittederror = true;
          self.submittedForm();
        });
    },
    closeModal,
    dataLayer(){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "FTC Modal Submitted" });
    }
  },
  computed: {
    firstNameValidationRules() {
      return this.labels.isFirstNameMandatory ? 'required|max:50' : 'max:50';
    },
    zipCodeValidationRules() {
			return this.labels.showZipCode ? 'required|alpha_dash|max: 9' : 'alpha_dash|max: 9';
		},
  },
  created: function() {
    EventBus.$on("TOGGLE_FTC_MODAL", () => {
      document.documentElement.classList.add("is-clipped");
      this.show();
    });

    this.quoterequest.toemailaddress = this.toemailaddress;
    this.quoterequest.ccemailaddress = this.ccemailaddress;
    this.quoterequest.showZipCode = this.labels.showZipCode;
    console.log("request  ", this.quoterequest);
  },
  mounted() {
    this.closeModal(this.closeForm);
  },
  watch: {
    submitted() {
      this.dataLayer()
    },
  },
};
</script>