// function to enable small screen hide / show for search filters as per UI design
function searchFilterToggle () {

    const filterToggleBtn = document.querySelector('.searchbar-mobile-toggle-btn');
    const filterParent = document.querySelector('.searchbar-inner');
    const chevronIcon = document.querySelector('.mobile-toggle-chevron-icon');

    if(filterParent){
        const activeClass = 'is-active';
        const chevronDownClass = 'fa-chevron-down';
        const chevronUpClass = 'fa-chevron-up';

        // set the filters to be open by default
        filterParent.classList.add(activeClass);

        filterToggleBtn.addEventListener('click', () => {
            filterParent.classList.toggle(activeClass);            
            if (chevronIcon.classList.contains(chevronDownClass)) {
                chevronIcon.classList.remove(chevronDownClass);
                chevronIcon.classList.add(chevronUpClass);
            } else {
                chevronIcon.classList.add(chevronDownClass);
                chevronIcon.classList.remove(chevronUpClass);
            }
        });
    }
}
module.exports = searchFilterToggle;