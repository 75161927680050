var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ttc-vue-slider vue-price-slider" },
    [
      _c("vue-slider", {
        ref: "priceslider",
        attrs: {
          show: _vm.pslider,
          tooltip: _vm.tooltip,
          width: _vm.width,
          "dot-size": _vm.dotSize,
          min: _vm.min,
          max: _vm.max,
          "use-keyboard": _vm.keyboard,
        },
        on: { callback: _vm.passValue },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "rangefromto" }, [
        _c("div", { staticClass: "rangefrom" }, [
          _c("span", { staticClass: "range-currency" }, [
            _vm._v(_vm._s(_vm.currency)),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value[0],
                expression: "value[0]",
              },
            ],
            staticClass: "input has-value",
            attrs: {
              type: "text",
              maxlength: "5",
              oninput: "this.value=this.value.replace(/[^0-9]/g,'');",
              id: "pricefrom",
              "aria-label": "StartPrice",
              min: this.min,
              max: this.max,
            },
            domProps: { value: _vm.value[0] },
            on: {
              change: _vm.passValue,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.value, 0, $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "rangeto" }, [
          _c("span", [_vm._v(_vm._s(_vm.currency))]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value[1],
                expression: "value[1]",
              },
            ],
            staticClass: "input has-value",
            attrs: {
              type: "text",
              maxlength: "5",
              oninput: "this.value=this.value.replace(/[^0-9]/g,'');",
              id: "priceto",
              "aria-label": "PriceUpto",
              min: this.min,
              max: this.max,
            },
            domProps: { value: _vm.value[1] },
            on: {
              change: _vm.passValue,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.value, 1, $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.value[1] == this.max,
                  expression: "value[1] == this.max",
                },
              ],
              staticClass: "vue-price-slider-max",
            },
            [_vm._v("+")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }