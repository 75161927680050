const debounce = require('lodash.debounce');
function searchAppResize (){
    window.addEventListener('resize', function() {
        debounce(resetSliders, 200);
    });
    function resetSliders() {
        this.$refs.durationslider.refresh();
        this.$refs.priceslider.refresh();
    }
}
module.exports = searchAppResize;