var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.targetel
        ? _c(
            "portal",
            {
              attrs: {
                name: _vm.name,
                "target-el": "#" + _vm.targetel,
                slim: "",
              },
            },
            [
              _c("button", {
                staticClass: "button is-subtle cta",
                attrs: { "data-type": "booking interest", "data-name": "raq" },
                domProps: { textContent: _vm._s(_vm.labels.button) },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    ;[
                      _vm.toggleModal(
                        _vm.tour,
                        _vm.tourselecteddate,
                        _vm.tourdepartureid,
                        $event
                      ),
                      _vm.dataLayerRAQOpen(),
                    ]
                  },
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.targetel
        ? _c("button", {
            staticClass: "button is-subtle is-pulled-right cta",
            domProps: { textContent: _vm._s(_vm.labels.button) },
            on: {
              click: function ($event) {
                $event.preventDefault()
                ;[
                  _vm.toggleModal(
                    _vm.tour,
                    _vm.tourselecteddate,
                    _vm.tourdepartureid,
                    $event
                  ),
                  _vm.dataLayerRAQOpen(),
                ]
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }