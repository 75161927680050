var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.includedmodel?.whatsIncludedCol1?.length > 0 ||
    _vm.includedmodel?.whatsIncludedCol2?.length > 0
    ? _c(
        "section",
        { staticClass: "included section", attrs: { id: "included-section" } },
        [
          _c("div", { staticClass: "included__wrapper" }, [
            _c("h3", { staticClass: "included__wrapper-title" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.dictionarymodel?.whatsIncluded) +
                  "\n\t\t"
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "included__box",
                class: {
                  columnOne:
                    _vm.includedmodel?.whatsIncludedCol1?.length === 0 ||
                    _vm.includedmodel?.whatsIncludedCol2?.length == 0,
                },
              },
              [
                _vm.includedmodel?.whatsIncludedCol1?.length > 0
                  ? _c(
                      "div",
                      { staticClass: "included__box-column" },
                      _vm._l(
                        _vm.includedmodel?.whatsIncludedCol1,
                        function ({ type, highlitghts }, index) {
                          return _c("div", [
                            type
                              ? _c("h4", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(type) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                highlitghts,
                                function ({ title, text, imageUrl }, index) {
                                  return _c("li", [
                                    text
                                      ? _c("i", {
                                          staticClass: "fas fa-check",
                                          attrs: { "aria-hidden": "true" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: { innerHTML: _vm._s(text) },
                                    }),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.includedmodel?.whatsIncludedCol2?.length > 0
                  ? _c(
                      "div",
                      { staticClass: "included__box-column" },
                      _vm._l(
                        _vm.includedmodel?.whatsIncludedCol2,
                        function ({ type, highlitghts }, index) {
                          return _c("div", [
                            type
                              ? _c("h4", { staticClass: "subtitle" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(type) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                highlitghts,
                                function ({ title, text, imageUrl }, index) {
                                  return _c("li", [
                                    text
                                      ? _c("i", {
                                          staticClass: "fas fa-check",
                                          attrs: { "aria-hidden": "true" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: { innerHTML: _vm._s(text) },
                                    }),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }