var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "refine-filter-search" }, [
    _c(
      "a",
      {
        staticClass: "scrollToAnchor",
        attrs: { href: "#tour-filters-section" },
        on: { click: _vm.scrollToAnchor },
      },
      [
        _c("span", [_vm._v("Refine your Search ")]),
        _vm._v(" "),
        _c("i", { staticClass: "fa fa-magnifying-glass" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }