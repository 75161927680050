import Vue from 'vue';
import appSearch from '../vue-components/apps/search.vue';
import PortalVue from 'portal-vue';
import VeeValidate from 'vee-validate';

Vue.use(PortalVue);
Vue.use(VeeValidate,{
    events: 'change'
});

function search() {
    new Vue({
        el: '#app-search',
        data: {
            appId: 'app-search'
        },
        components: {
            appSearch,
        }
    });
};
module.exports = search;