var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "portal",
    { attrs: { name: "vue-website-search", "target-el": "#search-field" } },
    [
      _c("div", { staticClass: "search__inputholder control" }, [
        _c("div", { attrs: { id: "search-field-wrapper" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.tripdestination,
                expression: "tripdestination",
              },
            ],
            staticClass: "input is-size-7",
            attrs: {
              autocomplete: "off",
              "aria-label": "searchInput",
              id: "searchInput",
              title: "Search Input",
              type: "text",
              placeholder: _vm.searchplaceholder,
            },
            domProps: { value: _vm.tripdestination },
            on: {
              keyup: [
                _vm.fetchSearchParams,
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchClick.apply(null, arguments)
                },
              ],
              input: function ($event) {
                if ($event.target.composing) return
                _vm.tripdestination = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.tripdestination.length > 2 && this.showSuggestions,
              expression:
                "this.tripdestination.length > 2 && this.showSuggestions",
            },
          ],
          ref: "searchresultscontent",
          staticClass: "search-field-result-content shadow-deep",
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "control" }, [
        _c(
          "button",
          {
            staticClass: "button cta is-primary",
            attrs: { "aria-label": "searchButton" },
            on: { click: _vm.searchClick },
          },
          [
            _c("span", { staticClass: "icon is-small" }, [
              _c("i", { staticClass: "fas fa-search" }),
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "search-btn-value is-size-7",
              domProps: { innerHTML: _vm._s(_vm.searchbuttonlabel) },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }