import 'url-polyfill';
import Vue from 'vue';
import ValidateModal from '../vue-components/modals/validate-modal.vue';

function validateModal() {

    new Vue({
        el: '#validate-modal',
        data: window.Cms.validateModal,
        components: {
            'validate-modal': ValidateModal
        }
    });
}

module.exports = validateModal;