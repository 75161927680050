import Vue from 'vue';
import VeeValidate from 'vee-validate';
import PortalVue from 'portal-vue';
import axios from 'axios';

Vue.use(VeeValidate,{
    events: 'change'
});

axios.interceptors.response.use(function (response) {
    if (response.status === 400) {
        return Promise.reject(response);
    }
    if (response.status === 500) {
        return Promise.reject(response);
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

Vue.use(PortalVue);
import QuoteRaqButton from "../vue-components/modals/raq-modal-button.vue";
import QuoteRaqModal from '../vue-components/modals/raq-modal.vue';
import VueDatesPrices from '../vue-components/dates-prices/dates-prices-list.vue';
import VueUniworldDatesPricesList from '../vue-components/dates-prices/uniworld-dates-prices-list.vue';
import * as searchConstants from '../search-constants.js';

function setMinHeight(minHeight){
    const pricesContainer  = document.querySelector(".dates-prices-item-container");

    if(minHeight) {
        pricesContainer.style.minHeight = '0px';
    }else {
        var height = pricesContainer ? pricesContainer.offsetHeight : 0;
        pricesContainer.style.minHeight = height + 'px';
    }
}

function websiteAppAvailability() {
    new Vue({
        el: '#app-availability',
        data () {
            return {
                windowCms: window.Cms,
                localAppData: {
                    isLoadingFresh: true,
                    isLoadingMore: false,
                    apiPages: 0,
                    apiPresent: true,
                    dataAll: [],
                    dataAllTotal: 0,
                    dataAllTotalPages: 0,
                    dataOnlyAvailable: [],
                    dataOnlyAvailableTotal: 0,
                    dataOnlyAvailablePages: 0,
                    definiteDepartureCount: 0,
                    nonDefiniteDepartureCount: 0,
                    maxShownItems: 10,
                    availableMonths: [],
                    availableShips: [],
                    availableRoutes: [],
                    allShipsMatch: false,
                    apiError: false,
                    userActions: {
                        selectedMonths: [],
                        selectedShip: '',
                        selectedRoute: '',
                        showAllDataFlag: true
                    },
                    visibleList: [],
                    siteDiscount: 0
                }
            }
        },
        components: {
            'quote-raq-modal': QuoteRaqModal,
            'quote-raq-button': QuoteRaqButton,
            'vue-dates-prices-list': VueDatesPrices,
            'vue-uniworld-dates-prices-list': VueUniworldDatesPricesList
        },
        computed: {
            showMore: function() {
                  return this.windowCms.availabilitiesModel.availabilities.pageTotal != this.windowCms.availabilitiesModel.availabilities.total;
            },
            noResults: function() {
                 return this.windowCms.availabilitiesModel.availabilities.pageTotal == 0;
            }
        },
        created() {

        },
        methods:{
            populateData() {
                let apiPresent = this.windowCms.availabilitiesModel.apiUrl;
                let allData = [];
                let onlyAvailableData = [];
                let definiteDepartureData = [];
                let nonDefiniteDepartureData = [];
                let pages = 0;

                if (!apiPresent) {
                    allData = this.windowCms.availabilitiesModel.availabilities.data;
                    this.windowCms.availabilitiesModel.availabilities.pageSize = 10;
                    this.windowCms.availabilitiesModel.availabilities.pageTotal = 10;
                    this.windowCms.availabilitiesModel.availabilities.currentPage = 0;
                    this.localAppData.dataAll = allData.sort((a,b) => new Date(a.startDateData) - new Date(b.startDateData));
                    this.localAppData.dataAllTotal = allData.length;
                    this.localAppData.apiPages = Math.floor(this.localAppData.dataAll.length / this.windowCms.availabilitiesModel.availabilities.pageTotal);
                    this.localAppData.dataAllTotalPages = this.localAppData.apiPages;
                    let monthsArray = [];
                    let shipsArray = [];
                    let routesArray = [];
                    this.localAppData.dataAll.forEach(item => {
                        monthsArray.push(item.startMonth + " " + item.startDateData.slice(0,4));
                    });
                    let noDupesMonths = [...new Set(monthsArray.join().split(','))];
                    this.localAppData.availableMonths = noDupesMonths;
                    let noDupesShips = [...new Set(shipsArray.join().split(','))];
                    this.localAppData.availableShips = noDupesShips;
                    let noDupesRoutes = [...new Set(routesArray.join().split(','))];
                    this.localAppData.availableRoutes = noDupesRoutes;
                    this.localAppData.isLoadingFresh = false;
                    this.localAppData.dataAll.forEach(item => {
                        if (item.definiteDeparture) {
                            definiteDepartureData.push(item.definiteDeparture);
                        } else {
                            nonDefiniteDepartureData.push(item.definiteDeparture);
                        }
                        if (item.specialOffer) {
                            specialOfferData.push(item.definiteDeparture);
                        } else {
                            nonDefiniteDepartureData.push(item.definiteDeparture);
                        }
                    })
                    this.localAppData.definiteDepartureCount = definiteDepartureData.length;
                    this.localAppData.nonDefiniteDepartureCount = nonDefiniteDepartureData.length;                    
                    let visibleListArray = [];
                    for (let i = 0; i < this.localAppData.dataAll.length; i++) {
                    if ((this.windowCms.availabilitiesModel.labels.hideDepartureDatesSwitch) ||
                        ((!this.windowCms.availabilitiesModel.labels.toggleDefinite) || 
                         (this.windowCms.availabilitiesModel.labels.toggleDefinite == this.localAppData.dataAll[i].definiteDeparture)))
                        {
                            visibleListArray.push(this.localAppData.dataAll[i]);
                        } 
                    }
                    this.localAppData.visibleList = visibleListArray;
                } else {
                    const isUniworld = this.windowCms.availabilitiesModel.apiUrl.includes('uniworld') || this.windowCms.availabilitiesModel.apiUrl.includes('urivercruise');
                    let queryForPages = this.windowCms.availabilitiesModel.apiUrl;
                    axios.get(queryForPages, {timeout: searchConstants.SEARCH_SERVICE_TIMEOUT}).then(response => {
                        pages = Math.floor(response.data.total / response.data.pageSize);
                        this.localAppData.apiPages = pages;
                        this.windowCms.availabilitiesModel.availabilities.currentPage = response.data.currentPage;
                        this.windowCms.availabilitiesModel.availabilities.pageSize = response.data.pageSize;
                        this.windowCms.availabilitiesModel.availabilities.pageTotal = response.data.pageTotal;
                        this.windowCms.availabilitiesModel.availabilities.total = response.data.total;
                        let visibleList = [];
                        let definiteDepartureArray = [];

                        for (let i = 0; i <= pages; i++) {
                            let queryForData = this.windowCms.availabilitiesModel.apiUrl + `?page=${i}`;
                            axios.get(queryForData).then(response => {
                                response.data.data.forEach(item => {
                                    allData.push(item);
                                    if (item.definiteDeparture) {
                                        definiteDepartureArray.push(item);
                                    }
                                    if (isUniworld) {
                                        let dealsAvailable = [];
                                        for (let i = 0; i < item.categories.length; i++) {
                                            dealsAvailable.push(item.categories[i].isAvailable);
                                        }
                                        if (dealsAvailable.toString().includes("true")) {
                                            onlyAvailableData.push(item);
                                        }
                                        if (onlyAvailableData.length) {
                                            visibleList = onlyAvailableData.sort((a,b) => new Date(a.startDateData) - new Date(b.startDateData));
                                        } else {
                                            visibleList = allData;
                                        }
                                    } else {
                                        if (item.definiteDeparture) {
                                            definiteDepartureData.push(item.definiteDeparture);
                                        } else {
                                            nonDefiniteDepartureData.push(item.definiteDeparture);
                                        }
                                        this.localAppData.definiteDepartureCount = definiteDepartureData.length;
                                        this.localAppData.nonDefiniteDepartureCount = nonDefiniteDepartureData.length;
                                        
                                        if (!this.windowCms.availabilitiesModel.labels.toggleDefinite) {
                                            visibleList = allData; 
                                        } 
                                        else {
                                            visibleList = definiteDepartureArray; 
                                        } 
                                    }
                                })
                                this.localAppData.dataAll = allData.sort((a,b) => new Date(a.startDateData) - new Date(b.startDateData));
                                this.localAppData.dataAllTotal = allData.length;
                                this.localAppData.dataAllTotalPages = this.localAppData.apiPages;
                                this.localAppData.dataOnlyAvailable = onlyAvailableData;
                                this.localAppData.dataOnlyAvailableTotal = onlyAvailableData.length;
                                this.localAppData.dataOnlyAvailablePages = Math.floor(onlyAvailableData.length / this.windowCms.availabilitiesModel.availabilities.pageTotal);
                                this.localAppData.visibleList = visibleList;
                                if (this.localAppData.dataAll[0].siteDiscount !== "undefined") {                                    
                                    this.localAppData.siteDiscount = this.localAppData.dataAll[0].siteDiscount;
                                }
                                let monthsArray = [];
                                let shipsArray = [];
                                let routesArray = [];
                                let shipsArrayCount =[];
                                this.localAppData.dataAll.forEach(item => {
                                    monthsArray.push(item.startMonth + " " + item.startDateData.slice(0,4));
                                    if (isUniworld) {
                                        shipsArray.push(item.shipNames);
                                        shipsArrayCount.push(item.ships);
                                        routesArray.push(item.cruiseItinerary.replace(/\(.*?\)/g, '').replace(/\bdepart\b/gi, '').replace(/\bembark\b/gi, ''));
                                    }
                                });
                                let noDupesMonths = [...new Set(monthsArray.join().split(','))];
                                this.localAppData.availableMonths = noDupesMonths;
                                let noDupesShips = [...new Set(shipsArray.join().split(','))];
                                this.localAppData.availableShips = noDupesShips;
                                let shipsCount = [...new Set(shipsArrayCount)];
                                if (shipsCount.length == 1 && noDupesShips.length > 1) {
                                    this.localAppData.allShipsMatch = true;
                                }
                                
                                let noDupesRoutes = [...new Set(routesArray)];
                                this.localAppData.availableRoutes = noDupesRoutes;
                                this.localAppData.isLoadingFresh = false;
                            })
                        }
                    })
                    .catch(error => {
                        this.localAppData.apiError = true;
                    })
                }

            },
            loadMore() {
                this.windowCms.availabilitiesModel.availabilities.currentPage ++;
                this.localAppData.isLoadingMore = true;
                setMinHeight();
                if (this.localAppData.userActions.showAllDataFlag) {
                    this.windowCms.availabilitiesModel.availabilities.total = this.localAppData.dataAllTotal;
                    this.localAppData.apiPages = this.localAppData.dataAllTotalPages;
                } else {
                    this.windowCms.availabilitiesModel.availabilities.total = this.localAppData.dataOnlyAvailableTotal;
                    this.localAppData.apiPages = this.localAppData.dataOnlyAvailablePages;
                }

                if(this.windowCms.availabilitiesModel.availabilities.currentPage <= this.localAppData.apiPages ){
                    this.localAppData.maxShownItems += this.windowCms.availabilitiesModel.availabilities.pageSize;
                }
                
                this.localAppData.isLoadingMore = false;
                setMinHeight(true);
            }
        },
        created() {
            if (!this.windowCms.raqModal.tour.useWidget) {
                this.populateData();
            }
        }
    });
};
module.exports = websiteAppAvailability
;