<template>
	<div
		id="subnav"
		class="subnav-placeholder"
		v-if="!datamenu.tddHideSubNav">
		<nav class="subnav subnav-control subnav-tourPage">
			<div class="subnav-container is-flex">
				<div class="subnav-scroll-btn scroll-left hidden">
					<i class="fas fa-lg fa-chevron-left"></i>
				</div>

				<div class="subnav-wrapper">
					<button
						class="subnav-selected"
						:aria-label="datamenu.brandName">
						<p class="subnav-selected-value"></p>
						<span class="subnav-toggle-indicator">
							<i class="fas fa-chevron-down is-closed"></i>
							<i class="fas fa-chevron-up is-open"></i>
						</span>
					</button>
					<a
						href="#overview-section"
						data-type="general interaction"
						data-name="in-page-menu"
						data-target="overview-section"
						class="subnav__link"
						v-if="!datamenu.tddHideOverview"
						>{{ datamenu.overViewTitle }}</a
					>

					<a
						href="#app-mapModal"
						data-target="app-mapModal"
						data-name="in-page-menu"
						class="subnav__link"
						v-if="
							!datamenu.tddHideItinery &&
							datamenu.isDefaultMapImage
						"
						>{{ datamenu.mapTitle }}</a
					>
					<a
						href="#itinerary-section"
						data-type="general interaction"
						data-name="in-page-menu"
						data-target="itinerary-section"
						class="subnav__link"
						v-if="
							datamenu.isUWTour &&
							!datamenu.tddHideItinery &&
							datamenu.directionsAny
						"
						>{{ datamenu.itineraryTitle }}</a
					>
					<a
						href="#highlights-section"
						data-type="general interaction"
						data-name="in-page-menu"
						data-target="highlights-section"
						class="subnav__link"
						v-if="
							datamenu.isUWTour &&
							!datamenu.tddHideItinery &&
							datamenu.tripHighlightsAny
						"
						>{{ datamenu.highlightsTitle }}</a
					>

					<a
						href="#itinerary-section"
						data-type="general interaction"
						data-name="in-page-menu"
						data-target="itinerary-section"
						class="subnav__link"
						v-if="
							!datamenu.isUWTour &&
							!datamenu.tddHideItinery &&
							datamenu.itineraryAny
						"
						>{{ datamenu.itineraryTitle }}</a
					>

					<a
						href="#trip-options-section"
						data-target="trip-options-section"
						class="subnav__link"
						v-if="datamenu.isC1Tour || datamenu.isV4Tour"
						>{{ datamenu.tourOptionsConfiguratorTitle }}</a
					>

					<a
						href="#highlights-section"
						data-type="general interaction"
						data-name="in-page-menu"
						data-target="highlights-section"
						class="subnav__link"
						v-if="
							!datamenu.isUWTour &&
							!datamenu.tddHideItinery &&
							datamenu.tripHighlightsAny
						"
						>{{ datamenu.highlightsTitle }}</a
					>
					<a
						href="#included-section"
						data-type="general interaction"
						data-name="in-page-menu"
						data-target="included-section"
						class="subnav__link"
						v-if="
							(!datamenu.tddHideWhatsIncluded &&
								datamenu.whatsIncludedCol1Any) ||
							datamenu.whatsIncludedCo21Any
						"
						>{{ datamenu.whatsIncludedTitle }}</a
					>
					<a
						href="#ships-section"
						data-type="general interaction"
						data-name="in-page-menu"
						data-target="ships-section"
						class="subnav__link"
						v-if="datamenu.isUWTour && datamenu.shipsAny"
						>{{ datamenu.shipsTitle }}</a
					>
					<div
						id="feature-subnav_cta"
						class="feature-subnav-cta sticky-nav-is-visible not-last is-hidden-mobile"></div>

					<a
						class="subnav__link button is-secondary js-scroll-link sticky-nav-is-visible last is-hidden-mobile cta"
						data-target="availability-section"
						href="#availability-section"
						v-if="!datamenu.useWidget"
						>{{ datamenu.datesAndPricesTitle }}</a
					>
				</div>

				<button
					id="show-ttc-widget-button-subnav"
					class="button is-secondary sticky-nav-is-visible last cta ttc-widget-button is-hidden-desktop"
					v-if="datamenu.useWidget">
					{{ datamenu.raqTitle }}
				</button>

				<div class="subnav-scroll-btn scroll-right hidden">
					<i class="fas fa-lg fa-chevron-right"></i>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
export default {
	props: ["datamenu"],
	data() {
		return {};
	},
};
</script>
