<template>
	<div class="ttc-vue-slider vue-price-slider">
		<vue-slider
			ref="priceslider"
			v-model="value"
			:show="pslider"
			:tooltip="tooltip"
			:width="width"
			:dot-size="dotSize"
			:min="min"
			:max="max"
			@callback="passValue"
			:use-keyboard="keyboard">
		</vue-slider>
		<div class="rangefromto">
			<div class="rangefrom">
				<span class="range-currency">{{ currency }}</span>
				<input
					type="text"
					maxlength="5"
					oninput="this.value=this.value.replace(/[^0-9]/g,'');"
					id="pricefrom"
					aria-label="StartPrice"
					v-model="value[0]"
					@change="passValue"
					:min="this.min"
					:max="this.max"
					class="input has-value" />
			</div>
			<div class="rangeto">
				<span>{{ currency }}</span>
				<input
					type="text"
					maxlength="5"
					oninput="this.value=this.value.replace(/[^0-9]/g,'');"
					id="priceto"
					aria-label="PriceUpto"
					v-model="value[1]"
					@change="passValue"
					:min="this.min"
					:max="this.max"
					class="input has-value" />
				<span
					class="vue-price-slider-max"
					v-show="value[1] == this.max"
					>+</span
				>
			</div>
		</div>
	</div>
</template>

<script>
import vueSlider from "vue-slider-component";
import * as searchConstants from "../../search-constants.js";
import eventBus from "../../vue-functions/event-bus.js";

export default {
	components: { vueSlider },
	props: ["currency", "minval", "maxval", "pslider"],
	data: function () {
		return {
			value: [
				searchConstants.PRICESLIDERMINVAL,
				searchConstants.PRICESLIDERMAXVAL,
			],
			min: searchConstants.PRICESLIDERMINVAL,
			max: searchConstants.PRICESLIDERMAXVAL,
			dotSize: 24,
			keyboard: true,
			width: "49%",
			tooltip: false,
			valuesObject: {
				minPrice: 0,
				maxPrice: 0,
			},
		};
	},
	mounted() {
		if (this.minval) {
			this.value[0] = this.minval;
		}

		if (this.maxval) {
			this.value[1] = this.maxval;
		}
	},
	methods: {
		passValue() {
			if (this.value[0] == this.min) {
				this.valuesObject.minPrice = null;
			} else {
				this.valuesObject.minPrice = this.value[0];
			}

			if (this.value[1] == this.max) {
				this.valuesObject.maxPrice = null;
			} else {
				this.valuesObject.maxPrice = this.value[1];
			}
			this.$parent.$emit("price-slider-val", this.valuesObject);
		},
		resetSlider() {
			this.$refs.priceslider.setValue([this.min, this.max]);
		},
	},
	created() {
		eventBus.$on("price-slider-reset", () => {
			this.resetSlider();
		});
	},
};
</script>
