<template>
	<div>
		<search-field
			v-if="settings.parentSearch"
			ref="searchfield"
			:dest="settings.currentDestinations"
			:searchterm="settings.currentSearchPrefix"
			:searchbuttonlabel="settings.filterTripsContent.searchButtonText"
			:searchplaceholder="settings.filterTripsContent.searchPlaceholder"
			@searchclicked="formFieldSearch">
		</search-field>

		<div
			id="searchbarholder"
			class="searchbarholder">
			<div
				id="searchbar"
				class="searchbar form-inverse">
				<div class="searchbar-wrapper">
					<div class="searchbar-inner">
						<div class="searchbar-mobile-toggle">
							<button class="searchbar-mobile-toggle-btn">
								<span>{{
									settings.filterTripsContent.mobileHeading
								}}</span>
								<i
									class="fas fa-chevron-up mobile-toggle-chevron-icon"></i>
							</button>
						</div>
						<div class="searchbar-inner-row">
							<div class="searchbar-inner-col">
								<div class="searchbar__label">
									<h4>
										{{
											settings.filterTripsContent
												.filterTripsText
										}}
									</h4>
									<p
										class="label"
										v-for="(
											product, productIndex
										) in products"
										:key="'product' + productIndex">
										{{
											settings.filterTripsContent
												.numberOfSearchResultText
										}}
										{{ product.totalHits }}
									</p>
								</div>
							</div>
							<div class="searchbar-inner-col">
								<small-groups-filter
									@set-small-groups-flag="setSmallGroupsFlag"
									@filter-search="filterSearch"
									:settings="settings">
								</small-groups-filter>
								<special-offers-filter
									@set-special-offers-flag="
										setSpecialOffersFlag
									"
									@filter-search="filterSearch"
									:settings="settings">
								</special-offers-filter>
							</div>
						</div>
						<div class="searchbar-inner-row">
							<div class="searchbar-controls-wrap">
								<div class="searchbar-control">
									<search-date-duration
										:settings="settings"
										:filter-visibility="filterVisibility"
										@set-filter-visibility="
											setFilterVisibility
										"
										@duration-filter-actions="
											durationFilterActions
										"
										@months="getSelectedMonths"
										@durationsliderval="
											getDurationSliderValues
										"
										@reset-duration-filters="
											resetDurationFilters
										"
										@filter-search="filterSearch">
									</search-date-duration>
								</div>
								<div class="searchbar-control">
									<search-brand-selector
										:settings="settings"
										:products="products"
										:filter-visibility="filterVisibility"
										@set-filter-visibility="
											setFilterVisibility
										"
										@get-selected-brands="getSelectedBrands"
										@reset-brand-filters="resetBrandFilters"
										@filter-search="filterSearch"
										@brand-filter-actions="
											brandFilterActions
										">
									</search-brand-selector>
								</div>
								<div class="searchbar-control">
									<search-destination-selector
										:settings="settings"
										:filter-visibility="filterVisibility"
										@set-filter-visibility="
											setFilterVisibility
										"
										@destination-filter-actions="
											destinationFilterActions
										"
										@destinations-region-click="
											clearDestinationValues
										"
										@destinations-region-search="
											setRegionSearch
										"
										@destinations-filter="
											getSelectedDestinations
										"
										@reset-destination-filters="
											resetDestinationFilters
										"
										@filter-search="filterSearch">
									</search-destination-selector>
								</div>
								<div class="searchbar-control">
									<search-price-selector
										:settings="settings"
										:products="products"
										:filter-visibility="filterVisibility"
										:currency="currencySymbol"
										@set-filter-visibility="
											setFilterVisibility
										"
										@price-filter-actions="
											priceFilterActions
										"
										@reset-price-slider="resetPriceSlider"
										@filter-search="filterSearch"
										@price-slider-val="
											getPriceSliderValues
										">
									</search-price-selector>
								</div>

								<clear-all-button
									:settings="settings"
									@reset-all-filters="
										resetAllFilters(
											'clearSearchField',
											'callNewSearch',
										)
									"></clear-all-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<search-results
			:settings="settings"
			:products="products"
			:tourResults="tourResults"
			:locationString="locationString"
			@show-more-results="showMoreResults"
			@exit-to-main-search="exitToMainSearch"
			@launch-easy-quote="launchEasyQuote($event)">
		</search-results>
		<div class="search-easyquote-wrapper">
			<quote-raq-modal
				:labels="launchEasyQuoteData.labels"
				:membershipno="launchEasyQuoteData.membershipNo"
				:api="launchEasyQuoteData.api"
				:toemailaddress="launchEasyQuoteData.toemailaddress"
				:ccemailaddress="launchEasyQuoteData.ccemailaddress"
				:is-logged-in="launchEasyQuoteData.isLoggedIn"
				:urlquoterequest="launchEasyQuoteData.url"
				:tourid="launchEasyQuoteData.tourId"
				:touroptionid="launchEasyQuoteData.tourOptionId">
			</quote-raq-modal>
		</div>
		<aboutus-modal></aboutus-modal>
	</div>
</template>
<script>
import searchField from "../../vue-components/search/search-field.vue";
import searchDateDuration from "../../vue-components/search/search-date-duration.vue";
import searchBrandSelector from "../../vue-components/search/search-brand-selector.vue";
import searchDestinationSelector from "../../vue-components/search/search-destination-selector.vue";
import searchTypeSelector from "../../vue-components/search/search-type-selector.vue";
import searchPriceSelector from "../../vue-components/search/search-price-selector.vue";
import clearAllButton from "../../vue-components/search/clear-all-button.vue";
import smallGroupsFilter from "../../vue-components/search/small-groups-filter.vue";
import specialOffersFilter from "../../vue-components/search/special-offers-filter.vue";
import quoteRaqModal from "../../vue-components/modals/raq-modal.vue";
import searchResults from "../../vue-components/search/search-results.vue";
import aboutusModal from "../../vue-components/modals/aboutus-modal.vue";
import getSelectedTypes from "../../search-functions/travel-types-filters/get-selected-types";
import resetTypeFilters from "../../search-functions/travel-types-filters/reset-type-filters";
import typeFilterActions from "../../search-functions/travel-types-filters/type-filter-actions";
import setTypeFilterText from "../../search-functions/travel-types-filters/set-type-filter-text";
// url params
import getUrlParams from "../../search-functions/url-handling/get-url-params.js";
// constants for use
import * as searchConstants from "../../search-constants.js";
// functions for filtering and searching
import makeSearchFilters from "../../search-functions/global-filter-functions/make-search-filters";
import filterSearch from "../../search-functions/global-filter-functions/filter-search";
import formFieldSearch from "../../search-functions/search-operations/form-field-search";
import setRegionSearch from "../../search-functions/destination-filters/set-region-search";
import fetchSearch from "../../search-functions/search-operations/fetch-search";
import setFilterVisibility from "../../search-functions/global-filter-functions/set-filter-visibility";
import showMoreResults from "../../search-functions/search-operations/show-more-results";
import resetSearch from "../../search-functions/search-operations/reset-search";
import exitToMainSearch from "../../search-functions/search-operations/exit-to-main-search";
import applyCmsParameters from "../../search-functions/search-operations/apply-cms-parameters";
import constructSearchTerm from "../../search-functions/search-operations/construct-search-term";
import matchApiDestinations from "../../search-functions/global-filter-functions/match-api-destinations";
import handleApplyClick from "../../search-functions/global-filter-functions/handle-apply-click";
// url management function
import manageUrlState from "../../search-functions/url-handling/manage-url-state";
import manageDeepLinkState from "../../search-functions/url-handling/manage-deep-link-state";
// function for resetting all filters
import resetAllFilters from "../../search-functions/global-filter-functions/reset-all-filters";
// functions for the price filters
import getPriceSliderValues from "../../search-functions/price-filters/get-price-slider-values";
import setPriceFilterText from "../../search-functions/price-filters/set-price-filter-text";
import resetPriceSlider from "../../search-functions/price-filters/reset-price-slider";
import priceFilterActions from "../../search-functions/price-filters/price-filter-actions";
// date duration filters functions
import getDurationSliderValues from "../../search-functions/date-duration-filters/get-duration-slider-values";
import getSelectedMonths from "../../search-functions/date-duration-filters/get-selected-months";
import setDurationFilterText from "../../search-functions/date-duration-filters/set-duration-filter-text";
import setMonthFilterText from "../../search-functions/date-duration-filters/set-month-filter-text";
import resetDurationFilters from "../../search-functions/date-duration-filters/reset-duration-filters";
import durationFilterActions from "../../search-functions/date-duration-filters/duration-filter-actions";
// brand filters functions
import getSelectedBrands from "../../search-functions/brand-filters/get-selected-brands";
import setBrandFilterText from "../../search-functions/brand-filters/set-brand-filter-text";
import resetBrandFilters from "../../search-functions/brand-filters/reset-brand-filters";
import brandFilterActions from "../../search-functions/brand-filters/brand-filter-actions";
// destination filter actions
import getSelectedDestinations from "../../search-functions/destination-filters/get-selected-destinations";
import setDestinationFilterText from "../../search-functions/destination-filters/set-destination-filter-text";
import destinationFilterActions from "../../search-functions/destination-filters/destination-filter-actions";
import resetDestinationFilters from "../../search-functions/destination-filters/reset-destination-filters";
import clearDestinationValues from "../../search-functions/destination-filters/clear-destination-values";
// small groups flag actions
import setSmallGroupsFlag from "../../search-functions/tags/set-small-groups-flag";
import resetSmallGroupsFlag from "../../search-functions/tags/reset-small-groups-flag";
// special offers flag actions
import setSpecialOffersFlag from "../../search-functions/tags/set-special-offers-flag";
import resetSpecialOffersFlag from "../../search-functions/tags/reset-special-offers-flag";
// brochure code Actions
import getSelectedBrochureCodes from "../../search-functions/brochure-codes/get-selected-brochure-codes";
// ui behaviour functions
import doSearchScroll from "../../search-functions/ui-behaviour/do-search-scroll";
import clickOutsideFilters from "../../search-functions/ui-behaviour/click-outside-filters";
import searchAppResize from "../../search-functions/ui-behaviour/search-app-resize";
import getCurrentScreenSize from "../../ui-functions/get-current-screen-size";
import scrollToElement from "../../search-functions/ui-behaviour/scroll-to-element";
import scrollToFilterSmallScreen from "../../search-functions/global-filter-functions/scroll-to-filter-small-screen";
import checkSearchResultHeight from "../../ui-functions/checkSearchResultHeight";
// easy quote functionality
import launchEasyQuote from "../../search-functions/easy-quote/launch-easy-quote";
// googleAnalytics tracking function
import sendGoogleAnalyticsSearchCriteria from "../../search-functions/search-operations/send-google-analytics-search-criteria";
// searchIEFixs
import fixIEImages from "../../ie-fixes/fix-ie-images";

export default {
	props: {
		appId: String,
	},
	data() {
		return {
			settings: {
				loading: false,
				filtersText: {
					durationText: false,
					monthsText: false,
					brandsText: false,
					destinationsText: false,
					priceText: false,
					typeText: false,
					dateDurationDefault: "Date & Duration",
					destinationsDefault: "Destination",
				},
				filtersLocked: false,
				currentSearchPrefix: searchConstants.DEFAULTSEARCHTERM,
				currentDestinations: "",
				currentContinent: false,
				filterActions: {
					destinationActionClicked: "filterSearch",
					durationActionClicked: "filterSearch",
				},
				filters: {
					minPrice: false,
					maxPrice: false,
					minDuration: false,
					maxDuration: false,
					monthsSelected: false,
					brandsSelected: [],
					typesSelected: [],
					tags: [],
					brochurecodes: [],
				},
				filtersValues: {
					// need to hold the values for any filters set to pass
					// around to components without and search param text
					minDurationVal: false,
					maxDurationVal: false,
					monthsVal: false,
					brandsVal: [],
					typesVal: [],
					minPriceVal: false,
					maxPriceVal: false,
					tags: [],
				},
				currency: searchConstants.CURRENCY,
				showMoreLoading: false,
				showMore: false,
				parentSearch: true,
				resultsInView: 0,
				totalResults: 0,
				error: false,
				filterTripsContent: {},
				searchResultContent: {},
			},
			products: [],
			filterVisibility: {
				showDurationFilter: false,
				showBrandFilter: false,
				showDestinationFilter: false,
				showTypeFilter: false,
				showPriceFilter: false,
			},
			tourResults: [],
			currencySymbol: "",
			urlParams: "",
			locationString: searchConstants.LOCATION_STRING,
			currentBrochureCodes: false,
			currentPage: 0,
			viewLimit: 20,
			defaultViewLimit: 20,
			searchScrollPosition: 0,
			launchEasyQuoteData: searchConstants.browserWindow.Cms.raqModal,
		};
	},
	components: {
		searchField,
		searchDateDuration,
		searchBrandSelector,
		searchDestinationSelector,
		searchPriceSelector,
		searchTypeSelector,
		clearAllButton,
		searchResults,
		aboutusModal,
		quoteRaqModal,
		smallGroupsFilter,
		specialOffersFilter,
	},
	beforeMount() {
		// load the settings into the search / search results
		this.settings.filterTripsContent =
			searchConstants.browserWindow.Cms.searchSettings.filterTripsContent;
		this.settings.searchResultContent =
			searchConstants.browserWindow.Cms.searchSettings.searchResultContent;
		if (searchConstants.browserWindow.Cms.searchAppParams) {
			this.applyCmsParameters();
		}
		// as application is mounted checking to see if there are search params
		let currentUrlParams = window.location.search;
		// trying to set this so that a page reload always causes a scroll back top top of page
		window.onbeforeunload = function () {
			window.scrollTo(0, 0);
		};
		if (currentUrlParams.length > 0) {
			let searchParam = getUrlParams(window.location.href);
			let dest = "";
			let searchPrefix = "";

			Object.keys(searchParam).forEach((key) => {
				switch (key) {
					case "text":
						dest = searchParam[key];
						this.settings.currentDestinations = dest;
						searchPrefix = searchConstants.FREETEXTSEARCHTERM;
						break;
					case "tour":
						dest = searchParam[key];
						this.settings.currentDestinations = dest;
						searchPrefix = searchConstants.TOURSEARCHTERM;
						break;
					case "destinations":
						dest = searchParam[key];
						this.settings.currentDestinations = dest;
						searchPrefix = searchConstants.DEFAULTSEARCHTERM;
						break;
					case "minduration":
						dest = searchParam[key];
						this.settings.minDuration = dest;
						searchPrefix = searchConstants.MINDURATIONSEARCHPREFIX;
						break;
					case "maxduration":
						dest = searchParam[key];
						this.settings.maxDuration = dest;
						searchPrefix = searchConstants.MAXDURATIONSEARCHPREFIX;
						break;
					case "months":
						dest = searchParam[key];
						this.settings.months = dest;
						searchPrefix = searchConstants.MONTHSSELECTEDPREFIX;
						break;
					case "brochurecodes":
						dest = searchParam[key];
						this.currentBrochureCodes = dest;
						searchPrefix = searchConstants.BROCHURECODESSEARCHTERM;
						break;
					case "brands":
						dest = searchParam[key];
						searchPrefix = searchConstants.BRANDSSELECTEDPREFIX;
						break;
					case "travelTypes":
						dest = searchParam[key];
						searchPrefix = searchConstants.TYPESSELECTEDPREFIX;
						break;
					case "continents":
						dest = searchParam[key];
						this.settings.currentContinent = dest;
						searchPrefix =
							searchConstants.REGIONSTAGSSEARCHTERMSEARCHTERM;
						break;
					case "tags":
						dest = searchParam[key];
						this.settings.tags = dest;
						//searchPrefix = searchConstants.TAGSSEARCHTERM;
						break;
					default:
						dest = "";
						searchPrefix = searchConstants.DEFAULTSEARCHTERM;
						break;
				}
			});
			if (dest == "") {
				this.filterSearch();
			} else {
				this.settings.currentSearchPrefix = searchPrefix;
				let queryString = currentUrlParams.substring(1);
				this.fetchSearch(queryString, false, true);
			}
		} else {
			this.filterSearch();
		}
	},
	mounted() {
		this.clickOutsideFilters();
	},
	filters: {
		addCurrency: function (item) {
			return this.currency + item;
		},
	},
	methods: {
		applyCmsParameters,
		makeSearchFilters,
		filterSearch,
		formFieldSearch,
		setRegionSearch,
		fetchSearch,
		manageUrlState,
		resetAllFilters,
		getPriceSliderValues,
		setPriceFilterText,
		resetPriceSlider,
		priceFilterActions,
		getDurationSliderValues,
		getSelectedMonths,
		setDurationFilterText,
		setMonthFilterText,
		resetDurationFilters,
		durationFilterActions,
		getSelectedBrands,
		setBrandFilterText,
		resetBrandFilters,
		brandFilterActions,
		getSelectedBrochureCodes,
		getSelectedDestinations,
		setDestinationFilterText,
		destinationFilterActions,
		resetDestinationFilters,
		clearDestinationValues,
		setFilterVisibility,
		showMoreResults,
		doSearchScroll,
		resetSearch,
		manageDeepLinkState,
		clickOutsideFilters,
		sendGoogleAnalyticsSearchCriteria,
		exitToMainSearch,
		searchAppResize,
		getCurrentScreenSize,
		scrollToElement,
		scrollToFilterSmallScreen,
		launchEasyQuote,
		constructSearchTerm,
		matchApiDestinations,
		fixIEImages,
		handleApplyClick,
		getSelectedTypes,
		resetTypeFilters,
		typeFilterActions,
		setTypeFilterText,
		checkSearchResultHeight,
		setSmallGroupsFlag,
		resetSmallGroupsFlag,
		setSpecialOffersFlag,
		resetSpecialOffersFlag,
	},
	updated() {
		fixIEImages();
	},
};
</script>
