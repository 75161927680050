function stickyNav() {

     let searchbar = document.getElementById('searchbarholder');
     let fixedClass = "is-fixed";
     let searchPage = document.querySelector('.page-vacationSearch');
     let footer = document.querySelector('.footer');

     if(searchPage) {       

        let headerHeight = searchbar.offsetHeight;
        let top;

        window.addEventListener('load', function(){
            footer.style.marginTop = '10rem';
        });

        window.addEventListener('scroll', function() { 
            top = window.scrollY;       
            if (top > headerHeight) {
                searchbar.classList.toggle(fixedClass, true);
            } else {
                searchbar.classList.toggle(fixedClass, false);
            }
        });
    }

    


}
module.exports = stickyNav;