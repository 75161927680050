var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "hero-carousel",
      class: !_vm.herocarouseldata?.heroWidth ? "hero-full" : "container",
    },
    [
      _c(
        "carousel",
        {
          staticClass: "hero-carousel__wrap",
          attrs: {
            items: 1,
            loop: true,
            nav: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 500,
            autoplayTimeout: 5000,
          },
        },
        _vm._l(
          _vm.herocarouseldata.carouselItems,
          function (
            {
              carouselImageUrl,
              carouselText,
              carouselImageAltTag,
              leftFocalPoint,
              topFocalPoint,
            },
            index
          ) {
            return _c(
              "div",
              { key: index, staticClass: "hero-carousel__slide" },
              [
                _c("div", {
                  staticClass: "hero-overlay",
                  style: { opacity: _vm.opacityValue + "%" },
                }),
                _vm._v(" "),
                carouselImageUrl
                  ? _c("div", {
                      staticClass: "hero-carousel__image is-background",
                      style: {
                        backgroundImage: "url('" + carouselImageUrl + "')",
                        backgroundPosition:
                          "" + leftFocalPoint + " " + topFocalPoint + "",
                      },
                      attrs: { alt: carouselImageAltTag },
                    })
                  : _vm._e(),
                _vm._v(" "),
                carouselText
                  ? _c(
                      "h1",
                      {
                        staticClass: "hero-carousel__title",
                        class: !_vm.herocarouseldata.heroWidth
                          ? "container"
                          : "",
                      },
                      [_vm._v("\n\t\t\t\t" + _vm._s(carouselText) + "\n\t\t\t")]
                    )
                  : _vm._e(),
              ]
            )
          }
        ),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }