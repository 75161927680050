<template>
	<section
		class="section hero has-spacing hero-tour-detail hero-section test2"
		id="top-section-tour"
		:class="!heromodel?.heroWidth ? 'hero-full' : 'container'">
		<div class="hero has-spacing hero-tour-detail">
			<div
				class="hero-overlay"
				:style="{ opacity: opacityValue + '%' }"></div>
			<img
				v-if="heromodel && heromodel?.heroImageUrl"
				:src="heromodel.heroImageUrl"
				class="is-background"
				:alt="heromodel?.brandLogoAltText" />

			<div class="hero__content">
				<div class="hero__card card">
					<div class="hero__card__image">
						<img
							v-if="
								heromodel?.brandLogoUrl &&
								generatedmodel?.brandLogo
							"
							class="generatedmodel.isSquareLogo ? 'is-square' : '')"
							:src="heromodel?.brandLogoUrl"
							:alt="heromodel?.brandLogoAltText" />
					</div>
					<div class="hero__card__content card-content">
						<h2
							class="hero-title"
							v-if="heromodel?.tourName != ''">
							{{ heromodel?.tourName }}
						</h2>
						<p
							class="hero-subTitle"
							v-if="
								heromodel?.tourSubName &&
								(raqmodel.tour.isV4Tour ||
									raqmodel.tour.isC1Tour)
							">
							{{ heromodel?.tourSubName }}
						</p>
						<p
							class="pricefrom"
							v-if="heromodel?.fromPrice != ''">
							{{ heromodel?.priceFromLabel }}
							{{ heroavailability.airfareIncluded ? "*" : "" }}
						</p>

						<div class="details">
							<div class="columns priceholder details-first">
								<p
									class="price column"
									v-if="heromodel?.fromPrice != ''">
									{{ heromodel?.fromPrice }}
									<span>{{
										heroavailability.airfareIncluded
											? "*"
											: ""
									}}</span>
								</p>
								<p
									class="pricewas column"
									v-if="
										heromodel?.originalAdultPrice &&
										heromodel?.originalAdultPrice !=
											heromodel?.fromPrice
									">
									<span class="strikethrough">
										{{
											heromodel?.originalAdultPrice
										}}</span
									>
								</p>
							</div>
							<div class="days details-last">
								<p>{{ heromodel?.days }} days</p>
							</div>
						</div>
						<p
							class="terms"
							v-if="
								heroavailability.airfareIncluded &&
								heroavailability.includesAirfareText !== ''
							">
							{{ heroavailability.includesAirfareText }}
						</p>
						<p
							class="login-for-price-call-to-action"
							v-if="
								generatedmodel?.showLoginCallToAction &&
								!raqmodel.isLoggedIn
							">
							<img
								:src="raqmodel.loginForPriceModel.logoUrl"
								:alt="raqmodel.loginForPriceModel.logoAltText"
								class="logo-image" />
							<a
								:href="raqmodel.loginForPriceModel.loginUrl"
								class="login-link customer-price-link"
								@click="dataLayerLoginForPriceClicked()">
								{{ raqmodel.loginForPriceModel.text }}
							</a>
						</p>
					</div>
					<footer class="hero__card__footer card-footer">
						<div class="card-footer-item">
							<div
								id="feature-hero_cta"
								class="hero-cta"></div>
							<a
								v-if="
									generatedmodel?.tdlButton2Text &&
									!raqmodel.tour.useWidget
								"
								class="button is-secondary hero-cta js-scroll-link cta"
								href="#availability-section">
								{{ generatedmodel.tdlButton2Text }}
							</a>
							<button
								v-else-if="raqmodel?.tour.useWidget"
								id="show-ttc-widget-button-hero"
								class="button is-secondary hero-cta cta hero-widget-button">
								{{ raqmodel.tour.widgetButtonText }}
							</button>
						</div>
					</footer>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "hero-section",
	data() {
		return {
			opacityValue: 50,
		};
	},
	props: ["heromodel", "raqmodel", "generatedmodel", "heroavailability"],
	methods: {
		dataLayerLoginForPriceClicked() {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: "Login Clicked From Trip Page" });
		},
	},
	mounted() {
		this.opacityValue =
			this.heromodel?.opacityHeroValue != ""
				? this.heromodel.opacityHeroValue
				: this.opacityValue;
	},
};
</script>
