import eventBus from '../../vue-functions/event-bus';

function destinationFilterActions(target) {
    let ddTarget = target; 

    if(!this.$data.settings.filtersLocked) {
      
        eventBus.$emit('destinations-set-active-states');
        this.setFilterVisibility('showDestinationFilter');

        if(this.$props.appId === 'app-embeddedSearch'){
            this.scrollToElement(this.$el);
        }else{
            this.scrollToFilterSmallScreen(ddTarget);
        }
    }
}
module.exports = destinationFilterActions;
