var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { "is-active": _vm.filterVisibility.showPriceFilter },
    },
    [
      _c("div", { staticClass: "dropdown-trigger" }, [
        _c(
          "a",
          {
            staticClass: "button is-filter",
            class: { "is-filter-active": _vm.settings.filtersText.priceText },
            attrs: {
              href: "#",
              "aria-haspopup": "true",
              "aria-controls": "dropdown-prices",
              disabled: _vm.settings.loading,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("price-filter-actions")
              },
            },
          },
          [
            !_vm.settings.filtersText.priceText
              ? _c("span", [
                  _vm._v(_vm._s(_vm.settings.filterTripsContent.priceMenuText)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.settings.filtersText.priceText
              ? _c("span", [_vm._v(_vm._s(_vm.settings.filtersText.priceText))])
              : _vm._e(),
            _vm._v(" "),
            !_vm.settings.filtersLocked
              ? _c("span", { staticClass: "icon is-small" }, [
                  _c("i", {
                    staticClass: "fas fa-chevron-down",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fas fa-chevron-up",
                    attrs: { "aria-hidden": "true" },
                  }),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu dropdown-prices",
          attrs: { id: "dropdown-prices", role: "presentation" },
        },
        [
          _c("div", { staticClass: "dropdown-header" }, [
            _c("div", { staticClass: "dropdown-header-wrap" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-header-close" }, [
                _c(
                  "button",
                  {
                    staticClass: "dropdown-close",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("set-filter-visibility")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "is-sr-only" }, [
                      _vm._v("Close Menu"),
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "dropdown-close__icon delete",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "rangesliderholder" },
              [
                _c("search-price-slider", {
                  attrs: {
                    currency: _vm.currency,
                    pslider: _vm.filterVisibility.showPriceFilter,
                    minval: _vm.settings.filtersValues.minPriceVal,
                    maxval: _vm.settings.filtersValues.maxPriceVal,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-footer search-footer" }, [
            _c("div", { staticClass: "buttonholder search-clear-apply" }, [
              _c(
                "button",
                {
                  staticClass: "btn-clear",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("reset-price-slider")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tClear Selection\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-apply",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("filter-search")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tApply Selection\n\t\t\t\t")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dropdown-header-data" }, [
      _c("h4", { staticClass: "dropdown-title" }, [
        _vm._v("Budget Per Person"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }