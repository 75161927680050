import Vue from 'vue';
import Banner from '../vue-components/generic/generic-banner.vue';
function BrandView() {
    new Vue({
        el: '#generic-banner',
        data () {
            return {
                bannerdata: window.Cms.bannerModel
            };
        },
        components: {
            'generic-banner': Banner,
        },
    });
};
module.exports = BrandView;