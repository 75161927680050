import Vue from 'vue';
import HeroCarousel from '../../vue-components/heros/hero-carousel.vue';
function heroCarouselView() {
    new Vue({
        el: '#hero-carousel-section',
        data () {
            return {
                herocarouseldata: window.Cms.heroModel
            };
        },
        components: {
            'hero-carousel': HeroCarousel,
        }
    });
};
module.exports = heroCarouselView;