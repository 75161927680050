<template>
	<div class="ttc-vue-slider vue-range-slider">
		<div class="rangefromto">
			<div class="rangefrom">
				<input
					type="text"
					maxlength="2"
					oninput="this.value=this.value.replace(/[^0-9]/g,'');"
					id="durationfrom"
					aria-label="StartDuration"
					v-model="value[0]"
					@change="passValue"
					:min="this.min"
					:max="this.max"
					class="input has-value" />
			</div>
			<div class="rangeto">
				<input
					type="text"
					maxlength="2"
					oninput="this.value=this.value.replace(/[^0-9]/g,'');"
					id="durationto"
					aria-label="DurationUpto"
					v-model="value[1]"
					@change="passValue"
					:min="this.min"
					:max="this.max"
					class="input has-value" />
			</div>
		</div>
	</div>
</template>
<script>
import vueSlider from "vue-slider-component";
import * as searchConstants from "../../search-constants.js";
import eventBus from "../../vue-functions/event-bus.js";

export default {
	props: ["minval", "maxval", "dslider"],
	components: { vueSlider },
	data: function () {
		return {
			value: [
				searchConstants.DURATIONSLIDERMINVAL,
				searchConstants.DURATIONSLIDERMAXVAL,
			],
			min: searchConstants.DURATIONSLIDERMINVAL,
			max: searchConstants.DURATIONSLIDERMAXVAL,
			dotSize: 26,
			keyboard: true,
			width: "100%",
			tooltip: false,
			valuesObject: {
				minDuration: searchConstants.DURATIONSLIDERMINVAL,
				maxDuration: searchConstants.DURATIONSLIDERMAXVAL,
			},
		};
	},
	// watch: {
	// 	minval(val) {
	// 		if (!val) {
	// 			this.valuesObject.minDuration = 0;
	// 			this.value[0] = 0;
	// 			this.minval = 0;
	// 		} else {
	// 			this.valuesObject.minDuration = val;
	// 			this.value[0] = val;
	// 		}
	// 	},
	// 	maxval(val) {
	// 		if (!val) {
	// 			this.valuesObject.maxDuration = 0;
	// 			this.value[0] = 0;
	// 			this.maxval = 0;
	// 		} else {
	// 			this.valuesObject.maxDuration = val;
	// 			this.value[1] = val;
	// 		}
	// 	},
	// },
	mounted: function () {
		if (this.minval) {
			this.value[0] = parseInt(this.minval);
		}
		if (this.maxval) {
			this.value[1] = parseInt(this.maxval);
		}
	},
	methods: {
		passValue() {
			if (this.value[0] == this.min) {
				this.valuesObject.minDuration = null;
			} else {
				this.valuesObject.minDuration = this.value[0];
			}
			if (this.value[1] == this.max) {
				this.valuesObject.maxDuration = null;
			} else {
				this.valuesObject.maxDuration = this.value[1];
			}
			this.$parent.$emit("durationsliderval", this.valuesObject);
		},
		resetSlider() {
			this.$refs.slider.setValue([0, 40]);
		},
	},
	created() {
		eventBus.$on("duration-slider-reset", () => {
			this.resetSlider();
		});
	},
};
</script>
