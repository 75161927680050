var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "map-expand-button" }, [
    _c(
      "a",
      {
        staticClass: "level expand",
        attrs: { href: "#", "aria-label": "map Expand" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleModal()
          },
        },
      },
      [_vm._m(0)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon expand-btn" }, [
      _c("i", { staticClass: "fas fa-expand" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }