function setFilterVisibility(callerId) {

    if(this.$data.settings.filtersLocked) {
        return false;
    }
    
    let clickedFilter = callerId || '';
    let filterVisObj = this.$data.filterVisibility;

    Object.keys(filterVisObj).forEach(function(key) {
        if(key === clickedFilter) {
            filterVisObj[key] = !filterVisObj[key];
        } else {
            filterVisObj[key] = false;
        }
    });
    if(this.$props.appId === 'app-embeddedSearch' && clickedFilter === ''){
        this.scrollToFilterSmallScreen(this.$el);
    };
}
module.exports = setFilterVisibility;   