import Vue from 'vue';
import FeatureDestinations from '../vue-components/featured/featured-destinations.vue';
function featureDestinations() {
    new Vue({
        el: '#featured-destinations',
        data() {
            return {
                featureddata: window.Cms.featureddestinations,
            };
        },
        components: {
            'featured-destinations': FeatureDestinations,
        }
    });
};
module.exports = featureDestinations;