function handleApplyClick (method) {
    switch(method){
        case 'setFilterVisibility':
            this.setFilterVisibility();
        break;
        case 'filterSearch':
            this.filterSearch();
        break;
    }
}
module.exports = handleApplyClick;