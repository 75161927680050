import { render, staticRenderFns } from "./itinerary-dayHighlights.vue?vue&type=template&id=e51a10f2&"
import script from "./itinerary-dayHighlights.vue?vue&type=script&lang=js&"
export * from "./itinerary-dayHighlights.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports