var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown" }, [
    _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button",
          class: {
            "filters-active":
              _vm.selectedRoute && _vm.selectedRoute !== "all-routes",
            "no-dropdown": _vm.routes.length == 1,
          },
          attrs: { disabled: _vm.apierror },
        },
        [
          !_vm.routes.length && !_vm.apierror
            ? _c("span", [
                _c("i", { staticClass: "fas fa-lg fa-spin fa-circle-notch" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.routes.length > 1 && _vm.selectedRoute === "all-routes"
            ? _c("span", [_vm._v("Filter by Route")])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedRoute !== "all-routes"
            ? _c("span", [_vm._v(_vm._s(_vm.selectedRoute))])
            : _vm._e(),
          _vm._v(" "),
          _vm.routes.length == 1
            ? _c("span", { domProps: { textContent: _vm._s(_vm.routes[0]) } })
            : _vm._e(),
          _vm._v(" "),
          _vm.routes.length > 1
            ? _c("span", { staticClass: "icon is-small" }, [
                _c("i", {
                  staticClass: "fas fa-angle-down",
                  attrs: { "aria-hidden": "true" },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.routes.length > 1
      ? _c(
          "ul",
          { staticClass: "dropdown-menu dropdown-content" },
          [
            _c(
              "li",
              {
                staticClass: "dropdown-item is-paddingless is-flex",
                class: _vm.selectedRoute === "all-routes" ? "selected" : "",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "dropdown-label",
                    attrs: { for: "all-routes" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedRoute,
                          expression: "selectedRoute",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "all-routes",
                        value: "all-routes",
                      },
                      domProps: {
                        checked: _vm._q(_vm.selectedRoute, "all-routes"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            _vm.selectedRoute = "all-routes"
                          },
                          function ($event) {
                            return _vm.updateSelectedRoute(_vm.selectedRoute)
                          },
                        ],
                      },
                    }),
                    _vm._v("\n                All Routes\n            "),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.routes, function (route) {
              return _c(
                "li",
                {
                  key: route.index,
                  staticClass: "dropdown-item is-paddingless is-flex",
                  class: route === _vm.selectedRoute ? "selected" : "",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "dropdown-label",
                      attrs: { for: route.index },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedRoute,
                            expression: "selectedRoute",
                          },
                        ],
                        attrs: { type: "radio", id: route.index },
                        domProps: {
                          value: route,
                          checked: _vm._q(_vm.selectedRoute, route),
                        },
                        on: {
                          change: [
                            function ($event) {
                              _vm.selectedRoute = route
                            },
                            function ($event) {
                              return _vm.updateSelectedRoute(_vm.selectedRoute)
                            },
                          ],
                        },
                      }),
                      _vm._v(
                        "\n                " + _vm._s(route) + "\n            "
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }