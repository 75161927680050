var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "generic-banner subsection container" }, [
    _vm.bannerdata?.showBanner
      ? _c(
          "div",
          {
            staticClass: "has-text-centered section",
            class: [
              _vm.bannerdata?.brandLogoHasValue &&
              !_vm.bannerdata?.bannerBackgroundColour
                ? `${_vm.bannerdata?.brandUrlSegment}`
                : "partner-banner",
            ],
            style: {
              backgroundColor: "#" + _vm.bannerdata?.bannerBackgroundColour,
            },
          },
          [
            _vm.bannerdata?.bannerHeaderText
              ? _c(
                  "h2",
                  {
                    staticClass: "hero-marketing-banner__header",
                    style: { color: "#" + _vm.bannerdata?.bannerTextColour },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(_vm.bannerdata?.bannerHeaderText) +
                        "\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.bannerdata?.bannerSubHeaderText
              ? _c(
                  "p",
                  {
                    staticClass:
                      "hero-marketing-banner__subheader has-text-weight-normal",
                    style: { color: "#" + _vm.bannerdata?.bannerTextColour },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(_vm.bannerdata?.bannerSubHeaderText) +
                        "\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }