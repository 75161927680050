<template>
  <div
    class="modal aboutus-modal modal-geq"
    :class="{ 'is-active': active }"
    @keydown.esc.prevent="closeForm()"
    v-if="datamodal"
    role="dialog"
    aria-labelledby="aboutus_dialog"
    aria-modal="true"
  >
    <div class="modal-background" @click.prevent="closeForm()"></div>
    <div class="modal-card">
      <div class="modal-form">
        <div class="modal-card-head">
          <a
            class="modal-card-close bg-transparent"
            href="#"
            @click.prevent="closeForm()"
            aria-label="close"
          >
            <i class="fa fa-times fa-lg"></i>
          </a>
        </div>
        <div class="modal-card-body">
          <div class="aboutus-logo-image">
            <img :src="datamodal.logoUrl" :alt="datamodal.logoAlt" />
          </div>
          <div class="aboutus-info" v-html="datamodal.aboutUsText"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../../vue-functions/event-bus";
import { closeModal } from "../../../js/modals/closeModal";
export default {
  data() {
    return {
      isLoading: false,
      active: false,
      modal: false,
      targetButton: "",
      datamodal: "",
    };
  },
  props: ["modaldata"],
  methods: {
    show() {
      this.active = true;
      this.toggleBodyClass();
    },
    toggleBodyClass() {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.toggle("is-modal-active");
    },
    closeForm() {
      this.active = false;
      document.documentElement.classList.remove("is-clipped");
      this.toggleBodyClass();
      document
        .querySelector(".modal-card-body")
        .parentElement.classList.remove("scroll-more");
    },
    closeModal,
  },
  created: function() {
    EventBus.$on("toggle-aboutus-modal", () => {
      document.documentElement.classList.add("is-clipped");
      this.show();
    });
    EventBus.$on("aboutus-modal-data", (data) => {
      this.datamodal = data;
    });
    if (!this.active) {
      EventBus.$on("update-aboutus-target", (target) => {
        this.targetButton = target;
        this.selectedButton = target;
      });
    }
  },
  mounted() {
    this.closeModal(this.closeModal);
  },
  watch: {
    active() {
      if (this.active) {
        setTimeout(() => {
          const modal = document.querySelector(
            ".aboutus-modal .modal-card-close"
          );
          modal.focus();
        }, 200);
      } else {
        this.selectedButton.focus();
      }
    },
  },
};
</script>
