var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "homepage-hero-outer hero",
      class: !_vm.herodata.heroWidth ? "hero-full" : "container",
    },
    [
      _c("div", {
        staticClass: "hero-overlay",
        style: { opacity: _vm.opacityValue + "%" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "feature__content homepage-hero-container" }, [
        _c("div", { staticClass: "homepage-hero searchbarholder" }, [
          _c("h1", { staticClass: "homepage-hero_header" }, [
            _vm._v(
              "\n\t\t\t\t" + _vm._s(_vm.herodata.searchTitle) + "\n\t\t\t"
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.herodata.imageUrl
        ? _c("img", {
            staticClass: "is-background",
            attrs: {
              src: _vm.herodata.imageUrl,
              alt: _vm.herodata.imageAltText,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "embeddedSearch-wrap" },
        [
          _c("app-embedded-search", {
            attrs: { id: "app-embeddedSearch", "app-id": _vm.appId },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }