function durationFilterActions(target) {
    
    if(!this.$data.settings.filtersLocked) {
        this.setFilterVisibility('showDurationFilter');

        if(this.$props.appId === 'app-embeddedSearch'){
            this.scrollToElement(this.$el);
        }else{
            this.scrollToFilterSmallScreen(target);
        }
    }
}
module.exports = durationFilterActions;