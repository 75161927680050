var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.featureddata, function (feature) {
      return _c("section", { staticClass: "destinations subsection section" }, [
        _c("div", { staticClass: "site-section" }, [
          _c("h2", { staticClass: "site-section-title" }, [
            _vm._v(
              "\n\t\t\t\t" + _vm._s(feature.featureContentTitle) + "\n\t\t\t"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "featured-destinations__wrap" },
          _vm._l(feature.destinations, function (item) {
            return _c(
              "div",
              {
                staticClass: "featured-destinations__item",
                attrs: {
                  "data-type": "navigation",
                  "data-name": "feature-content-pod",
                },
              },
              [
                item.destinationLinkUrl
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: item.destinationLinkUrl,
                          "aria-label": item.destinationLinkName,
                          target: "_self",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "featured-destinations__card" },
                          [
                            item.destinationImageUrl
                              ? _c("img", {
                                  staticClass:
                                    "is-background featured-destinations__image",
                                  attrs: {
                                    alt: item.destinationImageAltText
                                      ? item.destinationImageAltText
                                      : item.destinationLinkName,
                                    src: item.destinationImageUrl,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "featured-destinations__content" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "featured-destinations__content-overlay",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "featured-destinations__content-center",
                                      },
                                      [
                                        feature.hoverText
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "featured-destinations__content-smallText",
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(feature.hoverText) +
                                                    "\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "featured-destinations__content-title",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.destinationDescriptionText
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    feature.hoverButton
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "featured-destinations__content-bottom",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "featured-destinations__content-btn",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "btn-text" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        feature.hoverButton
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("i", {
                                                  staticClass:
                                                    "arrow fa fa-arrow-right",
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }