var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.featuredsection
    ? _c("section", { staticClass: "featured-content" }, [
        _c("div", { staticClass: "columns" }, [
          _c(
            "div",
            {
              staticClass: "column featured-content__desc",
              class: _vm.featuredsection.hideIntroductionMedia
                ? "is-full-desktop"
                : "is-6-tablet is-5-desktop",
            },
            [
              _c("div", {
                staticClass: "featured-content__text",
                domProps: { innerHTML: _vm._s(_vm.featuredsection.introText) },
              }),
            ]
          ),
          _vm._v(" "),
          !_vm.featuredsection.hideIntroductionMedia
            ? _c(
                "div",
                {
                  staticClass:
                    "column is-6-tablet is-7-desktop featured-content__media",
                },
                [
                  _vm.featuredsection.introImageUrl &&
                  _vm.featuredsection.introVideoUrl == ""
                    ? _c("div", {
                        staticClass: "featured-content__media-image",
                        style: {
                          backgroundImage:
                            "url('" + _vm.featuredsection.introImageUrl + "')",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.featuredsection.introVideoUrl
                    ? _c(
                        "div",
                        { staticClass: "featured-content__media-video" },
                        [
                          _c("embed", {
                            attrs: {
                              title: _vm.featuredsection.brandName,
                              src: _vm.featuredsection.introVideoUrl,
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }