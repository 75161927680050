var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "searchlistings" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.settings.totalResults || _vm.settings.showMoreLoading
          ? _c(
              "div",
              _vm._l(_vm.products, function (product, productIndex) {
                return _c(
                  "article",
                  {
                    key: "product" + productIndex,
                    staticClass: "ttc-search-items",
                  },
                  [
                    product.results.length > 0
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.tourResults,
                            function (result, resultIndex) {
                              return _c(
                                "div",
                                {
                                  key: "tourResult" + resultIndex,
                                  staticClass: "searchlistings__listing",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "columns image-container" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "searchlistings__listing__image column is-3-tablet",
                                        },
                                        [
                                          result.primaryImageUrl
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "searchlistings__listing__image__link",
                                                  style: [
                                                    !result.isCmsTour
                                                      ? {
                                                          backgroundImage:
                                                            "url('" +
                                                            result.primaryImageUrl +
                                                            "')",
                                                          backgroundPosition:
                                                            "50%",
                                                        }
                                                      : {
                                                          backgroundImage:
                                                            "url('" +
                                                            result.primaryImageUrl +
                                                            "')",
                                                          backgroundPosition:
                                                            "" +
                                                            result.leftFocalPoint +
                                                            " " +
                                                            result.topFocalPoint +
                                                            "",
                                                        },
                                                  ],
                                                  attrs: {
                                                    target: "_blank",
                                                    "aria-label": "image",
                                                    href: result.url,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "is-background mobile-visible",
                                                    attrs: {
                                                      src: result.primaryImageUrl,
                                                      alt: result.primaryImageAltText
                                                        ? result.primaryImageAltText
                                                        : result.tourName,
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !result.primaryImageUrl
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    target: "_blank",
                                                    href: result.url,
                                                    "aria-label": "image",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "is-background",
                                                    attrs: {
                                                      src: _vm.settings
                                                        .searchResultContent
                                                        .alternativeSearchImage,
                                                      alt: _vm.settings
                                                        .searchResultContent
                                                        .alternativeSearchImageAltText
                                                        ? _vm.settings
                                                            .searchResultContent
                                                            .alternativeSearchImageAltText
                                                        : "search-image-placeholder-image",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          result?.groupSizeText
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "group-container",
                                                  class: {
                                                    showGroupSize:
                                                      result.hasGroupSizeVariants,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "image group-container-image",
                                                    attrs: {
                                                      src: _vm.settings
                                                        .searchResultContent
                                                        .groupSizeImage,
                                                      alt: _vm.settings
                                                        .searchResultContent
                                                        .groupSizeImageAltText
                                                        ? _vm.settings
                                                            .searchResultContent
                                                            .groupSizeImageAltText
                                                        : "Group Size logo",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "group-container-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          result?.groupSizeText
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          result?.smallGroupText
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "group-container",
                                                  class: {
                                                    showSmallGroup:
                                                      result.isSmallGroup &&
                                                      !result.hasGroupSizeVariants,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "image group-container-image",
                                                    attrs: {
                                                      src: _vm.settings
                                                        .searchResultContent
                                                        .smallGroupsImage,
                                                      alt: _vm.settings
                                                        .searchResultContent
                                                        .smallGroupsImageAltText
                                                        ? _vm.settings
                                                            .searchResultContent
                                                            .smallGroupsImageAltText
                                                        : "small Groups Image logo",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "group-container-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          result?.smallGroupText
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "searchlisting-brand-image-ss",
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "image is-paddingless",
                                                class: {
                                                  "is-square":
                                                    _vm.brandLogoSizeArray[
                                                      result.brand.brandValue
                                                    ],
                                                },
                                                attrs: {
                                                  src:
                                                    _vm.locationString +
                                                    result.brand.logoUrl,
                                                  alt: result.brand.logoAltText
                                                    ? result.brand.logoAltText
                                                    : result.brand.brandValue +
                                                      " logo",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "searchlistings__listing__desc column is-relative",
                                        },
                                        [
                                          result.travelTypes != null &&
                                          _vm.hideType
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "searchlisting-travel-style",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "level-left",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "category",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.settings
                                                                  .searchResultContent
                                                                  .travelTypeText
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "searchlisting__item",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  result
                                                                    .travelTypes
                                                                    .name
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          result.travelStyles
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "searchlisting-travel-style",
                                                },
                                                _vm._l(
                                                  result.travelStyles,
                                                  function (
                                                    travelStyle,
                                                    travelStyleIndex
                                                  ) {
                                                    return _c(
                                                      "span",
                                                      {
                                                        key:
                                                          "travelStyle" +
                                                          travelStyleIndex,
                                                        staticClass:
                                                          "searchlisting__item",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              travelStyleIndex ==
                                                                0
                                                                ? travelStyle
                                                                : ", " +
                                                                    travelStyle
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "searchlisting-tour-title",
                                              attrs: {
                                                target: "_blank",
                                                href: result.url,
                                              },
                                            },
                                            [
                                              _c("h3", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    result.tourName
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column search-listing-brand-image-ls",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "image",
                                                attrs: {
                                                  src:
                                                    _vm.locationString +
                                                    result.brand.logoUrl,
                                                  alt: result.brand.logoAltText
                                                    ? result.brand.logoAltText
                                                    : result.brand.brandValue +
                                                      " image",
                                                },
                                              }),
                                              _vm._v(" "),
                                              result.showAboutUs &&
                                              result.aboutUsTextLink != ""
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "searchlisting-aboutUs",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.LaunchGenericModal(
                                                            {
                                                              logoUrl:
                                                                result.brand
                                                                  .logoUrl,
                                                              logoAlt:
                                                                result.brand
                                                                  .logoAltText,
                                                              aboutUsText:
                                                                result.aboutUsModalText,
                                                            }
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass:
                                                          "fas fa-info-circle",
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          result.aboutUsTextLink
                                                        ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "searchlisting-tour-data isLoggedOut",
                                            },
                                            [
                                              result.numberOfDays
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "searchlisting-tour-data-item",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-calendar",
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "searchlisting__item",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                result.numberOfDays
                                                              ) +
                                                              " days\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("search-location-item", {
                                                attrs: {
                                                  settings: _vm.settings,
                                                  result: result,
                                                  citiesCount:
                                                    result?.locationsVisited?.split(
                                                      ","
                                                    ).length,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("search-country-item", {
                                                attrs: {
                                                  settings: _vm.settings,
                                                  result: result,
                                                  countryCount:
                                                    result?.countriesVisited?.split(
                                                      ","
                                                    ).length,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          result.airfareIncluded
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "searchlisting-tour-airfareIncludedText",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-plane",
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "searchlisting__item",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            result.airfareIncludedText
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          result.hasVariants &&
                                          !!_vm.settings.searchResultContent
                                            .tripVariantsText
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "searchlisting-more-options",
                                                  attrs: {
                                                    href: `${result.url}/#trip-options-section`,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "more-options-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.settings
                                                              .searchResultContent
                                                              .tripVariantsText
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "searchlistings__listing__pricing column",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "columns searchlisting-pricing-wrapper",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "column" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "pricefrom",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.settings
                                                              .searchResultContent
                                                              .priceFromText
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "columns priceholder",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "price column",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                product.currencySymbol
                                                              ) +
                                                              _vm._s(
                                                                result.fromAdultPrice.toLocaleString()
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                          result.airfareIncluded
                                                            ? _c("span", [
                                                                _vm._v("*"),
                                                              ])
                                                            : _vm._e(),
                                                          result.cmsCurrency
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "is-size-7",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.currency(
                                                                          result.cmsCurrency
                                                                        )
                                                                      )
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      result.originalAdultPrice &&
                                                      result.fromAdultPrice !=
                                                        result.originalAdultPrice
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "pricewas column",
                                                            },
                                                            [
                                                              _c("span", {}, [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "strikethrough",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          product.currencySymbol
                                                                        ) +
                                                                        _vm._s(
                                                                          result.originalAdultPrice.toLocaleString()
                                                                        ) +
                                                                        "\n                          "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                result.cmsCurrency
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "is-size-7",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.currency(
                                                                                result.cmsCurrency
                                                                              )
                                                                            )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          result.brand.showCallToAction &&
                                          product.loginCallToAction.isEnabled &&
                                          !product.loginCallToAction.isLoggedIn
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "login-for-price-call-to-action",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "logo-image",
                                                    attrs: {
                                                      src:
                                                        _vm.locationString +
                                                        product
                                                          .loginCallToAction
                                                          .logoUrl,
                                                      alt: result.brand
                                                        .logoAltText,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "login-link",
                                                      attrs: {
                                                        id: "customer-price-link",
                                                        href: product
                                                          .loginCallToAction
                                                          .loginUrl,
                                                        type: "submit",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.dataLayerLoginForPriceClicked()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            product
                                                              .loginCallToAction
                                                              .text
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "buttonholder level searchlisting-cta-wrapper",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "level-left" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "button is-primary is-level cta",
                                                      attrs: {
                                                        target: "_blank",
                                                        href: result.url,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.settings
                                                            .searchResultContent
                                                            .viewVacationButtonText
                                                        ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "button is-subtle is-level cta",
                                                      attrs: {
                                                        "data-type":
                                                          "booking interest",
                                                        "data-name": "raq",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.launchEasyQuote(
                                                            result.brand
                                                              .brandValue,
                                                            result.brand.name,
                                                            result.tourName,
                                                            result.aaeCategory,
                                                            result.isCmsTour,
                                                            result.tourDate,
                                                            $event,
                                                            result.emailLead,
                                                            result.url,
                                                            result.tourId,
                                                            result.tourOptionId,
                                                            result.isUniworldTour,
                                                            result.isUbyTour
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.settings
                                                              .searchResultContent
                                                              .quoteButtonText
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "is-relative" }, [
        _vm.settings.showMoreLoading
          ? _c("div", { staticClass: "search-results-show-more" }, [
              _c("div", { staticClass: "loader-bar" }),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.settings.loading &&
        !_vm.settings.showMoreLoading &&
        _vm.settings.parentSearch &&
        _vm.settings.resultsInView < _vm.settings.totalResults
          ? _c("div", { staticClass: "search-results-show-more" }, [
              _c("div", { staticClass: "inner" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-subtle is-outlined cta",
                    attrs: { "aria-label": "ShowMore" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("show-more-results")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.settings.searchResultContent.showMore) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.settings.loading && !_vm.settings.parentSearch && !_vm.settings.error
        ? _c("div", { staticClass: "search-results-go-main" }, [
            _c("div", { staticClass: "inner" }, [
              _c(
                "button",
                {
                  staticClass: "button is-subtle is-outlined is-fullwidth",
                  attrs: {
                    "data-type": "general interaction",
                    "data-name": "button",
                    "aria-label": "MoreResults",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("exit-to-main-search")
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.settings.searchResultContent.searchMoreResults)
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.settings.searchResultContent.opensMainSearch)
                    ),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.settings.loading &&
      _vm.settings.totalResults === 0 &&
      !_vm.settings.showMoreLoading
        ? _c("div", [
            !_vm.settings.error
              ? _c("div", { staticClass: "search__loading-loader" }, [
                  _c("div", { staticClass: "loader-bar is-marginless" }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "search__loading" }, [
              _c("h3", {
                domProps: {
                  textContent: _vm._s(
                    _vm.settings.searchResultContent.loadingMessage
                      ? _vm.settings.searchResultContent.loadingMessage
                      : "We're searching for the right trips. Please wait while we find your results."
                  ),
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.settings.loading && _vm.settings.totalResults === 0
        ? _c("div", { staticClass: "search__loading" }, [
            _c("h3", {
              domProps: {
                textContent: _vm._s(
                  _vm.settings.searchResultContent.noSearchResultsMessage
                    ? _vm.settings.searchResultContent.noSearchResultsMessage
                    : "Looks like we couldn't find any trips matching your search criteria"
                ),
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }