const $ = require('jquery');
function uniworldModal() {
    
    const itineraryModal = $(".itinerary-modal");
    const modalTitle = itineraryModal.find(".modal-card-title");
    const modalContent = itineraryModal.find(".modal-card-content");
    const modalCloseButton = itineraryModal.find('.modal-card-close');

    $('.itinerary-modal-trigger').click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        const modalTitleText = $(this).data('excursion-title');
        const modalContentText = $(this).data('excursion-details');
        itineraryModal.addClass('is-active');
        modalTitle.html(modalTitleText);
        modalContent.html(modalContentText);
        modalCloseButton.click(function() {
            itineraryModal.removeClass('is-active');
        })
        $(window).keydown(function(e) {
            if (e.keyCode == 27) {
                itineraryModal.removeClass('is-active');
            }
        })
    });
}

module.exports = uniworldModal;