import Vue from 'vue';
import TourMenuView from '../vue-components/tour-page/tourMenu.vue';
function tourMenuView() {
    new Vue({
        el: '#tour-menu',
        data() {
            return {
                datamenu: window.Cms.subNavModel
            };
        },
        components: {
            'tour-menu': TourMenuView,
        }
    });
};
module.exports = tourMenuView;