require('waypoints/lib/noframework.waypoints.js');
const $ = require('jquery');

 // function controls sub nav component used in various areas across site
function subnavControl () {
    const subnavControl = document.querySelector('.subnav-control');
    if(!subnavControl) {
        return;
    }

    let subnavValueField = subnavControl.querySelector('.subnav-selected-value');
    let subnavItems = Array.prototype.slice.call(subnavControl.querySelectorAll('.subnav__link'), 0);
    let firstDivIdElement = subnavItems[0].getAttribute('href');
    let subnavBtn = subnavControl.querySelector('.subnav-selected');
    const stickyClass = 'is-fixed';
    
    subnavItems.forEach((element, index) => {
        if(index == 0)
        {
            addSubnavActiveClass(element);
        }
    });

    // event for the button click to toggle small screen sub nav menu
    subnavBtn.addEventListener('click', () => {
       subnavControl.classList.toggle('is-subnav-active');
    });

    // function to add active class to any sub navs
    function addSubnavActiveClass(element) {

        subnavValueField.innerText = element.innerText;

        for(let i = 0; i < subnavItems.length; i++) {
            subnavItems[i].classList.remove('is-active');
        }

        element.classList.add('is-active');
        subnavControl.classList.remove('is-subnav-active');
    }

    function toggleSticky(scrollDirection) {
        if(scrollDirection == 'down') {
            subnavControl.classList.add(stickyClass);
        }
        if(scrollDirection == 'up') {
            subnavControl.classList.remove(stickyClass);
        } 
    }
    
    function waypoints(){
        // set the initial nav point
        let waypoint = new Waypoint({
            element: document.querySelector(firstDivIdElement),
            handler: function(direction) {
                toggleSticky(direction);
            },
            offset: 65
        });
        // add active class on scroll
        subnavItems.forEach(element => {
            var waypointElement = document.querySelector('#' + element.dataset.target);
            if(waypointElement != null)
            {
                let waypoint_up = new Waypoint({
                    element: waypointElement,
                    handler: function(direction){
                        if(direction == 'up') return;
                        subnavValueField.innerText = element.innerText;
                        // this should run in both directions so that curent place in page is highlighted
                        addSubnavActiveClass(element);
                    },
                    offset: 65
                });

                let waypoint_down = new Waypoint({
                    element: waypointElement,
                    handler: function(direction){
                        if(direction == 'down') return;
                        subnavValueField.innerText = element.innerText;
                        // this should run in both directions so that curent place in page is highlighted
                        addSubnavActiveClass(element);
                    },
                    offset: 60
                });
            }
        });
    }
    waypoints();

    function overflowScroll() {
        const subnavWrapper = $('.subnav-wrapper');
        const subnavWrapperWidth = subnavWrapper.outerWidth();
        const subnavLink = $('.subnav__link');
        const scrollLeftButton = $('.scroll-left');
        const scrollRightButton = $('.scroll-right');
        let scrollLeft = 0;
        let subnavLinkTotalWidth = 0;

        subnavLink.each(function() {
            subnavLinkTotalWidth += $(this).outerWidth();
        });
        
        const availableScroll = parseInt(subnavLinkTotalWidth - subnavWrapperWidth);

        if (subnavLinkTotalWidth > subnavWrapperWidth) {
            subnavWrapper.addClass('scrolling-active');            
            scrollRightButton.toggleClass('hidden active');
        }

        const scrollSpeed = 400;

        scrollRightButton.click(function() {
            scrollLeft += scrollSpeed;
            scrollLeftButton.removeClass('hidden');
            scrollLeftButton.addClass('active');
            subnavWrapper.animate({scrollLeft: scrollLeft}, 'fast');
            if (scrollLeft >= availableScroll) {
                $(this).removeClass('active').addClass('hidden');
            }
        });

        scrollLeftButton.click(function() {
            scrollLeft -= scrollSpeed;
            scrollRightButton.removeClass('hidden');
            scrollRightButton.addClass('active');
            subnavWrapper.animate({scrollLeft: scrollLeft}, 'fast');
            if (scrollLeft === 0) {
                $(this).removeClass('active').addClass('hidden');                          
            }
        });
    }
    overflowScroll();

    window.addEventListener('resize', function() {
        subnavControl.classList.remove(stickyClass);
    });
}
module.exports = subnavControl;