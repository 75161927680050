<template>
	<section class="section hero-container">
		<div
			class="hero-brand"
			:class="!herobranddata.heroWidth ? 'hero-full' : 'container'">
			<div
				class="hero-overlay"
				:style="{ opacity: opacityValue + '%' }"></div>
			<div
				class="hero-brand-wrap"
				:class="!herobranddata.heroWidth ? 'container' : ''">
				<div
					class="hero-brand-logo"
					v-if="
						herobranddata?.onlyBrandValue &&
						!herobranddata.hideBrandLogo
					">
					<img
						class="logo-image"
						:src="herobranddata?.brandLogoUrl"
						:alt="herobranddata?.brandLogoAltText" />
				</div>
				<div
					class="hero-brand-data"
					v-if="!herobranddata?.onlyBrandValue">
					<h2
						v-if="herobranddata?.heroTitle"
						class="hero-brand-title">
						{{ herobranddata?.heroTitle }}
					</h2>

					<p
						v-if="herobranddata?.subtitle"
						class="hero-brand-subtitle">
						{{ herobranddata?.subtitle }}
					</p>

					<a
						href="#searchbar"
						v-if="herobranddata?.ctaButton"
						class="button cta ctaButton"
						>{{ herobranddata?.ctaButton }}</a
					>
				</div>
			</div>

			<img
				class="is-background"
				v-if="herobranddata?.marketingBackgroundImageUrl"
				:src="herobranddata?.marketingBackgroundImageUrl"
				:alt="herobranddata?.marketingBackgroundImageAltText" />

			<!-- <hero-banner
				v-if="herobranddata.showBanner"
				:herobranddata="herobranddata"
				id="hero-banner"></hero-banner> -->
		</div>
	</section>
</template>
<script>
//import HeroBanner from "../../vue-components/heros/hero-banner.vue";
export default {
	name: "heroBrand",
	data() {
		return {
			opacityValue: 50,
		};
	},
	props: ["herobranddata"],
	// components: {
	// 	"hero-banner": HeroBanner,
	// },
	methods: {
		getHeroHeight() {
			let heroBrandHeight =
				document.querySelectorAll(".hero-brand")[0].offsetHeight;
			let heroBannerHeight =
				document.querySelector("#hero-banner").offsetHeight;
			let heroLayoutHeight =
				16 + heroBrandHeight + heroBannerHeight + "px";

			document.querySelector("#hero-brand-section").style.height =
				heroLayoutHeight;
		},
	},
	mounted() {
		this.opacityValue =
			this.herobranddata.opacityHeroValue != ""
				? this.herobranddata.opacityHeroValue
				: this.opacityValue;
	},
};
</script>
