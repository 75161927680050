var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "section hero-container",
      class: !_vm.herodata.heroWidth ? "hero-full" : "container",
    },
    [
      _c("div", {
        staticClass: "hero-overlay",
        style: { opacity: _vm.opacityValue + "%" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "hero-container__title",
          class: !_vm.herodata.heroWidth ? "container" : "",
        },
        [
          _c("h1", { staticClass: "headline-font" }, [
            _vm._v(_vm._s(_vm.herodata.title)),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.herodata.imageUrl
        ? _c("img", {
            staticClass: "is-background",
            attrs: { src: _vm.herodata.imageUrl, alt: _vm.herodata.altText },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }