<template>
	<div
		class="small-groups-filter is-flex slider-toggle"
		:class="{ disabled: settings.loading }">
		<label
			class="switch inline-center is-relative"
			for="small-groups"
			aria-labelledby="small-groups">
			<input
				type="checkbox"
				@change="setSmallGroupsFlag($event)"
				id="small-groups"
				name="small-groups"
				v-model="isSmallGroups"
				role="switch" />
			<div class="slider round"></div>
		</label>
		<p
			class="inline-center"
			:class="{ filterChecked: filterCheckbox }">
			{{
				settings.filterTripsContent.smallGroupsDisplayText
					? settings.filterTripsContent.smallGroupsDisplayText
					: "Small Groups"
			}}
		</p>
	</div>
</template>
<script>
import EventBus from "../../vue-functions/event-bus";
export default {
	props: {
		settings: {
			type: Object,
		},
	},
	data() {
		return {
			isSmallGroups: false,
			filtersClicked: false,
			filterCheckbox: false,
		};
	},
	methods: {
		setSmallGroupsFlag(e) {
			this.filtersClicked = true;
			this.$emit("set-small-groups-flag", this.isSmallGroups);
			this.$emit("filter-search");
		},
		checkDefaultValue() {
			let smallGroupsChecked = this.settings.defaultSmallGroupsFilter;
			let tagsHasSmallGroups = this.settings.tags === "SmallGroup";
			this.isSmallGroups = smallGroupsChecked || tagsHasSmallGroups;
		},
	},
	created() {
		this.checkDefaultValue();
		EventBus.$on("reset-small-groups-flag", () => {
			this.isSmallGroups = false;
		});
	},
};
</script>
