<template>
	<div class="brands__content columns">
		<div
			v-for="(card, index) in modulescard"
			data-type="navigation"
			data-name="travel-pod"
			class="pod-card"
			:key="index"
			:id="card.travelStyleId">
			<div class="pod-card-content">
				<div
					class="pod-card-header"
					v-if="card.imageUrl">
					<a
						v-if="card.linkUrl"
						:href="card.linkUrl"
						class="pod-card-content__link"
						:alt="card.title"
						:aria-label="card.title"
						:style="{
							backgroundImage: 'url(\'' + card.imageUrl + '\')',
							backgroundPosition:
								'' +
								card.leftFocalPoint +
								' ' +
								card.topFocalPoint +
								'',
						}">
					</a>
					<div
						v-if="!card.linkUrl"
						class="pod-card-content__link"
						:style="{
							backgroundImage: 'url(\'' + card.imageUrl + '\')',
							backgroundPosition:
								'' +
								card.leftFocalPoint +
								' ' +
								card.topFocalPoint +
								'',
						}"></div>
				</div>
				<div class="pod-card-content-body">
					<div
						class="pod-card-content-body__header"
						:class="card.iconUrl ? 'has-icon' : ''">
						<img
							v-if="card.iconUrl"
							class="header-icon"
							:src="card.iconUrl"
							:alt="card.iconAltTag ? card.iconAltTag : ''" />
						<a
							v-if="card.linkUrl"
							:href="card.linkUrl"
							class="header-title">
							<h3>{{ card.title }}</h3>
						</a>
					</div>
					<div
						class="pod-card-content-body-text"
						v-if="card.text">
						{{ card.text }}
					</div>
				</div>
				<div class="pod-card-footer">
					<div
						class="pod-card-footer-inner"
						v-if="card.linkUrl">
						<a
							class="pod-card-footer__link"
							:href="card.linkUrl"
							target="_blank"
							:aria-label="card.title"
							v-if="card.linkName != ''">
							{{ card.linkName }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {};
	},
	props: ["modulescard"],
	created() {},
};
</script>
