<template>
	<div class="itinerary section">
		<div class="itinerary-header"></div>
		<div class="itinerary-body">
			<div
				v-if="!itinerarymodel?.isDefaultMapImage"
				class="map itinerary-map">
				<div
					data-type="general interaction"
					data-name="map"
					id="app-mapModal"
					class="map-holder">
					<map-modal
						:mapTypeImage="true"
						:mapurl="itinerarymodel?.mapImageUrl"
						:tourtype="raqmodel.tour"></map-modal>
					<div class="map-image-wrap new-section">
						<img
							:src="itinerarymodel?.mapImageUrl?.split('?')[0]"
							class="itinerary-map-image fullimage"
							:class="raqmodel.tour.isV4Tour ? 'v4map' : 'c1Map'"
							:alt="itinerarymodel?.mapImageAltTag" />
						<map-modal-button></map-modal-button>
					</div>
				</div>
			</div>
			<div
				id="itinerary-section"
				class="lead itinerary-days"
				data-type="general interaction"
				data-name="itinerary">
				<section class="accordions">
					<div class="accordion-tabs-wrap">
						<h3 class="itinerary-text">
							{{ dictionarymodel?.itinerary }}
						</h3>
						<div class="accordion-tabs-container">
							<span
								class="accordion-tab accordion-collapse"
								role="button"
								tabindex="0"
								>{{ dictionarymodel.collapseAll }}</span
							>
							<span
								class="accordion-tab accordion-expand"
								role="button"
								tabindex="0"
								>{{ dictionarymodel.expandAll }}</span
							>
						</div>
					</div>
					<div>
						<itinerary-accordion
							v-for="(day, index) in itinerarymodel.itinerary"
							:key="index"
							:itinerarymodel="itinerarymodel"
							:dictionarymodel="dictionarymodel"
							:day="day"
							:index="index"
							:tourapi="raqmodel.tour"></itinerary-accordion>
					</div>
				</section>
			</div>
		</div>

		<div
			id="itinerary-modal"
			class="itinerary-modal modal wide-modal itinerary-modal">
			<div class="modal-background"></div>
			<div class="modal-card">
				<div class="modal-form">
					<div class="modal-card-head">
						<p class="modal-card-title is-size-6">Title</p>
						<div class="modal-card-close">
							<i class="fa fa-times fa-lg"></i>
						</div>
					</div>
					<form>
						<section class="modal-card-body">
							<div class="modal-card-content"></div>
						</section>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ItineraryAccordion from "../../vue-components/itinerary/itinerary-accordion.vue";
import MapModal from "../../vue-components/map/map-modal.vue";
import MapModalButton from "../../vue-components/map/map-button.vue";
export default {
	name: "itinerarySection",
	props: ["itinerarymodel", "raqmodel", "dictionarymodel"],
	data() {
		return {
			reverseAccordionActive: false,
		};
	},
	components: {
		"itinerary-accordion": ItineraryAccordion,
		"map-modal": MapModal,
		"map-modal-button": MapModalButton,
	},
};
</script>
