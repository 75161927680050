<template>
	<div
		class="tourUpdate-wrap"
		:class="updateMessage ? 'tourChanged' : ''">
		<div class="tourUpdate-icon">
			<i class="fas fa-exclamation-circle"></i>
		</div>
		<div class="tourUpdate-message">
			<h4 class="tourUpdate-message__title">
				{{ heromodel.tourItineraryTitleUpdatedMessage }}
			</h4>
			<p class="tourUpdate-message__text">
				{{ heromodel.tourItineraryTextUpdatedMessage }}
			</p>
		</div>
		<button
			class="close-btn"
			@click="closeItineraryMessage">
			X
		</button>
	</div>
</template>
<script>
export default {
	props: ["heromodel", "updateMessage"],
	data() {
		return {
			closeMessage: "",
		};
	},
	methods: {
		closeItineraryMessage() {
			this.closeMessage = false;
			this.$emit("close-message", this.closeMessage);
		},
	},
};
</script>
