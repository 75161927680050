function bookingWidget() {
    const pageHtml = document.querySelector('.page-tourDetail');
    const widgetContainer = document.querySelector('.widget-container');
    const widgetContainerBox = document.querySelector('.widget-container__box');
    const widgetBackground = document.querySelector('.widget-background');
    const notificationBarHeight = document.querySelector('.notificationbar').clientHeight;
    const navbarHeight = document.querySelector('.navbar').clientHeight;
    const breadcrumbHeight = document.querySelector('.breadcrumb').clientHeight;
    let totalHeaderHeight = notificationBarHeight + navbarHeight + breadcrumbHeight;
    const footerBar = document.querySelector('.footerbar');
    const footer = document.querySelector('.footer');
    const windowHeight = window.innerHeight;
    const subnav = document.querySelector('.subnav');
    const subnavHeight = subnav.clientHeight;
    widgetContainerBox.style.height = (windowHeight - totalHeaderHeight) + 'px';
    let scrolltop;
    let subnavIsFixed;
    const highestFooterElem = footerBar ? footerBar : footer;
    let highestFooterElemOffsetTop = highestFooterElem.offsetTop;
    let windowWidth;
    function setSizes() {
        windowWidth = window.innerWidth;
        if (windowWidth > 1024) {
            scrolltop = window.scrollY;
            subnavIsFixed = subnav.classList.contains('is-fixed');
            if (scrolltop <= totalHeaderHeight) {
                widgetContainer.style.height = (windowHeight - totalHeaderHeight) + 'px';
                widgetContainerBox.style.height = (windowHeight - totalHeaderHeight) + 'px';
                widgetContainer.style.top = 0;
            } else if (subnavIsFixed) {
                widgetContainer.style.height = (windowHeight - subnavHeight) + 'px';
                widgetContainerBox.style.height = (windowHeight - subnavHeight) + 'px';
                widgetContainer.style.top = ((scrolltop - totalHeaderHeight) + subnavHeight) + 'px';
            } else {
                widgetContainer.style.height = windowHeight + 'px';
                widgetContainerBox.style.height = windowHeight + 'px';
                widgetContainer.style.top = (scrolltop - totalHeaderHeight) + 'px';
            }
            let footerScrollAmount = windowHeight + scrolltop;
            if (footerScrollAmount > (highestFooterElemOffsetTop - totalHeaderHeight)) {
                widgetContainer.style.bottom = 0;
                widgetContainer.style.top = 'auto';
            }
        } else {
            widgetContainer.style.height = '100%';
            widgetContainerBox.style.height = '515px';
        }
        
    }
    setSizes();

    window.addEventListener('scroll', () => {
        setSizes();
    });
    window.addEventListener('resize', () => {
        setSizes();
    });

    const showWidgetButtonHero = document.getElementById('show-ttc-widget-button-hero');
    const showWidgetButtonSubnav = document.getElementById('show-ttc-widget-button-subnav');
    const closeWidgetButton = document.getElementById('close-ttc-widget-button');
    function toggleWidgetClass(e) {
        e.preventDefault();
        widgetContainer.classList.toggle('widget-active');
    }
    if(showWidgetButtonHero){
        showWidgetButtonHero.addEventListener('click', (e) => {
            pageHtml.classList.add('is-clipped');
            toggleWidgetClass(e);
        });
    }
    
    showWidgetButtonSubnav.addEventListener('click', (e) => {
        pageHtml.classList.add('is-clipped');
        toggleWidgetClass(e);
    });
    closeWidgetButton.addEventListener('click', (e) => {
        pageHtml.classList.remove('is-clipped');
        toggleWidgetClass(e);
    });
    widgetBackground.addEventListener('click', (e) => {
        pageHtml.classList.remove('is-clipped');
        toggleWidgetClass(e);
    });

}
module.exports = bookingWidget;