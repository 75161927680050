import Vue from 'vue';
import ShipsView from '../vue-components/ships/ships.vue';

function shipsView() {
    new Vue({
        el: '#ships-section',
        data () {
            return {
                shipsModel: window.Cms.shipsModel,
            }
        },
        components: {
            'ships-view': ShipsView
        }
    });
};
module.exports = shipsView;