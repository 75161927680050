import Vue from 'vue';
import contentModulesCard from '../vue-components/content-modules/content-modules-card.vue';

function contentModulesInfoCard() {
    new Vue({
        el: '#content-modules-card',
        data () {
            return {
                modulescard: window.Cms.modulescard
            };
        },
        components: {
            'content-modules-card': contentModulesCard
        }
    });
};
module.exports = contentModulesInfoCard;