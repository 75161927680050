require("@babel/polyfill");
import "./scss/brands/expediacruises/expediacruises-index.scss";
import "materialize-css";
import Vue from 'vue';
import VueMq from 'vue-mq';
    Vue.use(VueMq, {
    breakpoints: {
        mobile: 450,
        tablet: 769,
        laptop: 1025,
        desktop: 1281,
    }
});


const mobileMenu = require("./assets/js/navigation/mobile-menu");
const mainNavHelper = require("./assets/js/navigation/main-nav-helper");
const autoCompleteSearch = require("./assets/js/search/search-auto-complete");
const stickyNav = require("./assets/js/navigation/sticky-nav");
const stickyMap = require("./assets/js/map/sticky-map");
const backToTop = require("./assets/js/ui-functions/back-to-top");
const scrollTo = require("./assets/js/ui-functions/scroll-to");
const fixIEImages = require("./assets/js/ie-fixes/fix-ie-images");
const featureExpand = require("./assets/js/feature/feature-expand");
const brandPods = require("./assets/js/feature/brand-pods");
const materializeFunctions = require("./assets/js/ui-functions/materializeFunctions");
const smoothScroll = require("./assets/js/ui-functions/smoothScroll");
const featureSection = require("./assets/js/feature/feature-section");
const accordion = require("./assets/js/ui-functions/accordion");
 
let currentUrl = window.location.hostname;
if (currentUrl.includes("leisure") /* || currentUrl.includes('localhost')*/) {
  const validateModal = require("./assets/js/validation/validateMembership");
  validateModal();
}

let searchEl = document.querySelector("#app-search");
if (searchEl && window.Cms.searchPage) {
  const search = require("./assets/js/vue-apps/app-search");
  search();
}

let dropdownEl = document.querySelector(".dropdown-trigger");
if (dropdownEl) {
  const dropdownClickFunction = require("./assets/js/common/dropdown");
  dropdownClickFunction();
}

if (searchEl) {
  const searchFilterToggle = require("./assets/js/search/search-filter-toggle");
  searchFilterToggle();
}

let subSearchEl = document.querySelector("#website-sub-search");
if (subSearchEl) {
  const subSearch = require("./assets/js/search/sub-search");
  subSearch();
}

let ftcModalEl = document.querySelector("#app-ftcModals");
if (ftcModalEl) {
  const websiteFtcModals = require("./assets/js/vue-apps/app-ftc-modals");
  websiteFtcModals();
}

let dealsEl = document.querySelector("#app-deals");
if (dealsEl) {
  const websiteDeals = require("./assets/js/vue-apps/app-deals");
  websiteDeals();
}

let ongoingDealsEl = document.querySelector("#app-ongoing-deals");
if (ongoingDealsEl) {
  const websiteOngoingDeals = require("./assets/js/vue-apps/app-ongoing-deals");
  websiteOngoingDeals();
}

let expandMapEl = document.querySelector("#app-mapModal");
if (expandMapEl) {
  const websiteMapModal = require("./assets/js/vue-apps/app-map-modal");
  websiteMapModal();
}


let embeddedSearchEl = document.querySelector("#app-embeddedSearch");
if (embeddedSearchEl) {
  const embeddedSearch = require("./assets/js/vue-apps/app-embedded-search");
  embeddedSearch();
}

let siteModalEl = document.querySelector("#app-site-modal");
if (siteModalEl) {
  const siteModal = require("./assets/js/vue-apps/app-site-modal");
  siteModal();
}

let heroEl = document.querySelector("#top-section-tour");
if (heroEl) {
  const heroView = require("./assets/js/vue-apps/app-hero");
  heroView();
}

let tourMenuEl = document.querySelector("#top-section-tour");
if (tourMenuEl) {
  const tourMenuView = require("./assets/js/vue-apps/app-tour-menu");
  tourMenuView();
}
let overviewEl = document.querySelector("#overview-section");
if (overviewEl) {
  const overviewView = require("./assets/js/vue-apps/app-overview");
  overviewView();
}

let availabilityEl = document.querySelector("#app-availability");
if (availabilityEl) {
  const availabilityList = require("./assets/js/vue-apps/app-availability");
  availabilityList();
}

let itineraryEl = document.querySelector("#itinerary-section-view");
if (itineraryEl) {
  const itineraryView = require("./assets/js/vue-apps/app-itinerary");
  itineraryView();
}

const shipsEl = document.querySelector("#ships-section");
if (shipsEl) {
  const shipsView = require("./assets/js/vue-apps/app-ships");
  shipsView();
}

const includedEl = document.querySelector("#included-section");
if (includedEl) {
  const includedView = require("./assets/js/vue-apps/app-included");
  includedView();
}

const itineraryModalEl = document.querySelectorAll(".itinerary-modal");
if (itineraryModalEl) {
  const itineraryModal = require("./assets/js/modals/itineraryModal");
  itineraryModal();
}

const tabsEl = document.querySelectorAll(".tabs");
if (tabsEl) {
  materializeFunctions();
}

const secundaryMenuEl = document.querySelector("#secundary-menu");
if (secundaryMenuEl) {
  const secundaryMenu = require("./assets/js/vue-apps/app-secundary-menu");
  secundaryMenu();
}


const contentModulesEl = document.querySelector("#content-modules");
if (contentModulesEl) {
  const contentModules = require("./assets/js/vue-apps/app-content-modules");
  contentModules();
}

const contentModulesCardEl = document.querySelector("#content-modules-card");
if (contentModulesCardEl) {
  const contentModulesInfoCard = require("./assets/js/vue-apps/app-content-modules-card");
  contentModulesInfoCard();
}

const brandLogoImageEl = document.querySelector(".brand-logo-image");
if (brandLogoImageEl) {
  const brandLogoImage = require("./assets/js//logos/logo-size");
  brandLogoImage();
}
// HEROS
let HeroGenericViewEl = document.querySelector("#hero-generic-section");
if (HeroGenericViewEl) {
  const heroGenericView = require("./assets/js/vue-apps/heros/app-hero-generic");
  heroGenericView();
}
let HeroBrandViewEl = document.querySelector("#hero-brand-section");
if (HeroBrandViewEl) {
  const heroBrandView = require("./assets/js/vue-apps/heros/app-hero-brand");
  heroBrandView();
}
let BannerViewEl = document.querySelector("#generic-banner");
if (BannerViewEl) {
  const BannerView = require("./assets/js/vue-apps/app-generic-banner");
  BannerView();
}
let HeroCarouselViewEl = document.querySelector("#hero-carousel-section");
if (HeroCarouselViewEl) {
  const heroCarouselView = require("./assets/js/vue-apps/heros/app-hero-carousel");
  heroCarouselView();
}
let HeroEmbeddedSearchViewEl = document.querySelector("#hero-embeddedSearch");
if (HeroEmbeddedSearchViewEl) {
  const heroEmbeddedSearchView = require("./assets/js/vue-apps/heros/app-hero-embeddedSearch");
  heroEmbeddedSearchView();}

let FeaturedDestinationsViewEl = document.querySelector("#featured-destinations");
if (FeaturedDestinationsViewEl) {
  const featuredDestinationsView = require("./assets/js/vue-apps/app-featured-destinations");
  featuredDestinationsView();
}

let FeaturedParentSectionViewEl = document.querySelector("#featured-parent-section");
if (FeaturedParentSectionViewEl) {
  const featuredParentSectionView = require("./assets/js/vue-apps/app-featured-parent-section");
  featuredParentSectionView();
}

let FeaturedIntroSectionViewEl = document.querySelector("#featured-intro-section");
if (FeaturedIntroSectionViewEl) {
  const featuredIntroSectionView = require("./assets/js/vue-apps/app-featured-intro-section");
  featuredIntroSectionView();
}

// HOMEPAGE
let FeaturedBrandsViewEl = document.querySelector("#featured-brands");
if (FeaturedBrandsViewEl) {
  const featuredBrandsView = require("./assets/js/vue-apps/app-featured-brands");
  featuredBrandsView();
}

const tourPage = document.querySelector("#tourPage");
if (tourPage) {
  const tourPageData = require("./assets/js/vue-apps/app-tour");
  tourPageData();
}
let subNavEl = document.querySelector(".subnav-control");
if (subNavEl) {
  const subnavControl = require("./assets/js/navigation/subnav-control");
  $(document).ready(function () {
    subnavControl();
  });
}

document.addEventListener("DOMContentLoaded", function() {
  mainNavHelper();
  mobileMenu();
  autoCompleteSearch();
  stickyNav();
  stickyMap();
  backToTop();
  scrollTo();
  fixIEImages();
  featureExpand(); 
  brandPods();
  smoothScroll();
  featureSection();
  accordion();
  
  
  const bookingWidgetBoxEl = document.querySelector(".widget-container__box");
  if (bookingWidgetBoxEl) {
    const bookingWidget = require("./assets/js/booking-widget/bookingWidget");
    bookingWidget();
  }
});
