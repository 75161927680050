var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modulesmodel.hasModules
    ? _c("section", { staticClass: "brands-detail subsection section" }, [
        _c("div", { staticClass: "site-section-header" }, [
          _vm.modulesmodel.title && _vm.modulesmodel.modules?.length
            ? _c("h2", {
                staticClass: "site-section-title",
                domProps: { textContent: _vm._s(_vm.modulesmodel.title) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.modulesmodel.subtitle
            ? _c("p", {
                staticClass: "site-section-subtitle",
                domProps: { textContent: _vm._s(_vm.modulesmodel.subtitle) },
              })
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "brands__content" },
          _vm._l(_vm.modulesmodel.modules, function (pod, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "pod-card",
                attrs: { "data-type": "product", "data-name": pod.linkUrl },
              },
              [
                _c("div", { staticClass: "pod-card-content" }, [
                  _c("div", { staticClass: "pod-card-header" }, [
                    _c(
                      "a",
                      {
                        staticClass: "pod-card-content__link",
                        style: {
                          backgroundImage: "url('" + pod.imageUrl + "')",
                          backgroundPosition:
                            "" +
                            pod.leftFocalPoint +
                            " " +
                            pod.topFocalPoint +
                            "",
                        },
                        attrs: {
                          href: pod.linkUrl,
                          alt: pod.Title,
                          "aria-label": pod.Title,
                          title: pod.Title,
                        },
                      },
                      [
                        _c("span", { staticClass: "hidden" }, [
                          _vm._v("Content module"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    pod.brandName
                      ? _c("div", { staticClass: "pod-card-content__logo" }, [
                          _c("img", {
                            attrs: {
                              src: pod.brandImageUrl,
                              alt: pod.brandName,
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pod-card-content-body" }, [
                    _c(
                      "div",
                      { staticClass: "pod-card-content-body__header" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "card-content-header-link",
                            attrs: { href: pod.linkUrl },
                          },
                          [
                            _c("h3", {
                              staticClass: "pod-card-content-body-title",
                              domProps: { textContent: _vm._s(pod.title) },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        pod.subTitle
                          ? _c("h4", {
                              staticClass: "pod-card-content-body-subtitle",
                              domProps: { textContent: _vm._s(pod.subTitle) },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        pod.showLoginCallToAction && !_vm.loginmodel.isLoggedIn
                          ? _c(
                              "p",
                              { staticClass: "login-for-price-call-to-action" },
                              [
                                _c("img", {
                                  staticClass: "logo-image",
                                  attrs: {
                                    src:
                                      _vm.constants.env === "local"
                                        ? _vm.constants.LOCATION_STRING +
                                          _vm.loginmodel.logoUrl
                                        : _vm.loginmodel.logoUrl,
                                    alt: _vm.loginmodel.logoAltText,
                                  },
                                }),
                                _vm._v(" "),
                                _c("a", {
                                  staticClass: "login-link",
                                  attrs: { href: _vm.loginmodel.loginUrl },
                                  domProps: {
                                    textContent: _vm._s(_vm.loginmodel.text),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dataLayerLoginForPriceClicked()
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "pod-card-content-body-text",
                      domProps: { innerHTML: _vm._s(pod.text) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pod-card-footer" }, [
                    _c("div", { staticClass: "pod-card-footer-inner" }, [
                      pod.linkName != ""
                        ? _c("a", {
                            staticClass: "pod-card-footer__link",
                            attrs: {
                              href: pod.linkUrl,
                              target: pod.target,
                              "aria-label": pod.Title,
                            },
                            domProps: { textContent: _vm._s(pod.linkName) },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }