<template>
    <portal name="quote-ftc-footer-button" target-el="#footerbar_cta" slim>
        <a @click.prevent="toggleModal()" v-show="labels.button" data-type="booking interest" data-name="free-consultation" class="button is-secondary modal-button" v-text="labels.button" href="#"></a>
    </portal>
</template>
<script>
  import EventBus from '../../vue-functions/event-bus';
  export default {
        props: {
            labels: Object,
        },
        methods: {
            toggleModal() {
                EventBus.$emit('TOGGLE_FTC_MODAL');
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ event: "FTC Footer Button Clicked" });
            }
        }
    }
</script>