<template>
	<div>
		<div
			v-if="raqmodel.tour.isUniworldTour"
			class="container">
			<h3 class="section-header">{{ dictionarymodel.whatsIncluded }}</h3>
			<div class="highlights is-relative">
				<div>
					<div class="included-column">
						<ul
							v-for="(
								{ type, highlitghts }, index
							) in includedmodel.whatsIncludedCol1"
							:key="index">
							<li
								class="included-image-container"
								v-if="highlitghts[0].imageUrl">
								<p class="imageurl included-image">
									<img
										:src="highlitghts[0].imageUrl"
										alt="What's Included image" />
								</p>
							</li>
							<li v-if="type">
								<p class="subtitle">
									<b>{{ type }}</b>
								</p>
							</li>
							<li
								class="included-list-item"
								v-html="text"
								v-for="({ title, text }, ind) in highlitghts"
								:key="ind"></li>
						</ul>
						<ul
							v-for="(
								{ type, highlitghts }, index
							) in includedmodel.whatsIncludedCol2"
							:key="index">
							<li
								class="included-image-container"
								v-if="highlitghts[0].imageUrl">
								<p class="imageurl included-image">
									<img
										:src="highlitghts[0].imageUrl"
										alt="What's Included image" />
								</p>
							</li>
							<li v-if="type">
								<p class="subtitle">
									<b>{{ type }}</b>
								</p>
							</li>
							<li
								class="included-list-item"
								v-html="text"
								v-for="({ title, text }, ind) in highlitghts"
								:key="ind"></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!raqmodel.tour.isUniworldTour">
			<div class="included__wrapper">
				<h3 class="included__wrapper-title">
					{{ dictionarymodel.whatsIncluded }}
				</h3>
				<div
					class="included__box"
					:class="{
						columnOne:
							includedmodel.whatsIncludedCol1.length === 0 ||
							includedmodel.whatsIncludedCol2.length == 0,
					}">
					<div
						class="included__box-column"
						v-if="includedmodel.whatsIncludedCol1.length > 0">
						<div
							v-for="(
								{ highlitghts }, index
							) in includedmodel.whatsIncludedCol1">
							<ul>
								<li
									v-for="(
										{ title, text }, index
									) in highlitghts">
									<i
										v-if="text"
										class="fas fa-check"
										aria-hidden="true"></i>
									<h4
										v-if="title"
										class="subtitle">
										{{ title }}
									</h4>
									<div v-html="text"></div>
								</li>
							</ul>
						</div>
					</div>
					<div
						class="included__box-column"
						v-if="includedmodel.whatsIncludedCol2.length > 0">
						<div
							v-for="(
								{ highlitghts }, index
							) in includedmodel.whatsIncludedCol2">
							<ul>
								<li
									v-for="(
										{ title, text }, index
									) in highlitghts">
									<i
										v-if="text"
										class="fas fa-check"
										aria-hidden="true"></i>
									<h4
										v-if="title"
										class="subtitle">
										{{ title }}
									</h4>
									<div v-html="text"></div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {};
	},
	props: ["includedmodel", "dictionarymodel", "raqmodel"],
	methods: {},
};
</script>
