var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.settings.filtersLocked
    ? _c(
        "button",
        {
          staticClass: "searchbar-reset-action",
          attrs: { "aria-label": "MainSearch" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.resetFiltersClicked()
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.settings.filterTripsContent.clearAllText) +
              "\n"
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }