var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "section hero-container" }, [
    _c(
      "div",
      {
        staticClass: "hero-brand",
        class: !_vm.herobranddata.heroWidth ? "hero-full" : "container",
      },
      [
        _c("div", {
          staticClass: "hero-overlay",
          style: { opacity: _vm.opacityValue + "%" },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "hero-brand-wrap",
            class: !_vm.herobranddata.heroWidth ? "container" : "",
          },
          [
            _vm.herobranddata?.onlyBrandValue &&
            !_vm.herobranddata.hideBrandLogo
              ? _c("div", { staticClass: "hero-brand-logo" }, [
                  _c("img", {
                    staticClass: "logo-image",
                    attrs: {
                      src: _vm.herobranddata?.brandLogoUrl,
                      alt: _vm.herobranddata?.brandLogoAltText,
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.herobranddata?.onlyBrandValue
              ? _c("div", { staticClass: "hero-brand-data" }, [
                  _vm.herobranddata?.heroTitle
                    ? _c("h2", { staticClass: "hero-brand-title" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.herobranddata?.heroTitle) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.herobranddata?.subtitle
                    ? _c("p", { staticClass: "hero-brand-subtitle" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.herobranddata?.subtitle) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.herobranddata?.ctaButton
                    ? _c(
                        "a",
                        {
                          staticClass: "button cta ctaButton",
                          attrs: { href: "#searchbar" },
                        },
                        [_vm._v(_vm._s(_vm.herobranddata?.ctaButton))]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.herobranddata?.marketingBackgroundImageUrl
          ? _c("img", {
              staticClass: "is-background",
              attrs: {
                src: _vm.herobranddata?.marketingBackgroundImageUrl,
                alt: _vm.herobranddata?.marketingBackgroundImageAltText,
              },
            })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }