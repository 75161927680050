// function for click dropdowns to be shared with common class names
function dropdownFunction () {
    const dropdownTrigger = document.querySelector('.dropdown-trigger');
    const dropdownContainer = document.querySelector('.dropdown-container');
    const dropdownActiveClass = 'dropdown-active';
    const langListContainer = document.querySelector('.lang-list-container');
    let dropdownLangs;
    if (dropdownContainer) {
        dropdownLangs = langListContainer.children.length;
    }

    if (dropdownLangs) {
        window.addEventListener('click', function() {
            dropdownContainer.classList.remove(dropdownActiveClass);
        });
    
        dropdownTrigger.addEventListener('click', function(e) {
            e.stopPropagation();
            dropdownContainer.classList.toggle(dropdownActiveClass);
        });
    }    
}
module.exports = dropdownFunction;