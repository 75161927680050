var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.raqmodel.tour.useWidget
    ? _c("section", { staticClass: "datesprices section" }, [
        _c("div", { staticClass: "container is-fullwidth" }, [
          _c("div", { staticClass: "level dates-prices-header" }, [
            _c(
              "div",
              {
                staticClass: "column dates-prices-header__info is-paddingless",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "columns is-marginless dates-prices-header__items is-full",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "column dates-prices-header-left is-full-mobile is-4-tablet px-0",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "dates-prices-header__title is-3-tablet",
                          },
                          [
                            _c("h2", { staticClass: "header" }, [
                              _vm._v("Dates & Prices"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "dates-prices-results-count",
                                class: {
                                  disabled: _vm.localappdata.apiError,
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\tResults:\n\t\t\t\t\t\t\t\t"
                                ),
                                !_vm.localappdata.dataAllTotal &&
                                !_vm.localappdata.apiError
                                  ? _c("span", [_vm._v("loading...")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.localappdata.dataAllTotal
                                  ? _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.model.labels.toggleDefinite &&
                                            !_vm.filtersClicked
                                            ? _vm.localappdata
                                                .definiteDepartureCount
                                            : _vm.localappdata.visibleList
                                                .length
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.localappdata.dataAllTotal
                                  ? _c("span", [
                                      _vm._v(
                                        "/ " +
                                          _vm._s(_vm.localappdata.dataAllTotal)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.localappdata.apiError
                                  ? _c("span", [_vm._v("0")])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "dates-prices-filters column is-8-tablet is-paddingless",
                      },
                      [
                        !_vm.model.labels.hideDepartureDatesSwitch
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "field dates-prices-toggle is-paddingless is-full-mobile is-flex",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "switch inline-center is-relative",
                                    attrs: {
                                      for: "defineDepartures",
                                      "aria-labelledby": "define Departures ",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.model.labels.toggleDefinite,
                                          expression:
                                            "model.labels.toggleDefinite",
                                        },
                                      ],
                                      attrs: {
                                        tabindex: "0",
                                        type: "checkbox",
                                        id: "defineDepartures",
                                        "aria-label":
                                          "Definite Departures Only",
                                        name: "defineDepartures",
                                        disabled:
                                          _vm.model.labels
                                            .hideDepartureDatesSwitch,
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.model.labels.toggleDefinite
                                        )
                                          ? _vm._i(
                                              _vm.model.labels.toggleDefinite,
                                              null
                                            ) > -1
                                          : _vm.model.labels.toggleDefinite,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.populateVisibleList()
                                        },
                                        change: function ($event) {
                                          var $$a =
                                              _vm.model.labels.toggleDefinite,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.model.labels,
                                                  "toggleDefinite",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.model.labels,
                                                  "toggleDefinite",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.model.labels,
                                              "toggleDefinite",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "slider round" }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "filterLabel",
                                    class: {
                                      filterChecked: _vm.filterCheckbox,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "definiteDeparture" },
                                      [_vm._v("Definite Departures Only")]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(0),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.model.labels.hideDepartureDatesSwitch
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "special-offers-filter field dates-prices-toggle is-paddingless is-full-mobile is-flex",
                                attrs: { "aria-labelledby": "special offers" },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "switch inline-center is-relative",
                                    attrs: {
                                      for: "specialOffers",
                                      "aria-labelledby": "special offers",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.model.labels
                                              .toggleSpecialOffers,
                                          expression:
                                            "\n\t\t\t\t\t\t\t\t\t\tmodel.labels.toggleSpecialOffers\n\t\t\t\t\t\t\t\t\t",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "specialOffers",
                                        "aria-label": "Special Offers",
                                        name: "specialOffers",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.model.labels.toggleSpecialOffers
                                        )
                                          ? _vm._i(
                                              _vm.model.labels
                                                .toggleSpecialOffers,
                                              null
                                            ) > -1
                                          : _vm.model.labels
                                              .toggleSpecialOffers,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.populateVisibleList()
                                        },
                                        change: function ($event) {
                                          var $$a =
                                              _vm.model.labels
                                                .toggleSpecialOffers,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.model.labels,
                                                  "toggleSpecialOffers",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.model.labels,
                                                  "toggleSpecialOffers",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.model.labels,
                                              "toggleSpecialOffers",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "slider round" }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "filterLabel",
                                    class: {
                                      filterChecked: _vm.filterCheckbox,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "specialOffers" },
                                      [_vm._v("Special Offers")]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "is-paddingless is-full-mobile filter",
                          },
                          [
                            _c("dates-prices-filter-months", {
                              attrs: {
                                model: _vm.model,
                                months: _vm.localappdata.availableMonths,
                                selectedmonths:
                                  _vm.localappdata.userActions.selectedMonths,
                              },
                              on: {
                                "update-selected-months-array": function (
                                  $event
                                ) {
                                  return _vm.updateSelectedMonths($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "prices-table dates-prices-item-container",
            attrs: { id: "pricesTable" },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.localappdata.visibleList.length,
                    expression: "localappdata.visibleList.length",
                  },
                ],
                staticClass: "dates-prices-table is-fullwidth",
              },
              [
                _c("ul", { staticClass: "dates-prices-table-header" }, [
                  _c("li", { staticClass: "dates-prices__startDate" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.model.labels.startDateText
                            ? _vm.model.labels.startDateText
                            : "Start date"
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.model.labels.startDateToolTip
                      ? _c(
                          "span",
                          {
                            staticClass: "icon tooltip",
                            attrs: {
                              "data-tooltip-text":
                                _vm.model.labels.startDateToolTip,
                              "data-tooltip-position": "right",
                            },
                          },
                          [_c("i", { staticClass: "fas fa-info-circle" })]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  !_vm.model.labels.hideEndDateHeading
                    ? _c("li", { staticClass: "dates-prices__endDate" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.model.labels.endDateText
                                ? _vm.model.labels.endDateText
                                : "End date"
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.model.labels.endDateToolTip
                          ? _c(
                              "span",
                              {
                                staticClass: "icon tooltip",
                                attrs: {
                                  "data-tooltip-text":
                                    _vm.model.labels.endDateToolTip,
                                  "data-tooltip-position": "top",
                                },
                              },
                              [_c("i", { staticClass: "fas fa-info-circle" })]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.model.labels.hideDealsColumn
                    ? _c("li", { staticClass: "dates-prices__deals" }, [
                        _c("span", [_vm._v("Deals")]),
                        _vm._v(" "),
                        _vm.model.labels.tasCurrentDealsTooltip
                          ? _c(
                              "span",
                              {
                                staticClass: "icon tooltip",
                                attrs: {
                                  "data-tooltip-text":
                                    _vm.model.labels.tasCurrentDealsTooltip,
                                  "data-tooltip-position": "top",
                                },
                              },
                              [_c("i", { staticClass: "fas fa-info-circle" })]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.model.labels.hideDepartureStatusColumn
                    ? _c("li", { staticClass: "dates-prices__departure" }, [
                        _vm._m(1),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.localappdata.siteDiscount
                    ? _c(
                        "li",
                        { staticClass: "dates-prices__exclusivePrice" },
                        [
                          _c("span", {
                            staticClass: "dates-prices-exclusive-header",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.model.labels.exclusivePriceLabel
                              ),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.model.labels.hideCurrentDealPriceColumn
                    ? _c(
                        "li",
                        {
                          staticClass:
                            "currentDealsPrice dates-prices__currentPrice",
                        },
                        [_vm._m(2)]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _vm.model.labels.canBook &&
                  _vm.model.labels.isCostco &&
                  _vm.model.labels.isLoggedIn &&
                  !_vm.model.labels.isGB &&
                  _vm.model.labels.membershipDetails &&
                  !_vm.raqmodel.tour.isCmsTour
                    ? _c(
                        "li",
                        { staticClass: "book-now dates-prices_bookNow" },
                        [_c("span", [_vm._v("Book now")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.labels.canBook &&
                  !_vm.model.labels.isCostco &&
                  !_vm.raqmodel.tour.isCmsTour
                    ? _c(
                        "li",
                        { staticClass: "book-now dates-prices_bookNow" },
                        [_c("span", [_vm._v("Book now")])]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dates-prices-table-body",
                    class: { bookNow: _vm.model.labels.canBook },
                  },
                  _vm._l(
                    _vm.localappdata.visibleList
                      .slice(0, _vm.localappdata.maxShownItems)
                      ?.sort(
                        (a, b) =>
                          new Date(a.startDate).getTime() -
                          new Date(b.startDate).getTime()
                      ),
                    function (item) {
                      return _c("vue-dates-price-row", {
                        key: item.index,
                        attrs: {
                          model: _vm.model,
                          row: item,
                          raqmodel: _vm.raqmodel,
                          localappdata: _vm.localappdata,
                        },
                      })
                    }
                  ),
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.localappdata.isLoadingFresh
              ? _c("div", { staticClass: "dates-prices-item-loader" }, [
                  !_vm.localappdata.apiError
                    ? _c("div", { staticClass: "loader-bar inverse" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.localappdata.apiError
                    ? _c(
                        "h3",
                        { staticClass: "has-text-centered has-text-white" },
                        [
                          _c("br"),
                          _vm._v(
                            "Trips are unable to load at the moment, please refresh\n\t\t\t\tthe page or try again later.\n\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.localappdata.maxShownItems < _vm.localappdata.visibleList.length
              ? _c(
                  "div",
                  {
                    staticClass: "has-text-centered datesprices-button-holder",
                  },
                  [
                    _c("button", {
                      staticClass: "cta",
                      class: { "is-loading": _vm.localappdata.isLoadingMore },
                      domProps: {
                        textContent: _vm._s(
                          !_vm.localappdata.isLoadingMore ? "Load more..." : ""
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showmoreclicked()
                        },
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        !_vm.localappdata.isLoadingFresh && !_vm.localappdata.visibleList.length
          ? _c(
              "div",
              { staticClass: "has-text-centered datesprices-noresults-holder" },
              [
                _c("h3", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.model.labels.noDefiniteDeparturesText
                        ? _vm.model.labels.noDefiniteDeparturesText
                        : "No results. Try switching the Definite Departures Only"
                    ),
                  },
                }),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "icon tooltip",
        attrs: {
          "data-tooltip-text":
            'We guarantee our trips are "Definite" and will depart (weather and other conditions dependent, of course) as soon as enough guests are booked.',
          "data-tooltip-position": "top",
        },
      },
      [_c("i", { staticClass: "fas fa-info-circle" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("Departure "),
      _c("br"),
      _vm._v("\n\t\t\t\t\t\tStatus"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _vm._v("Price with "),
      _c("br"),
      _vm._v("\n\t\t\t\t\t\tcurrent deals"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "dates-prices__easyQuote" }, [
      _c("span", [
        _vm._v("Get "),
        _c("br"),
        _vm._v("\n\t\t\t\t\t\tEasy Quote"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }