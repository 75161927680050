var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.citiesCount > 0
    ? _c("div", { staticClass: "searchlisting-tour-data-item" }, [
        _c("i", { staticClass: "fas fa-location-arrow" }),
        _vm._v(" "),
        _c("span", { staticClass: "searchlisting__item" }, [
          _vm._v(
            _vm._s(
              _vm.result.locationsVisited.split(",").slice(0, 2).join(",")
            ) + " "
          ),
          _vm.citiesCount > 2
            ? _c(
                "a",
                {
                  attrs: {
                    href: `${_vm.result.url}/#overview-section`,
                    target: "_blank",
                  },
                },
                [_vm._v("... +" + _vm._s(_vm.citiesCount - 2) + " more")]
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }