<template>
	<div
		class="modal modal-geq"
		id="geqmodalraq"
		:class="{ 'is-active': active }"
		@keydown.esc.prevent="closeForm()">
		<div
			class="modal-background"
			@click.prevent="closeForm()"></div>
		<div class="modal-card">
			<div
				class="modal-form"
				v-show="!submitted">
				<div class="modal-card-head">
					<p
						class="modal-card-title"
						v-text="labels.modalTitle"></p>
					<a
						class="modal-card-close bg-transparent"
						href="#"
						@click.prevent="closeForm()"
						aria-label="close">
						<i class="fa fa-times fa-lg"></i>
					</a>
				</div>
				<form @submit.prevent="validateBeforeSubmit">
					<section class="modal-card-body">
						<p
							class="label"
							v-text="modal.brandName"></p>
						<h2
							v-if="modal.tourName"
							v-text="modal.tourName"></h2>

						<div class="columns">
							<div
								class="column"
								v-if="modal.brandName == 'Contiki'">
								<div class="field">
									<input
										id="ageConsent"
										name="ageConsent"
										:data-vv-as="labels.ageConsent"
										v-validate="'required'"
										class="is-checkradio"
										type="checkbox"
										v-model="quoteRequest.ageConsent" />
									<label
										for="ageConsent"
										class="label"
										v-html="labels.ageConsent"></label>
									<span
										class="field-validation-error"
										v-if="errors.has('ageConsent')"
										>{{ errors.first("ageConsent") }}</span
									>
								</div>
							</div>
						</div>
						<div
							v-if="
								quoteRequest.iscmstour ||
								quoteRequest.isuniworldtour ||
								quoteRequest.isubytour
							">
							<div
								v-if="modal.departureDates"
								class="columns">
								<div class="column">
									<div class="field">
										<label class="label">{{
											labels.departureDate
										}}</label>
										<div class="control">
											<select
												class="input input-subtle"
												v-model="
													quoteRequest.departureDate
												"
												id="departureDate"
												aria-label="labeldepartureDate">
												<option value="">
													{{ labels.notSure }}
												</option>
												<option
													v-for="option in modal.departureDates"
													v-bind:key="option.value"
													v-bind:value="option.value">
													{{ option.text }}
												</option>
											</select>
											<span class="select-dropdown-icon">
												<i
													aria-hidden="true"
													class="fas fa-chevron-down"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else>
							<div
								v-if="modal.departureDates"
								class="columns">
								<div class="column">
									<div class="field">
										<label class="label">{{
											labels.departureDate
										}}</label>
										<div class="control">
											<select
												class="input input-subtle"
												v-model="
													quoteRequest.departureId
												"
												id="departureId"
												aria-label="labeldepartureDate"
												@change="departureDateChange()">
												<option value="">
													{{ labels.notSure }}
												</option>
												<option
													v-for="option in modal.departureDates"
													v-bind:key="
														option.departureId
													"
													v-bind:value="
														option.departureId
													">
													{{ option.text }}
												</option>
											</select>
											<span class="select-dropdown-icon">
												<i
													aria-hidden="true"
													class="fas fa-chevron-down"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
							<input
								v-text="quoteRequest.departureDate"
								id="departureDate"
								name="departureDate"
								v-model="quoteRequest.departureDate"
								type="hidden" />
						</div>
						<div
							class="columns"
							v-if="labels.showMemberField">
							<div class="column">
								<div class="field">
									<label
										class="label"
										:class="{
											'required-label':
												labels.memberFieldMandatory,
										}"
										>{{ labels.labelMemberNo }}</label
									>
									<input
										class="input input-subtle"
										:type="
											labels.alphanumericCriteria ||
											memberNoValidator
												? 'text'
												: 'number'
										"
										:class="{
											disabled:
												isLoggedIn || memberNoValidator,
										}"
										:disabled="
											isLoggedIn || memberNoValidator
										"
										name="membershipno"
										v-model="quoteRequest.membershipNo"
										v-validate="{
											required:
												labels.memberFieldMandatory,
											numeric:
												!labels.alphanumericCriteria,
											min: labels.memberIdCriteria
												? labels.memberIdCriteria
												: '1',
											max: labels.memberIdCriteria
												? labels.memberIdCriteria
												: '16',
											regex: labels.alphanumericCriteria
												? /^(?=.*\d)(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/
												: /^([0-9]+)$/,
										}"
										aria-label="labelmembershipno"
										id="membershipno"
										:data-vv-as="labels.labelMemberNo"
										v-bind:placeholder="
											labels.memberFieldWaterMark
										" />
									<span
										class="field-validation-error"
										v-if="errors.has('membershipno')"
										>{{
											errors.first("membershipno")
										}}</span
									>
								</div>
							</div>
						</div>
						<div class="columns">
							<div class="column">
								<div class="field">
									<label class="label required-label">{{
										labels.labelFirstname
									}}</label>
									<div class="control">
										<input
											v-model="quoteRequest.firstName"
											:data-vv-as="labels.labelFirstname"
											id="firstname"
											aria-label="labelfirstname"
											name="firstname"
											v-validate="'required|max:40'"
											class="input input-subtle"
											type="text" />
										<span
											class="field-validation-error"
											v-if="errors.has('firstname')"
											>{{
												errors.first("firstname")
											}}</span
										>
									</div>
								</div>
							</div>
							<div class="column">
								<div class="field">
									<label class="label required-label">{{
										labels.labelLastname
									}}</label>
									<div class="control">
										<input
											v-model="quoteRequest.lastName"
											:data-vv-as="labels.labelLastname"
											id="lastname"
											aria-label="labellastname"
											name="lastname"
											v-validate="'required|max:40'"
											class="input input-subtle"
											type="text" />
										<span
											class="field-validation-error"
											v-if="errors.has('lastname')"
											>{{
												errors.first("lastname")
											}}</span
										>
									</div>
								</div>
							</div>
							<input
								v-text="quoteRequest.toemailaddress"
								id="toemailaddress"
								name="toemailaddress"
								type="hidden" />
							<input
								v-text="quoteRequest.ccemailaddress"
								id="ccemailaddress"
								name="ccemailaddress"
								type="hidden" />
							<input
								v-text="quoteRequest.aaecategory"
								id="aaecategory"
								name="aaecategory"
								type="hidden" />
							<input
								v-text="quoteRequest.iscmstour"
								id="iscmstour"
								name="iscmstour"
								type="hidden" />
							<input
								v-text="quoteRequest.isuniworldtour"
								id="isuniworldtour"
								name="isuniworldtour"
								type="hidden" />
							<input
								v-text="quoteRequest.isubytour"
								id="isubytour"
								name="isubytour"
								type="hidden" />
							<input
								v-text="quoteRequest.urlquoterequest"
								id="urlquoterequest"
								name="urlquoterequest"
								type="hidden" />
							<input
								v-text="quoteRequest.tourid"
								id="tourid"
								name="tourid"
								type="hidden" />
							<input
								v-text="quoteRequest.touroptionid"
								id="touroptionid"
								name="touroptionid"
								type="hidden" />
						</div>
						<div class="columns">
							<div class="column">
								<div class="field">
									<label class="label required-label">{{
										labels.labelEmail
									}}</label>
									<div class="control">
										<input
											v-model="quoteRequest.email"
											:data-vv-as="labels.labelEmail"
											id="Email"
											aria-label="labelEmail"
											name="Email"
											v-validate="'required|email'"
											class="input input-subtle"
											type="email" />
										<span
											class="field-validation-error"
											v-if="errors.has('Email')"
											>{{ errors.first("Email") }}</span
										>
									</div>
								</div>
							</div>
							<div class="column">
								<div class="field">
									<label
										class="label"
										v-text="labels.labelPhone"></label>
									<div class="control">
										<input
											v-model="quoteRequest.phone"
											id="phone"
											name="phone"
											aria-label="labelphone"
											v-validate="'numeric|max: 22'"
											v-bind:placeholder="
												labels.placeholderOptional
											"
											class="input input-subtle" />
										<span
											class="field-validation-error"
											v-if="errors.has('phone')"
											>{{ errors.first("phone") }}</span
										>
									</div>
								</div>
							</div>
						</div>
						<div class="columns" v-show="labels.showZipCode">
							<div class="column">
								<div class="field">
									<label
										class="label required-label">{{labels.labelZipOrPostalCode}}</label>
									<div class="control">
										<input
											v-model="quoteRequest.zipCode"
											id="zipCode"
											name="zipCode"
											aria-label="labelZipCode"
											v-validate="zipCodeValidationRules"
											class="input input-subtle" />
											<input
												v-text="labels.showZipCode"
												id="showZipCode"
												name="showZipCode"
												type="hidden"
											/>
										<span
											class="field-validation-error"
											v-if="errors.has('zipCode')"
											>{{ errors.first("zipCode") }}</span
										>
									</div>
								</div>
							</div>
							<div class="column">
							</div>
						</div>
						<div class="columns margin-bottom">
							<div class="column">
								<div class="field">
									<label
										class="label"
										v-text="labels.labelComments"></label>
									<div class="control">
										<textarea
											v-model="quoteRequest.comments"
											type="text"
											aria-label="labels.placeholderComments"
											v-bind:placeholder="
												labels.placeholderComments
											"
											class="input input-subtle">
										</textarea>
									</div>
								</div>
							</div>
						</div>
						<div
							v-if="showNewsLetterCheckbox"
							class="columns">
							<div class="column">
								<div class="field">
									<input
										id="marketing"
										name="marketing"
										class="is-checkradio"
										type="checkbox"
										v-model="
											quoteRequest.requestNewsLetter
										" />
									<label
										for="marketing"
										class="label marketing"
										v-html="
											labels.newsletterMessage
										"></label>
								</div>
							</div>
						</div>
						<div class="columns">
							<div class="column">
								<div class="field">
									<input
										id="agreeTerms"
										name="agreeTerms"
										:data-vv-as="labels.agreeTerms"
										v-validate="'required'"
										class="is-checkradio"
										type="checkbox"
										v-model="quoteRequest.agreeTerms" />
									<label
										for="agreeTerms"
										class="label"
										v-html="labels.labelAgreeTerms"></label>
									<span
										class="field-validation-error"
										v-if="errors.has('agreeTerms')"
										>{{ errors.first("agreeTerms") }}</span
									>
								</div>
							</div>
						</div>
					</section>
					<div class="modal-card-foot">
						<button
							type="submit"
							:disabled="!quoteRequest.agreeTerms"
							class="button is-secondary show-confirm cta"
							:class="{ 'is-loading': isLoading }"
							v-text="
								!isLoading ? labels.submitButton : ''
							"></button>
					</div>
				</form>
			</div>
			<div
				class="modal-confirm"
				id="model-confirm-raq"
				v-show="submitted">
				<div class="modal-card-head">
					<p
						class="modal-card-title"
						v-text="labels.modalTitle"></p>
					<a
						class="modal-card-close bg-transparent"
						href="#"
						@click.prevent="closeForm()"
						aria-label="close">
						<i class="fa fa-times fa-lg"></i>
					</a>
				</div>
				<div class="modal-card-image">
					<img
						:src="labels.submittedBackgroundImage"
						:alt="
							labels.submittedBackgroundImageAltText
								? labels.submittedBackgroundImageAltText
								: 'modal-background-image'
						"
						class="is-background" />
					<div class="card-content">
						<h2
							class="modal-confirm__success-header"
							v-show="!submittedError"
							v-text="labels.submittedTitle"></h2>
						<h2
							v-show="submittedError"
							v-text="labels.submittedErrorTitle"></h2>
					</div>
				</div>
				<div class="modal-card-body">
					<p
						v-show="!submittedError"
						v-text="labels.submittedText"></p>
					<p
						v-show="submittedError"
						v-text="labels.submittedErrorText"></p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import EventBus from "../../vue-functions/event-bus";
import axios from "axios";
import { closeModal } from "../../../js/modals/closeModal";
const dict = {
	custom: {
		agreeTerms: {
			required: "",
		},
		ageConsent: {
			required: "",
		},
	},
};
export default {
	data() {
		return {
			isLoading: false,
			active: false,
			modal: false,
			submitted: false,
			submittedError: false,
			quoteRequest: {
				departureDate: "",
				membershipNo: "",
				firstName: "",
				lastName: "",
				email: "",
				phone: "",
				zipCode: "",
				showZipCode: false,
				comments: "",
				tourName: "",
				brandValue: "",
				agreeTerms: false,
				requestNewsLetter: false,
				toemailaddress: "",
				ccemailaddress: "",
				aaecategory: "",
				iscmstour: false,
				urlquoterequest: "",
				tourid: "",
				touroptionid: "",
				startdate: "",
				departureId: "",
				isuniworldtour: false,
				isubytour: false,
			},
			memberNoValidator: false,
			targetButton: "",
			selectedButton: "",
			isEmailLead: false,
			membershipNumberandLastNameInvalid: false,
		};
	},
	props: {
		labels: Object,
		api: String,
		toemailaddress: String,
		ccemailaddress: String,
		aaecategory: String,
		membershipno: String,
		isLoggedIn: { type: Boolean, default: false },
		emaillead: Boolean,
		islistingpage: Boolean,
		iscmstour: Boolean,
		urlquoterequest: String,
		tourid: String,
		touroptionid: String,
		isuniworldtour: Boolean,
		isubytour: Boolean,
	},
	computed: {
		zipCodeValidationRules() {
			return this.labels.showZipCode ? 'required|alpha_dash|max: 9' : 'alpha_dash|max: 9';
		},
		showNewsLetterCheckbox() {
			if (this.islistingpage && this.emaillead) {
				return false;
			} else if (!this.isEmailLead && this.labels.newsletterMessage) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		validateBeforeSubmit() {
			this.$validator.validate().then((result) => {
			console.log("result", result)
				if (result) {
					this.handleSubmit();
				}
			});
		},
		show() {
			this.active = true;

			let memberNumberValid = sessionStorage.getItem("memberValid");
			if (memberNumberValid) {
				this.memberNoValidator = true;
				let memberIdParam = sessionStorage.getItem("memberId");
				this.quoteRequest.membershipNo = memberIdParam;
			} else if (this.quoteRequest.membershipNo == "") {
				this.quoteRequest.membershipNo = this.membershipno;
			}

			this.toggleBodyClass();
		},
		toggleBodyClass() {
			let bodyEl = document.querySelector("body");
			bodyEl.classList.toggle("is-modal-active");
		},
		submittedForm() {
			this.submitted = true;
		},
		closeForm() {
			this.submitted = false;
			this.active = false;
			document.documentElement.classList.remove("is-clipped");
			this.toggleBodyClass();
			document
				.querySelector(".modal-card-body")
				.parentElement.classList.remove("scroll-more");
		},
		handleSubmit() {
			if (!this.quoteRequest.agreeTerms) {
				return;
			}
			var self = this;
			self.isLoading = true;
			axios
				.post(this.api, this.quoteRequest, {
					headers: { "Content-Type": "application/json" },
				})
				.then(function (response) {
					self.isLoading = false;
					if (response.data.Status === 0) {
						self.submittedError = false;
						self.submittedForm();
					} else {
						switch (response.data.Status) {
							case 2:
								switch (response.data.ParameterName) {
									case "MembershipNo":
										self.$validator.errors.add({
											field: "membershipno",
											msg: response.data.Message,
										});
										self.membershipNumberandLastNameInvalid = true;
										break;
									case "LastName":
										self.$validator.errors.add({
											field: "lastname",
											msg: response.data.Message,
										});
										break;
								}
								break;
							case 1:
								self.membershipNumberandLastNameInvalid = true;
							case 3: //Expired.
								self.$validator.errors.add({
									field: "membershipno",
									msg: response.data.Message,
								});
								self.membershipNumberandLastNameInvalid = true;
								break;
						}
					}
				})
				.catch(function (error) {
					self.isLoading = false;
					self.submittedError = true;
					self.submittedForm();
				});
		},
		closeModal,
		dataLayerError() {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "RAQ Modal Membership Number / Last Name Error",
			});
		},
		dataLayerSubmit() {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: "RAQ Modal Submitted" });
		},
		departureDateChange() {
			var departureDateHiddenField =
				document.getElementById("departureDate");
			var departureDateDropdown = document.getElementById("departureId");
			var departureDateSelected =
				departureDateDropdown.options[
					departureDateDropdown.selectedIndex
				].text;
			departureDateHiddenField.value = departureDateSelected;
			this.quoteRequest.departureDate = departureDateSelected;
		},
	},
	created: function () {
		dict.custom.agreeTerms.required = this.labels["termsRequiredMessage"];
		dict.custom.ageConsent.required = this.labels["ageConsentTermsMessage"];

		this.quoteRequest.toemailaddress = this.toemailaddress;
		this.quoteRequest.ccemailaddress = this.ccemailaddress;
		this.quoteRequest.iscmstour = this.iscmstour;
		this.quoteRequest.aaecategory = this.aaecategory;
		this.quoteRequest.urlquoterequest = this.urlquoterequest;
		this.quoteRequest.tourid = this.tourid;
		this.quoteRequest.touroptionid = this.touroptionid;
		this.quoteRequest.isuniworldtour = this.isuniworldtour;
		this.quoteRequest.isubytour = this.isubytour;
		this.quoteRequest.showZipCode = this.labels.showZipCode;
		console.log("show zip code ", this.quoteRequest.showZipCode)
		this.modal = { brand: { name: "" } };
		this.$validator.localize("en", dict);
		EventBus.$on(
			"TOGGLE_RAQ_MODAL",
			(row, tourSelectedDate, tourDepartureId) => {
				this.modal = row;
				if (typeof row.brand.brandValue !== "undefined") {
					this.modal.brandName = row.brand.name;
					this.quoteRequest.brandValue = row.brand.brandValue;
				} else {
					this.quoteRequest.brandValue = row.brand;
				}
				this.quoteRequest.departureDate = tourSelectedDate;
				this.quoteRequest.departureId = tourDepartureId;
				this.quoteRequest.tourName = row.tourName;
				this.quoteRequest.aaecategory = row.aaeCategory;
				this.quoteRequest.iscmstour = row.isCmsTour;
				this.quoteRequest.isuniworldtour = row.isUniworldTour;
				this.quoteRequest.isubytour = row.isUbyTour;
				this.quoteRequest.urlquoterequest = row.url;
				this.quoteRequest.tourid = row.tourId;
				this.quoteRequest.touroptionid = row.tourOptionId;
				document.documentElement.classList.add("is-clipped");
				this.show();
			},
		);
		if (!this.active) {
			EventBus.$on("update-raq-target", (target) => {
				this.targetButton = target;
				this.selectedButton = target;
			});
			EventBus.$on("update-is-email-lead", (emailLead) => {
				this.isEmailLead = emailLead;
			});
		}
	},
	mounted() {
		this.closeModal(this.closeForm);
		this.quoteRequest.requestNewsLetter =
			this.labels.newsletterDefaultOptIn;
	},
	watch: {
		active() {
			if (this.active) {
				setTimeout(() => {
					const modal = document.querySelector("#geqmodalraq .input");
					modal.focus();
				}, 200);
			} else {
				this.selectedButton.focus();
			}
		},
		submitted() {
			if (this.submitted) {
				this.dataLayerSubmit();
			}
		},
		membershipNumberandLastNameInvalid() {
			if (this.labels.showMemberField) {
				if (this.membershipNumberandLastNameInvalid) {
					this.dataLayerError();
				}
			}
		},
	},
};
</script>
