var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.raqmodel.tour.isC1Tour || _vm.raqmodel.tour.isV4Tour
      ? _c("div", { staticClass: "highlitghts" }, [
          _vm.highlitghts.length > 0
            ? _c(
                "div",
                {
                  staticClass: "highlitghts__wrapper",
                  attrs: { id: "highlights-section" },
                },
                [
                  _c("h3", { staticClass: "highlitghts__wrapper-title" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel.highlights) +
                        "\n\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "highlitghts__box" },
                    _vm._l(
                      _vm.highlitghts,
                      function ({ type, highlitghts }, index) {
                        return _c(
                          "div",
                          {
                            staticClass: "highlitghts__box-column",
                            class: { columnOne: highlitghts.length === 1 },
                          },
                          [
                            !(
                              highlitghts[0].text == "" &&
                              highlitghts.length == 1
                            ) && type
                              ? _c("h4", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(type) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                highlitghts,
                                function ({ title, text }, index) {
                                  return _c("li", [
                                    text
                                      ? _c("i", {
                                          staticClass: "fas fa-check",
                                          class: [
                                            type
                                              .replace(" ", "")
                                              .toLowerCase() ===
                                            "iconicexperience"
                                              ? "fa-map-marker"
                                              : "fa-check",
                                          ],
                                          attrs: { "aria-hidden": "true" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      title &&
                                      type.replace(" ", "").toLowerCase() ===
                                        "iconicexperience"
                                        ? _c(
                                            "p",
                                            { staticClass: "strong-text" },
                                            [
                                              _c("strong", [
                                                _vm._v(_vm._s(title) + " "),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: { innerHTML: _vm._s(text) },
                                      }),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.raqmodel.tour.isCmsTour
      ? _c("div", { staticClass: "highlitghts" }, [
          _vm.highlitghts.length > 0
            ? _c(
                "div",
                {
                  staticClass: "highlitghts__wrapper",
                  attrs: { id: "highlights-section" },
                },
                [
                  _c("h3", { staticClass: "highlitghts__wrapper-title" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel.highlights) +
                        "\n\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "highlitghts__box" },
                    _vm._l(
                      _vm.highlitghts,
                      function ({ type, highlitghts }, index) {
                        return _c(
                          "div",
                          {
                            staticClass: "highlitghts__box-column",
                            class: { columnOne: highlitghts.length === 1 },
                          },
                          [
                            !(
                              highlitghts[0].text == "" &&
                              highlitghts.length == 1
                            ) && type
                              ? _c("h4", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(type) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                highlitghts,
                                function ({ title, text }, index) {
                                  return _c("li", [
                                    text
                                      ? _c("i", {
                                          staticClass: "fas fa-check",
                                          attrs: { "aria-hidden": "true" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", [
                                      title
                                        ? _c("p", { staticClass: "subtitle" }, [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(title) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !title
                                        ? _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(text),
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }