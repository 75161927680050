var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "accordion" }, [
    _c(
      "header",
      {
        staticClass: "accordion-header",
        attrs: {
          role: "button",
          tabindex: "0",
          "aria-expanded": _vm.index === 0 ? "true" : "false",
          "aria-controls": `accordian-content-${_vm.index}`,
          id: `accordian-${_vm.index}`,
        },
      },
      [
        _c("div", [
          _c("p", { staticClass: "accordion-label" }, [
            _vm._v("Day " + _vm._s(_vm.day.startDay)),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "accordion-title" }, [
            _vm._v(_vm._s(_vm.day?.title)),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "accordion-body",
        attrs: {
          "aria-labelledby": `accordian-${_vm.index}`,
          id: `accordian-content-${_vm.index}`,
        },
      },
      [
        _c("div", { staticClass: "accordion-content" }, [
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-7-desktop" },
              [
                _vm.hasDayText
                  ? _c(
                      "div",
                      _vm._l(_vm.day.text, function (text) {
                        return _c(
                          "div",
                          { key: text, staticClass: "accordion-text" },
                          [
                            text
                              ? _c("p", {
                                  domProps: { innerHTML: _vm._s(text) },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isUBy && !_vm.hasDayText
                  ? _c("uby-excursions", {
                      attrs: {
                        ubyincluded: _vm.uByIncluded,
                        ubyplus: _vm.uByUPlus,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "column is-5-desktop details" }, [
              _c("ul", [
                _vm.day.accommodation ||
                (_vm.day.accommodations && _vm.day.accommodations[0]?.title)
                  ? _c("li", [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("p", { staticClass: "label" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.dictionarymodel.accommodation) +
                            "\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "detail" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.tourapi.isC1Tour
                                ? _vm.day.accommodations[0]?.title
                                : _vm.day.accommodation
                            ) +
                            "\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.day.meals && _vm.day.meals.length > 0
                  ? _c(
                      "li",
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("p", { staticClass: "label" }, [_vm._v("Meals")]),
                        _vm._v(" "),
                        _vm._l(_vm.day.meals, function (meal) {
                          return _c(
                            "p",
                            { key: meal.index, staticClass: "detail" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(meal) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.day.dayFootnote
            ? _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column is-7-desktop" }, [
                  _c("span", { staticClass: "is-italic" }, [
                    _vm._v(_vm._s(_vm.day.dayFootnote)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isUniworld
            ? _c("div", [
                _vm.dayThemesArray.toString().includes("Included - Featured")
                  ? _c(
                      "div",
                      { staticClass: "column details" },
                      [
                        _c(
                          "h4",
                          { staticClass: "subtitle is-size-6 is-marginless" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\tFeatured Excursions:\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.day?.excursions, function (excursion) {
                          return _c("div", { key: excursion.index }, [
                            excursion.type === "Included - Featured"
                              ? _c("div", [
                                  excursion.details
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "itinerary-modal-trigger",
                                          attrs: {
                                            href: "#",
                                            "data-excursion-title":
                                              excursion?.title,
                                            "data-excursion-details":
                                              excursion.details,
                                          },
                                        },
                                        [_vm._v(_vm._s(excursion?.title))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !excursion.details
                                    ? _c("span", [
                                        _vm._v(_vm._s(excursion?.title)),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.uniworldDayChoices.length && _vm.uniworld
                  ? _c(
                      "div",
                      { staticClass: "column details" },
                      [
                        _c(
                          "h4",
                          { staticClass: "subtitle is-size-6 is-marginless" },
                          [_vm._v("\n\t\t\t\t\t\tChoice of:\n\t\t\t\t\t")]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.uniworldDayChoices,
                          function (excursion, index) {
                            return _c("div", { key: index }, [
                              _c("p", [
                                index == _vm.uniworldDayChoices.length - 1
                                  ? _c("span", { staticClass: "subtitle" }, [
                                      _vm._v("or"),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              excursion.details
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "itinerary-modal-trigger",
                                      attrs: {
                                        href: "#",
                                        "data-excursion-title":
                                          excursion?.title,
                                        "data-excursion-details":
                                          excursion.details,
                                      },
                                    },
                                    [_vm._v(_vm._s(excursion?.title))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !excursion.details
                                ? _c("span", [_vm._v(_vm._s(excursion?.title))])
                                : _vm._e(),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dayThemesArray.toString().includes("Theme")
                  ? _c(
                      "div",
                      { staticClass: "column details" },
                      [
                        _c(
                          "h4",
                          { staticClass: "subtitle is-size-6 is-marginless" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.dayThemeName) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.day?.excursions, function (excursion) {
                          return _c("div", { key: excursion.index }, [
                            excursion.type.includes("Theme")
                              ? _c("div", [
                                  excursion.details
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "itinerary-modal-trigger",
                                          attrs: {
                                            href: "#",
                                            "data-excursion-title":
                                              excursion?.title,
                                            "data-excursion-details":
                                              excursion.details,
                                          },
                                        },
                                        [_vm._v(_vm._s(excursion?.title))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !excursion.details
                                    ? _c("span", [
                                        _vm._v(_vm._s(excursion?.title)),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isUBy && _vm.hasDayText
            ? _c(
                "div",
                { staticClass: "column is-7-desktop" },
                [
                  _c("uby-excursions", {
                    attrs: {
                      ubyincluded: _vm.uByIncluded,
                      ubyplus: _vm.uByUPlus,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        (_vm.tourapi?.isC1Tour || _vm.tourapi?.isCmsTour) &&
        _vm.day.dayHighlights?.length > 0
          ? _c("itinerary-dayHighlights", {
              attrs: { dayHighlights: _vm.day.dayHighlights },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-chevron-up" }),
      _vm._v(" "),
      _c("i", { staticClass: "fas fa-chevron-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-bed" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fas fa-utensils" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }