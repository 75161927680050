<template>
	<div class="multi-month-picker">
		<div class="level-left">
			<h2 class="dropdown-subheading">Departure Date</h2>
		</div>
		<div class="calendar-component-wrapper">
			<div
				class="calendar-component-inner"
				:style="computeContainerStyle">
				<div
					class="calendar-component calendar-component-year"
					:style="styleWidth"
					:key="year"
					v-for="(year, yearIndex) in yearsToRender">
					<div class="calendar-header">
						<button
							class="prev-year nav-year"
							@click="slidePrevYear"
							aria-label="prev-year"
							:class="{ disabled: slidePrevDisabled }">
							<span class="icon is-small">
								<i
									class="fas fa-angle-left"
									aria-hidden="true"></i>
							</span>
						</button>
						<span class="current-year">{{ years[yearIndex] }}</span>
						<button
							class="next-year nav-year"
							@click="slideNextYear"
							aria-label="next-year"
							:class="{ disabled: slideNextDisabled }">
							<span class="icon is-small">
								<i
									class="fas fa-angle-right"
									aria-hidden="true"></i>
							</span>
						</button>
					</div>
					<div class="calendar-months">
						<button
							class="calendar-month-btn"
							:class="{
								'is-active': isActive(month + years[yearIndex]),
							}"
							:disabled="
								yearIndex === 0 &&
								monthIndex < minMonthCurrentYear
							"
							:value="month + years[yearIndex]"
							:key="month + monthIndex"
							aria-label="calendar-months"
							@click="selectedMonths"
							v-for="(month, monthIndex) in months">
							{{ month }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import eventBus from "../../vue-functions/event-bus.js";
export default {
	props: ["urlmonths"],
	data: function () {
		return {
			yearsToRender: 3,
			years: [],
			months: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
			datesSelected: [],
			visibleYear: 1,
			slidePosition: 0,
			slideNextDisabled: false,
			slidePrevDisabled: true,
			minMonthCurrentYear: 0,
			buttonActiveClass: "is-active",
		};
	},
	watch: {
		urlmonths(val) {
			this.datesSelected = val;
		},
	},
	beforeMount() {
		for (let i = 0; i < this.yearsToRender; i++) {
			let now = new Date().getFullYear();
			let year = now + i;
			this.years.push(year);
		}
		// fetch the month  pass month number to data
		function getDate() {
			let today = new Date();
			let month = today.getMonth();
			return month;
		}
		this.minMonthCurrentYear = getDate();
	},
	mounted() {
		// this function exists to set the filter states if months are passed to the component via props
		if (this.urlmonths.length > 0) {
			for (let i = 0; i < this.urlmonths.length; i++) {
				this.datesSelected.push(this.urlmonths[i]);
			}
		}
		this.setButtonActiveStates();
	},
	created() {
		eventBus.$on("duration-calendar-reset", () => {
			this.clearValues();
		});
	},
	methods: {
		isActive(date) {
			let result = this.datesSelected.includes(date);
			return result;
		},
		slideNextYear() {
			if (this.slideNextDisabled) {
				return;
			}
			let slideValue = 100;
			this.visibleYear++;
			this.slidePosition += slideValue;
			this.slidePrevDisabled = false;
			if (this.visibleYear === this.yearsToRender) {
				this.slideNextDisabled = true;
			}
		},
		slidePrevYear() {
			if (this.slidePrevDisabled) {
				return;
			}
			let slideValue = 100;
			this.visibleYear--;
			this.slidePosition -= slideValue;
			this.slideNextDisabled = false;
			if (this.visibleYear === 1) {
				this.slidePrevDisabled = true;
			}
		},
		selectedMonths(event) {
			let buttonValue = event.target.value;
			let datesArray = this.datesSelected;
			// remove from datesArray if item already exists
			if (datesArray.includes(buttonValue)) {
				let spliceKey = datesArray.indexOf(buttonValue);
				datesArray.splice(spliceKey, 1);
				event.target.classList.remove(this.buttonActiveClass);
				// or add if value is not already in the array
			} else {
				datesArray.push(buttonValue);
				event.target.classList.add(this.buttonActiveClass);
			}
			// emit this back to the parent so that the months values are always up to date prior to any click of apply
			this.$parent.$emit("months", datesArray);
		},
		clearValues() {
			// empty the array
			this.datesSelected.length = 0;
			let buttons = this.getButtonDomRefs();
			// loop through buttons and remove the active class
			for (let i = 0; i < buttons.length; i++) {
				buttons[i].classList.remove(this.buttonActiveClass);
			}
		},
		getButtonDomRefs: function () {
			let calMonths = document.querySelector(
				".calendar-component-wrapper",
			);
			let monthButtons = calMonths.querySelectorAll(
				".calendar-month-btn",
			);
			return monthButtons;
		},
		setButtonActiveStates() {
			let buttons = this.getButtonDomRefs();
			for (let i = 0; i < buttons.length; i++) {
				let currentBtn = buttons[i];
				let btnValue = currentBtn.value;
				if (this.datesSelected.includes(btnValue)) {
					currentBtn.classList.add(this.buttonActiveClass);
				}
			}
		},
	},
	computed: {
		styleWidth() {
			return "width:" + 100 / this.yearsToRender + "%";
		},
		computeContainerStyle: function () {
			let style =
				"width:" +
				this.yearsToRender * 100 +
				"%" +
				";" +
				"margin-left:" +
				"-" +
				this.slidePosition +
				"%" +
				";";
			return style;
		},
	},
};
</script>
