import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate,{
    events: 'change'
});

import SiteModal from '../vue-components/modals/site-modal.vue';

function siteModal() {
    new Vue({
        el: '#app-site-modal',
        data: window.Cms.siteModal,
        components: {
            'site-modal': SiteModal
        },
        beforeCreate: function () {
            if(typeof this.content === 'undefined')
            {
                this.content = {};
            }
        }
    });
};
module.exports = siteModal;