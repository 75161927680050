var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tourUpdate-wrap",
      class: _vm.updateMessage ? "tourChanged" : "",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "tourUpdate-message" }, [
        _c("h4", { staticClass: "tourUpdate-message__title" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.heromodel.tourItineraryTitleUpdatedMessage) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "tourUpdate-message__text" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.heromodel.tourItineraryTextUpdatedMessage) +
              "\n\t\t"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "close-btn", on: { click: _vm.closeItineraryMessage } },
        [_vm._v("\n\t\tX\n\t")]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tourUpdate-icon" }, [
      _c("i", { staticClass: "fas fa-exclamation-circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }