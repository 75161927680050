import Vue from 'vue';
import FeatureBrands from '../vue-components/featured/featured-brands.vue';
function featureBrands() {
    new Vue({
        el: '#featured-brands',
        data() {
            return {
                brandsdata: window.Cms.brandsModel,
            };
        },
        components: {
            'featured-brands': FeatureBrands,
        }
    });
};
module.exports = featureBrands;