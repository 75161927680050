var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.settings.parentSearch
        ? _c("search-field", {
            ref: "searchfield",
            attrs: {
              dest: _vm.settings.currentDestinations,
              searchterm: _vm.settings.currentSearchPrefix,
              searchbuttonlabel:
                _vm.settings.filterTripsContent.searchButtonText,
              searchplaceholder:
                _vm.settings.filterTripsContent.searchPlaceholder,
            },
            on: { searchclicked: _vm.formFieldSearch },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "searchbarholder", attrs: { id: "searchbarholder" } },
        [
          _c(
            "div",
            {
              staticClass: "searchbar form-inverse",
              attrs: { id: "searchbar" },
            },
            [
              _c("div", { staticClass: "searchbar-wrapper" }, [
                _c("div", { staticClass: "searchbar-inner" }, [
                  _c("div", { staticClass: "searchbar-mobile-toggle" }, [
                    _c(
                      "button",
                      { staticClass: "searchbar-mobile-toggle-btn" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.settings.filterTripsContent.mobileHeading
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass:
                            "fas fa-chevron-up mobile-toggle-chevron-icon",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "searchbar-inner-row" }, [
                    _c("div", { staticClass: "searchbar-inner-col" }, [
                      _c(
                        "div",
                        { staticClass: "searchbar__label" },
                        [
                          _c("h4", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.settings.filterTripsContent
                                    .filterTripsText
                                ) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.products,
                            function (product, productIndex) {
                              return _c(
                                "p",
                                {
                                  key: "product" + productIndex,
                                  staticClass: "label",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.settings.filterTripsContent
                                          .numberOfSearchResultText
                                      ) +
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(product.totalHits) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "searchbar-inner-col" },
                      [
                        _c("small-groups-filter", {
                          attrs: { settings: _vm.settings },
                          on: {
                            "set-small-groups-flag": _vm.setSmallGroupsFlag,
                            "filter-search": _vm.filterSearch,
                          },
                        }),
                        _vm._v(" "),
                        _c("special-offers-filter", {
                          attrs: { settings: _vm.settings },
                          on: {
                            "set-special-offers-flag": _vm.setSpecialOffersFlag,
                            "filter-search": _vm.filterSearch,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "searchbar-inner-row" }, [
                    _c(
                      "div",
                      { staticClass: "searchbar-controls-wrap" },
                      [
                        _c(
                          "div",
                          { staticClass: "searchbar-control" },
                          [
                            _c("search-date-duration", {
                              attrs: {
                                settings: _vm.settings,
                                "filter-visibility": _vm.filterVisibility,
                              },
                              on: {
                                "set-filter-visibility":
                                  _vm.setFilterVisibility,
                                "duration-filter-actions":
                                  _vm.durationFilterActions,
                                months: _vm.getSelectedMonths,
                                durationsliderval: _vm.getDurationSliderValues,
                                "reset-duration-filters":
                                  _vm.resetDurationFilters,
                                "filter-search": _vm.filterSearch,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "searchbar-control" },
                          [
                            _c("search-brand-selector", {
                              attrs: {
                                settings: _vm.settings,
                                products: _vm.products,
                                "filter-visibility": _vm.filterVisibility,
                              },
                              on: {
                                "set-filter-visibility":
                                  _vm.setFilterVisibility,
                                "get-selected-brands": _vm.getSelectedBrands,
                                "reset-brand-filters": _vm.resetBrandFilters,
                                "filter-search": _vm.filterSearch,
                                "brand-filter-actions": _vm.brandFilterActions,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "searchbar-control" },
                          [
                            _c("search-destination-selector", {
                              attrs: {
                                settings: _vm.settings,
                                "filter-visibility": _vm.filterVisibility,
                              },
                              on: {
                                "set-filter-visibility":
                                  _vm.setFilterVisibility,
                                "destination-filter-actions":
                                  _vm.destinationFilterActions,
                                "destinations-region-click":
                                  _vm.clearDestinationValues,
                                "destinations-region-search":
                                  _vm.setRegionSearch,
                                "destinations-filter":
                                  _vm.getSelectedDestinations,
                                "reset-destination-filters":
                                  _vm.resetDestinationFilters,
                                "filter-search": _vm.filterSearch,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "searchbar-control" },
                          [
                            _c("search-price-selector", {
                              attrs: {
                                settings: _vm.settings,
                                products: _vm.products,
                                "filter-visibility": _vm.filterVisibility,
                                currency: _vm.currencySymbol,
                              },
                              on: {
                                "set-filter-visibility":
                                  _vm.setFilterVisibility,
                                "price-filter-actions": _vm.priceFilterActions,
                                "reset-price-slider": _vm.resetPriceSlider,
                                "filter-search": _vm.filterSearch,
                                "price-slider-val": _vm.getPriceSliderValues,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("clear-all-button", {
                          attrs: { settings: _vm.settings },
                          on: {
                            "reset-all-filters": function ($event) {
                              return _vm.resetAllFilters(
                                "clearSearchField",
                                "callNewSearch"
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("search-results", {
        attrs: {
          settings: _vm.settings,
          products: _vm.products,
          tourResults: _vm.tourResults,
          locationString: _vm.locationString,
        },
        on: {
          "show-more-results": _vm.showMoreResults,
          "exit-to-main-search": _vm.exitToMainSearch,
          "launch-easy-quote": function ($event) {
            return _vm.launchEasyQuote($event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-easyquote-wrapper" },
        [
          _c("quote-raq-modal", {
            attrs: {
              labels: _vm.launchEasyQuoteData.labels,
              membershipno: _vm.launchEasyQuoteData.membershipNo,
              api: _vm.launchEasyQuoteData.api,
              toemailaddress: _vm.launchEasyQuoteData.toemailaddress,
              ccemailaddress: _vm.launchEasyQuoteData.ccemailaddress,
              "is-logged-in": _vm.launchEasyQuoteData.isLoggedIn,
              urlquoterequest: _vm.launchEasyQuoteData.url,
              tourid: _vm.launchEasyQuoteData.tourId,
              touroptionid: _vm.launchEasyQuoteData.tourOptionId,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("aboutus-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }