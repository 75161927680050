var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "search-brand-container" }, [
    _c(
      "div",
      { staticClass: "check-link-holder search-brand-wrapper" },
      _vm._l(
        _vm.filterTripsContent.vacationBrand.brands,
        function (brand, brandIndex) {
          return _c(
            "a",
            {
              key: "brand" + brandIndex,
              staticClass: "check-link search-brand-item",
              class: { "is-active": _vm.isActive(brand) },
              attrs: {
                "data-brand-name": brand.brandId,
                value: brand.brandName,
                "aria-label": brand.brandName,
                href: "#",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.selectedBrands(brand)
                },
              },
            },
            [
              _c("div", {
                staticClass: "background-image-center contain logo-container",
                class: { "is-square": brand.isSquare },
                style: {
                  backgroundImage:
                    "url('" +
                    _vm.constants.LOCATION_STRING +
                    brand.brandLogoUrl +
                    "')",
                },
              }),
              _vm._v(" "),
              _vm._m(0, true),
            ]
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "icon icon-check is-small" }, [
      _c("i", {
        staticClass: "fas fa-check",
        attrs: { "aria-hidden": "true" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }