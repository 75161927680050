var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "button is-secondary cta",
        attrs: {
          title: !_vm.booknowurl ? "Book Now unavailable" : "",
          disabled: !_vm.booknowurl,
          href: "#",
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.book(_vm.booknowurl)
          },
        },
      },
      [_vm._v("Book Now")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }