var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "overview section overview-full-width",
      attrs: { id: "overview-section" },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "columns" }, [
          _c("div", { staticClass: "column is-9 lead" }, [
            _c("div", { staticClass: "level is-marginless" }, [
              _c("div", { staticClass: "level-left" }, [
                _vm.generatedmodel?.tddOverviewIconUrl != ""
                  ? _c("img", {
                      staticClass: "level-item",
                      attrs: {
                        src: _vm.generatedmodel?.tddOverviewIconUrl,
                        alt: _vm.generatedmodel?.tddOverviewIconAltTag
                          ? _vm.generatedmodel?.tddOverviewIconAltTag
                          : "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("h2", { staticClass: "level-item" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t" +
                      _vm._s(_vm.dictionarymodel?.overview) +
                      "\n\t\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            !_vm.overviewmodel.description !== "" ||
            !_vm.overviewmodel.description.length
              ? _c(
                  "div",
                  [
                    _vm._l(
                      _vm.overviewmodel.description,
                      function (desc, index) {
                        return _vm.raqmodel?.tour.isV4Tour
                          ? _c("div", { key: index }, [
                              _c("p", [_vm._v(_vm._s(desc))]),
                            ])
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.overviewmodel.description,
                      function (desc, index) {
                        return _vm.raqmodel?.tour.isC1Tour
                          ? _c("div", {
                              key: index,
                              domProps: { innerHTML: _vm._s(desc) },
                            })
                          : _vm._e()
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.overviewmodel?.locationsVisited !== ""
              ? _c("div", { staticClass: "details" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel?.locationsVisited) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.overviewmodel?.locationsVisited)),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column is-3 details" }, [
            _vm.overviewmodel?.countriesVisited !== ""
              ? _c("div", [
                  _c("p", { staticClass: "label" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel?.countriesVisited) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.overviewmodel?.countriesVisited)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.overviewmodel?.travelStyle !== ""
              ? _c("div", [
                  _c("p", { staticClass: "label" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel?.travelStyle) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.overviewmodel?.travelStyle))]),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.overviewmodel.travelType === ""
              ? _c("div", [
                  _c("p", { staticClass: "label" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel.travelType) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.overviewmodel.travelType))]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.overviewmodel.nights > 0
              ? _c("div", [
                  _c("p", { staticClass: "label" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel?.accommodations) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.overviewmodel?.nights) +
                        "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel?.nightsIncluded) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.overviewmodel?.ageRestriction !== ""
              ? _c("div", [
                  _c("p", { staticClass: "label" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.dictionarymodel?.ageRequirements) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.overviewmodel?.ageRestriction))]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }