import Vue from 'vue';
import axios from 'axios';
// componemts
import HeroSection from '../vue-components/tour-page/heroSection.vue';
import TourMenu from '../vue-components/tour-page/tourMenu.vue';
import OverviewSection from '../vue-components/tour-page/overviewSection.vue';
import OptionsSection from '../vue-components/tour-page/optionsSection.vue';
import ItinerarySection from '../vue-components/tour-page/itinerarySection.vue';
import IncludedSection from '../vue-components/tour-page/includedSection.vue';
import AvailabilitySection from '../vue-components/tour-page/availabilitySection.vue';
import RefineSearch from "../vue-components/tour-page/refine-search.vue";
import ItineraryUpdateMessage from "../vue-components/itinerary/itinerary-updatedMessage.vue";
import HighlitghtsSection from "../vue-components/tour-page/highlitghtsSection.vue";
import dayHighlightsSlider from '../vue-components/tour-page/dayHighlightsSlider.vue';

function getTourPage() {
    new Vue({
        el: '#tourPage',
        data: {
            windowCms: window.Cms,
            tourLoading:false,
            tourOptionId : window.Cms.raqModal.tour.tourOptionId,
            generatedmodel :{},
            dictionarymodel : window.Cms.dictionaryValuesModel,
            tourIdUpdated: '',         
            selectedOption: window.Cms.raqModal.tour.tourOptionId,
            heromodel: window.Cms.heroModel,
            datamenu: window.Cms.subNavModel,
            overviewmodel: window.Cms.overviewModel,
            raqmodel: window.Cms.raqModal,
            generatedmodel: window.Cms.generatedModelSection,
            heroavailability: window.Cms.availabilitiesModel.labels,
            itinerarymodel: window.Cms.itineraryModel,
            includedmodel: window.Cms.includedModel,
            availabilitiesmodel: window.Cms.availabilitiesModel, 
            tourOptions: window.Cms.TourOptions,
            optionFiltersOnLoad : window.Cms.optionFiltersBlocks,
            tourUpdatedStatus: false,
            highlitghts:window.Cms.itineraryModel.tripHighlights,
            filterConfiguration: window.Cms.raqModal.tour,
            filtervalues: {},
            tripChanged:false
        },
        components: {
            'hero-section': HeroSection,
            'tour-menu': TourMenu,
            'overview-section': OverviewSection,
            'options-section': OptionsSection,
            'itinerary-section': ItinerarySection,
            'included-section': IncludedSection,
            'availability-section': AvailabilitySection,
            'refine-search': RefineSearch,
            'itinerary-updatemessage': ItineraryUpdateMessage,
            'highlitghts-section': HighlitghtsSection,
            "day-highlitght-slider": dayHighlightsSlider
        },
        methods: {
            getBaseTripData() {
                this.loading = true;             
            },
            updateTourData(tourUrl){
              this.tourLoading = true;
              let self = this;
               axios.get(tourUrl)
                    .then(response => {
                        let { availabilitiesSection,generatedModelsSection,itinerarySection,
                            mainSection,raqModal,tourOptions,tourOverviewSection,
                            whatIsIncludedSection } = response.data;
                            this.tripChanged=true;
                        this.tourOptionId = raqModal.tour.tourOptionId,
                        this.availabilitiesmodel = availabilitiesSection;  
                        this.generatedmodel = generatedModelsSection;
                        this.itinerarymodel = itinerarySection;
                        this.heromodel = mainSection;
                        this.raqmodel = raqModal; 
                        this.overviewmodel = tourOverviewSection;
                        this.includedmodel = whatIsIncludedSection;           
                        this.heroavailability = availabilitiesSection.labels;           
                        this.tourUpdatedStatus = true;
                        this.highlitghts = itinerarySection.tripHighlights;
                        this.filterConfiguration = raqModal.tour;
                        this.tourLoading = false;
                        window.scroll({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                        this.filtervalues = this.getOverviewSummary(this.tourOptionId, tourOptions);
                        setTimeout(function() {
                            if(self.tourUpdatedStatus) self.tourUpdatedStatus = false;
                       }, 5000, this);
                    })
                    .catch(response => {
                        return '';
                    })
                    .finally(() => (this.loading = false));

            },
            itineraryUpdatedStatus(tourStatus){                
                this.tourUpdatedStatus = tourStatus; 
            },
            getOverviewSummary(tourId, tourOptionsList) {
                let overviewSummary = tourOptionsList.find(opt => opt.tourOptionId === tourId).tags;
                return overviewSummary;
            }
        },
        mounted() { 
            this.getBaseTripData();
            this.filtervalues = this.getOverviewSummary(this.tourOptionId, this.tourOptions);
        }

    });
};
module.exports = getTourPage;


