import * as searchConstants from '../../search-constants.js';

function getSelectedTypes (selectedTypesArray) {
    this.$data.settings.filtersValues.typesVal = selectedTypesArray;
    this.setTypeFilterText(selectedTypesArray);
    // receives selected brands array from brands filters and converts to string
    let typesString = selectedTypesArray.join();
    // im also removing the space here with the replace method and regexp so that search can understand the terms
    typesString = typesString.replace(/\s/g, "");
    // pass the string to the data for use in search
    this.$data.settings.filters.typesSelected = searchConstants.TYPESSELECTEDPREFIX + typesString;
}
module.exports = getSelectedTypes;