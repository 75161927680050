<template>
	<section
		class="homepage-hero-outer hero"
		:class="!herodata.heroWidth ? 'hero-full' : 'container'">
		<div
			class="hero-overlay"
			:style="{ opacity: opacityValue + '%' }"></div>
		<div class="feature__content homepage-hero-container">
			<div class="homepage-hero searchbarholder">
				<h1 class="homepage-hero_header">
					{{ herodata.searchTitle }}
				</h1>
			</div>
		</div>
		<img
			v-if="herodata.imageUrl"
			:src="herodata.imageUrl"
			class="is-background"
			:alt="herodata.imageAltText" />
		<div class="embeddedSearch-wrap">
			<app-embedded-search
				id="app-embeddedSearch"
				:app-id="appId"></app-embedded-search>
		</div>
	</section>
</template>
<script>
import EmbeddedSearch from "../../vue-components/apps/embedded-search.vue";
export default {
	name: "heroEmbeddedSearch",
	data() {
		return {
			appId: "app-embeddedSearch",
			opacityValue: 50,
		};
	},
	props: ["herodata"],
	components: {
		"app-embedded-search": EmbeddedSearch,
	},
	mounted() {
		this.opacityValue =
			this.herodata?.opacityHeroValue != ""
				? this.herodata?.opacityHeroValue
				: this.opacityValue;
	},
};
</script>
