var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "is-relative" }, [
    _c(
      "div",
      { staticClass: "container" },
      _vm._l(_vm.deals, function (item) {
        return _c("dealItem", {
          key: item.title,
          attrs: { row: item, labels: _vm.labels, ongoing: _vm.ongoing },
        })
      }),
      1
    ),
    _vm._v(" "),
    _vm.showmore
      ? _c(
          "div",
          {
            staticClass:
              "search-results-show-more grey specialslistings__footer has-text-centered",
          },
          [
            _c("div", { staticClass: "inner" }, [
              _c(
                "button",
                {
                  staticClass: "button is-subtle",
                  class: { "is-loading": _vm.isloading },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("load-more-clicked")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.labels.button) + "\n\t\t\t")]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }