var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ttc-specials-items specialslistings__listing",
      attrs: { "data-type": "promotion", "data-name": _vm.row.title },
    },
    [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          {
            staticClass: "column specialslistings__listing__image is-3-tablet",
          },
          [
            _vm.row.imageUrl
              ? _c(
                  "a",
                  {
                    staticClass:
                      "specialslistings__listing__image__link background-image-center",
                    attrs: {
                      href: _vm.row.linkUrl,
                      alt: _vm.row.title,
                      "aria-label": _vm.row.title,
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "is-background",
                      attrs: {
                        src: _vm.row.imageUrl,
                        alt: _vm.row.imageAltText
                          ? _vm.row.imageAltText
                          : "specials-listing-image",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.ongoing && _vm.row.brandLogoUrl
              ? _c("div", { staticClass: "searchlisting-brand-image-ss" }, [
                  _c("img", {
                    staticClass: "image deal-brand-logo-ss",
                    attrs: {
                      src: _vm.row.brandLogoUrl,
                      alt: _vm.row.brandLogoAltText,
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.row.imageUrl,
                    expression: "!row.imageUrl",
                  },
                ],
              },
              [
                _vm.row.imageTitle
                  ? _c("h2", {
                      domProps: { textContent: _vm._s(_vm.row.imageTitle) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.row.imageSubtitle
                  ? _c("p", {
                      domProps: { textContent: _vm._s(_vm.row.imageSubtitle) },
                    })
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "column specialslistings__listing__desc is-6-desktop is-7-widescreen",
          },
          [
            _c("h3", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.row.title,
                  expression: "row.title",
                },
              ],
              domProps: { textContent: _vm._s(_vm.row.title) },
            }),
            _vm._v(" "),
            _vm.row.dealBrands
              ? _c("div", { staticClass: "level" }, [
                  _c("div", { staticClass: "level-left" }, [
                    _c("p", {
                      staticClass: "level-item category",
                      domProps: { textContent: _vm._s(_vm.labels.labelBrands) },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "level-item",
                      domProps: { textContent: _vm._s(_vm.row.dealBrands) },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.row.dealRegion
              ? _c("div", { staticClass: "level deal-region" }, [
                  _c("div", { staticClass: "level-left" }, [
                    _c("p", {
                      staticClass: "level-item category",
                      domProps: { textContent: _vm._s(_vm.labels.labelRegion) },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "level-item",
                      domProps: { textContent: _vm._s(_vm.row.dealRegion) },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.row.bookByDate
              ? _c("div", { staticClass: "level deal-bookBy" }, [
                  _c("div", { staticClass: "level-left" }, [
                    _c("p", {
                      staticClass: "level-item category",
                      domProps: { textContent: _vm._s(_vm.labels.labelBookBy) },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "level-item",
                      domProps: { textContent: _vm._s(_vm.row.bookByDate) },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "level specialslistings__description_text" },
              [
                _c("div", { staticClass: "columns" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "column column-category deal-description-label",
                    },
                    [
                      _c("p", {
                        staticClass: "category",
                        domProps: {
                          textContent: _vm._s(_vm.labels.labelDescription),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "column column-description" }, [
                    _c("div", {
                      staticClass: "deal-description",
                      domProps: { innerHTML: _vm._s(_vm.row.description) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "deal-terms" }, [
                      _c("a", {
                        attrs: {
                          alt: _vm.row.title,
                          "aria-label": _vm.row.title,
                        },
                        domProps: {
                          textContent: _vm._s(_vm.labels.labelTerms),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleModal(_vm.row.terms, _vm.row.title)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "column specialslistings__listing__price column is-3-desktop is-2-widescreen",
          },
          [
            !_vm.ongoing && _vm.row.brandLogoUrl
              ? _c("img", {
                  staticClass: "image deal-brand-logo-ls",
                  attrs: {
                    src: _vm.row.brandLogoUrl,
                    alt: _vm.row.brandLogoAltText,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.row.detailsLinkUrl
              ? _c("a", {
                  staticClass: "button is-subtle",
                  attrs: {
                    href: _vm.row.detailsLinkUrl,
                    target: _vm.row.target,
                    alt: _vm.row.title,
                    "aria-label": _vm.row.title,
                  },
                  domProps: { textContent: _vm._s(_vm.row.detailsLinkName) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.row.linkUrl
              ? _c("a", {
                  staticClass: "button is-primary",
                  class: { "is-single-deal-btn": !_vm.row.detailsLinkUrl },
                  attrs: {
                    alt: _vm.row.title,
                    target: _vm.row.target,
                    "aria-label": _vm.row.title,
                    href: _vm.row.linkUrl,
                  },
                  domProps: { textContent: _vm._s(_vm.row.linkName) },
                })
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }