<template>
  <transition name="fade">
    <div v-if="showRow" class="dates-prices-table-row">
      <div class="dates-prices-table-col deals-prices-start-date item-col">
        <div class="dates-prices-item-wrap">
          <span class="mobile-label">Start Date</span>
          <span class="item-text" v-text="row.startDate"></span>
        </div>
        <p
          class="arrow"
          aria-hidden="true"
          v-if="!model.labels.hideEndDateHeading"
        >
          <span class="icon"><i class="fas fa-arrow-right"></i></span>
        </p>
      </div>
      <div
        class="dates-prices-table-col deals-prices-end-date item-col"
        v-if="!model.labels.hideEndDateHeading"
      >
        <div class="dates-prices-item-wrap">
          <span class="mobile-label">End Date</span>
          <span class="item-text" v-text="row.endDate"></span>
        </div>
      </div>
      <div
        class="dates-prices-table-col dates-prices-current-deals item-col"
        v-if="!model.labels.hideDealsColumn"
      >
        <div class="dates-prices-item-wrap">
          <span class="mobile-label">Deals</span>
          <div class="item-text" v-for="(deal, index) in deals" :key="index">
            {{ deal }}
          </div>
        </div>
      </div>
      <div
        class="dates-prices-table-col dates-prices-departure-deals"
        v-if="!model.labels.hideDepartureStatusColumn"
      >
        <div class="dates-prices-item-wrap">
          <span class="mobile-label">Departure Status</span>
          <div class="departure-icons">
            <span
              v-if="row.definiteDeparture"
              class="fa-stack depart-status tooltip"
              :class="{'no-tooltip': !model.labels.tasDepartureStatusTooltip}"
              :data-tooltip-text="model.labels.tasDepartureStatusTooltip"
              data-tooltip-position="top"
            >
              <i
                class="fa fa-circle fa-stack-1x fa-sm definite-departure-icon"
              ></i>
              <i
                class="fa fa-shield-alt fa-stack-1x fa-lg definite-departure-icon"
              ></i>
              <i
                class="fa fa-shield-alt fa-stack-1x fa-lg fa-flip-horizontal definite-departure-icon"
              ></i>
              <i class="fa fa-check fa-stack-1x fa-xs has-text-white"></i>
            </span>
            <div
              v-if="row.tags && row.tags.includes('Small Group')"
              class="mr-1 tooltip"
              :class="{'no-tooltip': !model.labels.smallGroupsToolTip}"
              :data-tooltip-text="model.labels?.smallGroupsToolTip"
              data-tooltip-position="top"
            >
            <span class="small-groups-icon"></span></div>
          </div>
        </div>
      </div>
      <div
        class="dates-prices-table-col dates-prices-discount-price item-col"
        v-if="localappdata.siteDiscount"
      >
        <div class="dates-prices-item-wrap">
          <p
            class="dates-prices-login-for-price-call-to-action"
            v-if="
              raqmodel.loginForPriceModel.isEnabled &&
              !raqmodel.loginForPriceModel.isLoggedIn
            "
          >
            <span class="mobile-label">Exclusive Costco Member Price</span>
            <img
              :src="raqmodel.loginForPriceModel.logoUrl"
              :alt="raqmodel.loginForPriceModel.logoAltText"
              class="logo-image"
            /><a
              :href="raqmodel.loginForPriceModel.loginUrl"
              class="login-link customer-price-link"
              @click="dataLayerLoginForPriceClicked()"
              >{{ raqmodel.loginForPriceModel.text }}
            </a>
          </p>
          <p
            v-if="
              !raqmodel.loginForPriceModel.isEnabled ||
              (raqmodel.loginForPriceModel.isEnabled &&
                raqmodel.loginForPriceModel.isLoggedIn)
            "
            class="mobile-price-header tablet-visible"
          >
            <small><em v-html="model.labels.exclusivePriceLabel"></em></small>
          </p>
          <strong
            class="exclusive-member-price"
            v-if="
              !raqmodel.loginForPriceModel.isEnabled ||
              (raqmodel.loginForPriceModel.isEnabled &&
                raqmodel.loginForPriceModel.isLoggedIn)
            "
            v-text="discountedPriceFlag"
          >
          </strong>
        </div>
      </div>
      <div
        class="dates-prices-table-col dates-prices-current-deals-price item-col"
        v-if="!model.labels.hideCurrentDealPriceColumn"
      >
        <div class="dates-prices-item-wrap">
          <span class="mobile-label">Price with Current Deals</span>
          <div class="is-inline-block item-text">
            <div
              v-if="row.currentDeals && row.price != ''"
              class="price"
              v-text="currentDealPriceFlag"
            ></div>
            <div
              v-if="
                !row.currentDeals &&
                row.originalPrice != row.price &&
                row.price != ''
              "
              class="price"
              v-text="currentDealPriceFlag"
            ></div>
            <div
              v-if="row.price != '' && row.originalPrice != row.price"
              v-text="row.originalPrice"
              :class="{
                strikethrough: !(
                  row.originalPrice == null || row.originalPrice.trim() === ''
                ),
              }"
            ></div>
            <div
              v-if="
                !row.currentDeals &&
                (row.originalPrice == row.price ||
                  (row.price == '' && row.originalPrice != ''))
              "
              v-text="row.originalPrice"
              class="price"
            ></div>
            <div
              v-if="
                row.currentDeals && row.price == '' && row.originalPrice != ''
              "
              v-text="row.originalPrice"
              class="price"
            ></div>
          </div>
        </div>
      </div>
      <div class="dates-prices-table-col dates-prices-get-quote">
        <quote-raq-button
          :tour="raqmodel.tour"
          :labels="raqmodel.labels"
          :tourselecteddate="row.startDate"
          :tourdepartureid="row.departureId"
        ></quote-raq-button>
      </div>
      <div
        class="dates-prices-table-col dates-prices-get-quote item-col book-now"
        v-if="model.labels.canBook && !model.labels.isCostco && !raqmodel.tour.isCmsTour"
      >
        <book-button
          :booknowurl="row.bookNowUrl"
          :labels="model.labels"
        ></book-button>
      </div>
      <div
        class="dates-prices-table-col dates-prices-get-quote item-col book-now"
        v-if="model.labels.canBook && model.labels.isCostco && model.labels.isLoggedIn && !model.labels.isGB && model.labels.membershipDetails && !raqmodel.tour.isCmsTour"
      >
        <book-button
          :booknowurl="row.bookNowUrl"
          :labels="model.labels"
        ></book-button>
      </div>
    </div>
  </transition>
</template>
<script>
import QuoteRaqButton from "../modals/raq-modal-button.vue";
import BookButton from "./book-button.vue";
export default {
  props: ["model", "row", "raqmodel", "localappdata"],
  components: {
    "quote-raq-button": QuoteRaqButton,
    "book-button": BookButton,
  },
  computed: {
    showRow: function () {
      if (this.model.labels.hideDepartureDatesSwitch) {
        return true;
      }
      if (
        (!this.model.labels.toggleDefinite ||
          (this.model.labels.toggleDefinite && this.row.definiteDeparture)) &&
        (!this.model.labels.toggleSpecialOffers ||
          (this.model.labels.toggleSpecialOffers && this.row.specialOffer))
      ) {
        return true;
      } else {
        return false;
      }
    },

    dealsString() {
      return this.row.currentDeals;
    },
    deals() {
      let dealsString = this.dealsString;
      return dealsString.split(",");
    },
    currentDealPriceFlag: function () {
      if (this.model.labels.airfareIncluded) {
        return this.row.price + "*";
      }
      return this.row.price;
    },
    discountedPriceFlag: function () {
      if (this.model.labels.airfareIncluded) {
        return this.row.discountedPrice + "*";
      }
      return this.row.discountedPrice;
    },
  },
  methods: {
    dataLayerLoginForPriceClicked() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: "Login Clicked From Trip Page" });
    },
  },
};
</script>
