import Vue from 'vue';
import HeroEmbeddedSearch from '../../vue-components/heros/hero-embeddedSearch.vue';
function heroEmbeddedSearchView() {
    new Vue({
        el: '#hero-embeddedSearch',
        data () {
            return {
                herodata: window.Cms.heroModel
            };
        },
        components: {
            'hero-embedded-search': HeroEmbeddedSearch,
        }
    });
};
module.exports = heroEmbeddedSearchView;