import * as searchConstants from '../../search-constants.js';
import eventBus from '../../vue-functions/event-bus';

function formFieldSearch (searchObj) {
    // do search function is only initiated from the vue search field application
    // each time a value, or autocomplete selection is chosen, a new search initiates
    this.resetAllFilters();
    this.resetSearch();

    switch(searchObj.searchType) {
        case 'text=' :
            this.$data.settings.currentDestinations = searchObj.term;
            this.$data.settings.currentContinent = false;
            this.$data.settings.currentSearchPrefix = searchConstants.FREETEXTSEARCHTERM;
            break;
        case 'tour=' :
            this.$data.settings.currentDestinations = searchObj.term;
            this.$data.settings.currentContinent = false;
            this.$data.settings.currentSearchPrefix = searchConstants.TOURSEARCHTERM;
            break;
        case 'continents=' :
            this.$data.settings.currentDestinations = false;
            this.setRegionSearch(searchObj.term);
            eventBus.$emit('set-values-from-search-input-region', 'region');
            break;
        case 'destinations=' :
            this.$data.settings.currentDestinations = searchObj.term;
            this.getSelectedDestinations([searchObj.term]);
            eventBus.$emit('set-values-from-search-input-region', 'destination');
            break;
        default:
            this.$data.settings.currentDestinations = searchObj.term;
            this.$data.settings.currentSearchPrefix = searchConstants.DEFAULTSEARCHTERM;
            break;
    }
    let searchString = this.constructSearchTerm();
    this.manageUrlState(searchString);
    this.fetchSearch(searchString);
}
module.exports = formFieldSearch;