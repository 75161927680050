var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ttc-vue-slider vue-range-slider" }, [
    _c("div", { staticClass: "rangefromto" }, [
      _c("div", { staticClass: "rangefrom" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value[0],
              expression: "value[0]",
            },
          ],
          staticClass: "input has-value",
          attrs: {
            type: "text",
            maxlength: "2",
            oninput: "this.value=this.value.replace(/[^0-9]/g,'');",
            id: "durationfrom",
            "aria-label": "StartDuration",
            min: this.min,
            max: this.max,
          },
          domProps: { value: _vm.value[0] },
          on: {
            change: _vm.passValue,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.value, 0, $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "rangeto" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.value[1],
              expression: "value[1]",
            },
          ],
          staticClass: "input has-value",
          attrs: {
            type: "text",
            maxlength: "2",
            oninput: "this.value=this.value.replace(/[^0-9]/g,'');",
            id: "durationto",
            "aria-label": "DurationUpto",
            min: this.min,
            max: this.max,
          },
          domProps: { value: _vm.value[1] },
          on: {
            change: _vm.passValue,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.value, 1, $event.target.value)
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }