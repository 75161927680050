var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "portal",
    {
      attrs: {
        name: "quote-ftc-header-button",
        "target-el": "#notificationbar_cta",
        slim: "",
      },
    },
    [
      _c("button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.labels.button,
            expression: "labels.button",
          },
        ],
        staticClass:
          "notificationbar__utils__cta button level-item is-hidden-mobile",
        attrs: { href: "#" },
        domProps: { textContent: _vm._s(_vm.labels.button) },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleModal()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }