<template>
	<div
		:class="{ 'is-active': filterVisibility.showDurationFilter }"
		class="dropdown">
		<div class="dropdown-trigger">
			<a
				class="button is-filter"
				aria-haspopup="true"
				aria-controls="dropdown-date"
				href="#"
				:disabled="settings.loading"
				:class="{
					'is-filter-active':
						settings.filtersText.monthsText ||
						settings.filtersText.durationText,
				}"
				@click.prevent="
					$emit('duration-filter-actions', '#dropdown-date')
				">
				<div>
					<span
						v-if="
							embeddedsearchcontent &&
							!settings.filtersText.monthsText &&
							!settings.filtersText.durationText
						"
						v-text="
							embeddedsearchcontent.datesPlaceHolderText
						"></span>
					<span
						v-if="
							!settings.filtersText.monthsText &&
							!settings.filtersText.durationText &&
							!embeddedsearchcontent
						"
						>{{ settings.filterTripsContent.dateMenuText }}</span
					>
					<span
						v-if="
							settings.filtersText.monthsText &&
							!settings.filtersText.durationText
						"
						>{{ settings.filtersText.monthsText }}</span
					>
					<span
						v-if="
							settings.filtersText.durationText &&
							!settings.filtersText.monthsText
						"
						>{{ settings.filtersText.durationText }}</span
					>
					<span
						class="duration-filter-multi-values"
						v-if="
							settings.filtersText.monthsText &&
							settings.filtersText.durationText
						"
						>{{ settings.filtersText.monthsText }}, <br />{{
							settings.filtersText.durationText
						}}
					</span>
					<span
						v-if="!settings.filtersLocked"
						class="icon is-small">
						<i
							class="fas fa-chevron-down"
							aria-hidden="true"></i>
						<i
							class="fas fa-chevron-up"
							aria-hidden="true"></i>
					</span>
				</div>
			</a>
		</div>
		<div
			class="dropdown-menu dropdown-date"
			id="dropdown-date"
			role="presentation">
			<div class="dropdown-header">
				<div class="dropdown-header-wrap">
					<div class="dropdown-header-close">
						<button
							class="dropdown-close"
							@click="setFilterVisibility">
							<span class="is-sr-only">Close Menu</span>
							<span
								class="dropdown-close__icon delete"
								aria-hidden="true"></span>
						</button>
					</div>
				</div>
				<keep-alive>
					<search-calendar
						ref="monthpicker"
						:urlmonths="settings.filtersValues.monthsVal">
					</search-calendar>
				</keep-alive>
				<div class="rangesliderholder">
					<div class="level-left">
						<h2 class="dropdown-subheading">
							Duration of Vacation
						</h2>
					</div>
					<search-duration-slider
						ref="durationslider"
						:dslider="filterVisibility.showDurationFilter"
						:minval="settings.filtersValues.minDurationVal"
						:maxval="settings.filtersValues.maxDurationVal">
					</search-duration-slider>
				</div>
			</div>
			<div class="dropdown-footer search-footer">
				<div class="buttonholder search-clear-apply">
					<button
						@click.prevent="$emit('reset-duration-filters')"
						class="btn-clear"
						href="#">
						Clear Selection
					</button>
					<button
						@click.prevent="
							handleApplyClick(
								settings.filterActions.durationActionClicked,
							)
						"
						class="btn-apply">
						Apply Selection
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import searchDurationSlider from "../../vue-components/search/search-duration-slider.vue";
import searchCalendar from "../../vue-components/search/search-calendar.vue";
import handleApplyClick from "../../search-functions/global-filter-functions/handle-apply-click";

export default {
	data() {
		return {};
	},
	props: {
		settings: {
			type: Object,
		},
		filterVisibility: {
			type: Object,
		},
		embeddedsearchcontent: {
			type: Object,
		},
	},
	components: {
		searchDurationSlider,
		searchCalendar,
	},

	methods: {
		handleApplyClick,
		setFilterVisibility: function (target) {
			this.$emit("set-filter-visibility", target);
		},
		filterSearch: function () {
			this.$emit("filter-search");
		},
		passValue() {
			if (this.daysvalue[0] == this.min) {
				this.valuesObject.minDays = null;
			} else {
				this.valuesObject.minDays = this.daysvalue[0];
			}

			if (this.daysvalue[1] == this.max) {
				this.valuesObject.maxDays = null;
			} else {
				this.valuesObject.maxDays = this.daysvalue[1];
			}
		},
	},
};
</script>
