var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brands__content columns" },
    _vm._l(_vm.modulescard, function (card, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "pod-card",
          attrs: {
            "data-type": "navigation",
            "data-name": "travel-pod",
            id: card.travelStyleId,
          },
        },
        [
          _c("div", { staticClass: "pod-card-content" }, [
            card.imageUrl
              ? _c("div", { staticClass: "pod-card-header" }, [
                  card.linkUrl
                    ? _c("a", {
                        staticClass: "pod-card-content__link",
                        style: {
                          backgroundImage: "url('" + card.imageUrl + "')",
                          backgroundPosition:
                            "" +
                            card.leftFocalPoint +
                            " " +
                            card.topFocalPoint +
                            "",
                        },
                        attrs: {
                          href: card.linkUrl,
                          alt: card.title,
                          "aria-label": card.title,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !card.linkUrl
                    ? _c("div", {
                        staticClass: "pod-card-content__link",
                        style: {
                          backgroundImage: "url('" + card.imageUrl + "')",
                          backgroundPosition:
                            "" +
                            card.leftFocalPoint +
                            " " +
                            card.topFocalPoint +
                            "",
                        },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "pod-card-content-body" }, [
              _c(
                "div",
                {
                  staticClass: "pod-card-content-body__header",
                  class: card.iconUrl ? "has-icon" : "",
                },
                [
                  card.iconUrl
                    ? _c("img", {
                        staticClass: "header-icon",
                        attrs: {
                          src: card.iconUrl,
                          alt: card.iconAltTag ? card.iconAltTag : "",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  card.linkUrl
                    ? _c(
                        "a",
                        {
                          staticClass: "header-title",
                          attrs: { href: card.linkUrl },
                        },
                        [_c("h3", [_vm._v(_vm._s(card.title))])]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              card.text
                ? _c("div", { staticClass: "pod-card-content-body-text" }, [
                    _vm._v("\n\t\t\t\t\t" + _vm._s(card.text) + "\n\t\t\t\t"),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pod-card-footer" }, [
              card.linkUrl
                ? _c("div", { staticClass: "pod-card-footer-inner" }, [
                    card.linkName != ""
                      ? _c(
                          "a",
                          {
                            staticClass: "pod-card-footer__link",
                            attrs: {
                              href: card.linkUrl,
                              target: "_blank",
                              "aria-label": card.title,
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(card.linkName) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }