import Vue from 'vue';
import ItineraryView from '../vue-components/itinerary/itinerary.vue';

function itineraryDays() {
    new Vue({
        el: '#itinerary-section-view',
        data () {
            return {
                windowCms: window.Cms,
                dictionarymodel: window.Cms.dictionaryValuesModel,
                itineraryModel: window.Cms.itineraryModel,
                raqModel: window.Cms.raqModal,
                directionsModel: window.Cms.directionsModel
            };
        },
        components: {
            'itinerary-view': ItineraryView
        },

    });
};
module.exports = itineraryDays;