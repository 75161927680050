function setLogoSizes() {
    const logoImages = document.querySelectorAll('.brand-logo-image');
   
    function setBrandLogoImageSizes() {
        
        for (let i = 0; i < logoImages.length; i++) {
            if (!logoImages[i].classList.contains('logo-resized')) {
                let logoWidth = logoImages[i].width;                
                let logoHeight = logoImages[i].height;
                let logoAspectRatio = logoWidth / logoHeight;

                logoImages[i].style.height = (200 / (logoAspectRatio + 1)) + 'px';
                if (!isNaN(logoAspectRatio)) {
                    logoImages[i].classList.add('logo-resized');
                    logoImages[i].parentNode.classList.add('logo-resized-wrap');                    
                }
            }
        }
        
    }
    
    const checkLogoImages = setInterval(function(){
        setBrandLogoImageSizes();
        
        let logosResized = document.querySelectorAll('.logo-resized').length;
        let logosNotResized = logoImages.length - logosResized;

        if (logosNotResized == 0) {
            clearInterval(checkLogoImages);
        }
    }, 500);
}

module.exports = setLogoSizes;