
function initAccordion(){

    let headers = document.querySelectorAll('.accordion .accordion-header');
    let expandItems = document.querySelector('.accordion-expand');
    let collapseItems = document.querySelector('.accordion-collapse');

    for(var i = 0; i < headers.length; i++) {
        headers[i].addEventListener('click', openCurrAccordion);
    }

    expandItems.addEventListener('click', expandAll);
    collapseItems.addEventListener('click', collapseAll);


    function openCurrAccordion(e) {

        for (let i = 0; i < headers.length; i++) {
            let parent = headers[i].parentElement;
            let article = headers[i].nextElementSibling;

            if (this === headers[i] && !parent.classList.contains('open')) {
                parent.classList.add('open');
                article.style.maxHeight = article.scrollHeight + 'px';
            } else {
                parent.classList.remove('open');
                article.style.maxHeight = '0px';
            }
        }
    }

    function expandAll() {
        for (let i = 0; i < headers.length; i++) {
            let parent = headers[i].parentElement;
            let article = headers[i].nextElementSibling;

            parent.classList.add('open');
            article.style.maxHeight = article.scrollHeight + 'px';
        }
    }

    function collapseAll() {
        for (let i = 0; i < headers.length; i++) {
            let parent = headers[i].parentElement;
            let article = headers[i].nextElementSibling;

            parent.classList.remove('open');
            article.style.maxHeight = '0px';
        }
    }


}

module.exports = initAccordion;
