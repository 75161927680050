import * as searchConstants from '../../search-constants.js';

function setPriceFilterText(valObj) {
    // function expects an object with 1 or 2 values minPrice, and or maxPrice
    // if those values are set in the object
    // we set the price filter text ( button ) and it's associated vales to those received
    let filterText = '';
    if(!valObj.maxPrice) {
       filterText += 'Min-Price: ';
    }
    if(!valObj.minPrice) {
        filterText += 'Max-Price: ';
    }
    if(valObj.minPrice && !valObj.maxPrice) {
       filterText  +=   valObj.minPrice.toLocaleString().split('.')[0];
    }
    if(valObj.maxPrice && !valObj.minPrice) {
        filterText  +=   valObj.maxPrice.toLocaleString().split('.')[0];
    }
    if(valObj.minPrice && valObj.maxPrice) {
        filterText =  valObj.minPrice.toLocaleString().split('.')[0] + ' - ' +  valObj.maxPrice.toLocaleString().split('.')[0];
    }
    // if both values are undefined, set the priceText val to false
    if(!valObj.minPrice && !valObj.maxPrice) {
        this.$data.settings.filtersText.priceText = false;
    // or as per the string created above in this function
    } else {

        this.$data.settings.filtersText.priceText = filterText;
    }
}
module.exports = setPriceFilterText;