var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown" }, [
    _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button",
          class: {
            "filters-active": _vm.selectedMonths.length,
            "no-dropdown": _vm.months.length == 1,
          },
          attrs: { "aria-haspopup": "true", disabled: _vm.apierror },
        },
        [
          !_vm.months.length && !_vm.apierror
            ? _c("span", [
                _c("i", { staticClass: "fas fa-lg fa-spin fa-circle-notch" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.months.length == 1
            ? _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.months.length == 1 ? _vm.months[0] : "Loading"
                  ),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.months.length > 1 && !_vm.selectedMonths.length
            ? _c("span", [_vm._v("Filter by Start Date")])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedMonths.length == 1
            ? _c("span", [_vm._v(_vm._s(_vm.selectedMonths[0]))])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedMonths.length > 1
            ? _c("span", [
                _vm._v(_vm._s(_vm.selectedMonths.length) + " months selected"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.months.length > 1
            ? _c("span", { staticClass: "icon is-small" }, [
                _c("i", {
                  staticClass: "fas fa-angle-down",
                  attrs: { "aria-hidden": "true" },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.months.length > 1
      ? _c(
          "ul",
          {
            staticClass: "dropdown-menu dropdown-content",
            attrs: { role: "presentation" },
          },
          [
            _c(
              "li",
              {
                staticClass:
                  "is-flex columns is-marginless has-text-grey-dark is-size-7 has-text-centered",
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "column cursor-pointer clear-all",
                    class: { disabled: !_vm.selectedMonths.length },
                    on: {
                      click: function ($event) {
                        return _vm.clearAllMonths()
                      },
                    },
                  },
                  [_vm._v("Clear All")]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.months, function (month) {
              return _c(
                "li",
                {
                  key: month.index,
                  staticClass: "dropdown-item is-paddingless is-flex",
                  class: _vm.selectedMonths.includes(month) ? "selected" : "",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "dropdown-label",
                      attrs: { for: month.index },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedMonths,
                            expression: "selectedMonths",
                          },
                        ],
                        attrs: { type: "checkbox", id: month.index },
                        domProps: {
                          value: month,
                          checked: Array.isArray(_vm.selectedMonths)
                            ? _vm._i(_vm.selectedMonths, month) > -1
                            : _vm.selectedMonths,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.selectedMonths,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = month,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedMonths = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedMonths = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedMonths = $$c
                              }
                            },
                            function ($event) {
                              return _vm.updateSelectedMonths(
                                _vm.selectedMonths
                              )
                            },
                          ],
                        },
                      }),
                      _vm._v(
                        "\n                " + _vm._s(month) + "\n            "
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }