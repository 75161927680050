const desktopNavJS = require ('./main-nav-helper/main-nav-helper-desktop');
const mobileNavJS = require ('./main-nav-helper/main-nav-helper-mobile');
const destinationMobileMenu = require ('./destinations-mobile'); // VS menu
const destinationMainMenu = require ('./destinations-desktop-mainMenu');
const debounce = require('lodash.debounce');

function mainNavHelper () {    
    
    function loadNavHelper() {
            let windowWidth = window.innerWidth;

            if (windowWidth <= 1024) {
                mobileNavJS();
                destinationMobileMenu();
            } else {
                desktopNavJS();
               
            } 
            destinationMainMenu(); 
    };
    loadNavHelper();
    window.onresize = debounce(loadNavHelper, 100); 
}

module.exports = mainNavHelper;