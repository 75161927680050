import * as searchConstants from '../../search-constants.js';
function setDestinationFilterText (valuesArray) {
    if(valuesArray.length < 2 && this.$data.parentSearch) {
        let matchedCountry = this.matchApiDestinations(valuesArray[0], 'country');
        this.$data.settings.filtersText.destinationsText = matchedCountry;
    } else if(valuesArray.length < 2 && !this.$data.parentSearch) {
        this.$data.settings.filtersText.destinationsText = valuesArray[0];
    } else {
        this.$data.settings.filtersText.destinationsText = '+' + valuesArray.length + searchConstants.FILTERTEXTDESTINATIONS;
    }
}
module.exports = setDestinationFilterText;