import Vue from 'vue';
import ContentModules from '../vue-components/content-modules/content-modules.vue';

function contentModules() {
    new Vue({
        el: '#content-modules',
        data () {
            return {
                modulesModel:  window.Cms.modules,
                loginModel: window.Cms.loginCall
            };
        },
        components: {
            'content-modules': ContentModules
        }
    });
};
module.exports = contentModules;