<template>
	<section
		v-if="featuredsection"
		class="featured-content">
		<div class="columns">
			<div
				class="column featured-content__desc"
				:class="
					featuredsection.hideIntroductionMedia
						? 'is-full-desktop'
						: 'is-6-tablet is-5-desktop'
				">
				<div
					class="featured-content__text"
					v-html="featuredsection.introText"></div>
			</div>
			<div
				class="column is-6-tablet is-7-desktop featured-content__media"
				v-if="!featuredsection.hideIntroductionMedia">
				<div
					class="featured-content__media-image"
					v-if="featuredsection.introImageUrl && featuredsection.introVideoUrl == ''"
					:style="{
						backgroundImage:
							'url(\'' + featuredsection.introImageUrl + '\')',
					}"></div>
				<div
					class="featured-content__media-video"
					v-if="featuredsection.introVideoUrl">
					<embed
						:title="featuredsection.brandName"
						:src="featuredsection.introVideoUrl" />
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: "featuredSection",
	props: ["featuredsection"],
};
</script>
