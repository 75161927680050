var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.datamenu.tddHideSubNav
    ? _c(
        "div",
        { staticClass: "subnav-placeholder", attrs: { id: "subnav" } },
        [
          _c("nav", { staticClass: "subnav subnav-control subnav-tourPage" }, [
            _c("div", { staticClass: "subnav-container is-flex" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "subnav-wrapper" }, [
                _c(
                  "button",
                  {
                    staticClass: "subnav-selected",
                    attrs: { "aria-label": _vm.datamenu.brandName },
                  },
                  [
                    _c("p", { staticClass: "subnav-selected-value" }),
                    _vm._v(" "),
                    _vm._m(1),
                  ]
                ),
                _vm._v(" "),
                !_vm.datamenu.tddHideOverview
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#overview-section",
                          "data-type": "general interaction",
                          "data-name": "in-page-menu",
                          "data-target": "overview-section",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.overViewTitle))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.datamenu.tddHideItinery && _vm.datamenu.isDefaultMapImage
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#app-mapModal",
                          "data-target": "app-mapModal",
                          "data-name": "in-page-menu",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.mapTitle))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.datamenu.isUWTour &&
                !_vm.datamenu.tddHideItinery &&
                _vm.datamenu.directionsAny
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#itinerary-section",
                          "data-type": "general interaction",
                          "data-name": "in-page-menu",
                          "data-target": "itinerary-section",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.itineraryTitle))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.datamenu.isUWTour &&
                !_vm.datamenu.tddHideItinery &&
                _vm.datamenu.tripHighlightsAny
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#highlights-section",
                          "data-type": "general interaction",
                          "data-name": "in-page-menu",
                          "data-target": "highlights-section",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.highlightsTitle))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.datamenu.isUWTour &&
                !_vm.datamenu.tddHideItinery &&
                _vm.datamenu.itineraryAny
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#itinerary-section",
                          "data-type": "general interaction",
                          "data-name": "in-page-menu",
                          "data-target": "itinerary-section",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.itineraryTitle))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.datamenu.isC1Tour || _vm.datamenu.isV4Tour
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#trip-options-section",
                          "data-target": "trip-options-section",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.datamenu.tourOptionsConfiguratorTitle)
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.datamenu.isUWTour &&
                !_vm.datamenu.tddHideItinery &&
                _vm.datamenu.tripHighlightsAny
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#highlights-section",
                          "data-type": "general interaction",
                          "data-name": "in-page-menu",
                          "data-target": "highlights-section",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.highlightsTitle))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                (!_vm.datamenu.tddHideWhatsIncluded &&
                  _vm.datamenu.whatsIncludedCol1Any) ||
                _vm.datamenu.whatsIncludedCo21Any
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#included-section",
                          "data-type": "general interaction",
                          "data-name": "in-page-menu",
                          "data-target": "included-section",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.whatsIncludedTitle))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.datamenu.isUWTour && _vm.datamenu.shipsAny
                  ? _c(
                      "a",
                      {
                        staticClass: "subnav__link",
                        attrs: {
                          href: "#ships-section",
                          "data-type": "general interaction",
                          "data-name": "in-page-menu",
                          "data-target": "ships-section",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.shipsTitle))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "feature-subnav-cta sticky-nav-is-visible not-last is-hidden-mobile",
                  attrs: { id: "feature-subnav_cta" },
                }),
                _vm._v(" "),
                !_vm.datamenu.useWidget
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "subnav__link button is-secondary js-scroll-link sticky-nav-is-visible last is-hidden-mobile cta",
                        attrs: {
                          "data-target": "availability-section",
                          href: "#availability-section",
                        },
                      },
                      [_vm._v(_vm._s(_vm.datamenu.datesAndPricesTitle))]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.datamenu.useWidget
                ? _c(
                    "button",
                    {
                      staticClass:
                        "button is-secondary sticky-nav-is-visible last cta ttc-widget-button is-hidden-desktop",
                      attrs: { id: "show-ttc-widget-button-subnav" },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.datamenu.raqTitle) +
                          "\n\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._m(2),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subnav-scroll-btn scroll-left hidden" }, [
      _c("i", { staticClass: "fas fa-lg fa-chevron-left" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "subnav-toggle-indicator" }, [
      _c("i", { staticClass: "fas fa-chevron-down is-closed" }),
      _vm._v(" "),
      _c("i", { staticClass: "fas fa-chevron-up is-open" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "subnav-scroll-btn scroll-right hidden" }, [
      _c("i", { staticClass: "fas fa-lg fa-chevron-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }