var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "tripoptions-section section",
      attrs: { id: "trip-options-section" },
    },
    [
      _c(
        "div",
        {},
        [
          _c(
            "div",
            [
              _c("tour-filters-view", {
                attrs: {
                  tourfilters: _vm.optionFiltersOnLoad,
                  "show-loader": _vm.showLoader,
                  "filter-configuration": _vm.filterConfiguration,
                  touroptions: _vm.tripOptionsData,
                },
                on: { "send-filters-data": _vm.filtersSelectedData },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("tour-options-view", {
            attrs: {
              touroptions: _vm.tourVariants ?? _vm.tripOptionsData,
              count: _vm.tripDisplayLimit,
              "tour-loading": _vm.tourLoading,
            },
            on: { tourId: _vm.updateTourId },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }