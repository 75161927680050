<template>
	<div>
		<div class="itinerary section">
			<div class="itinerary-header">
				<h3 class="itinerary-title">{{ raqmodel.tour.tourName }}</h3>
			</div>
			<div class="itinerary-body">
				<div
					class="map itinerary-map"
					v-if="itinerarymodel.mapImageUrl">
					<div
						data-type="general interaction"
						data-name="map"
						id="app-mapModal"
						class="map-holder">
						<map-modal
							:mapTypeImage="true"
							:mapurl="itinerarymodel?.mapImageUrl"
							:tourtype="raqmodel.tour"></map-modal>
						<div class="map-image-wrap">
							<img
								:src="itinerarymodel.mapImageUrl"
								class="itinerary-map-image fullimage"
								:alt="itinerarymodel.mapImageAltTag" />
							<map-modal-button></map-modal-button>
						</div>
					</div>
				</div>
				<div
					class="lead itinerary-days"
					id="itinerary-section"
					data-type="general interaction"
					data-name="itinerary">
					<section class="accordions">
						<div class="accordion-tabs-wrap">
							<h3 class="itinerary-text">
								{{ dictionarymodel.itinerary }}
							</h3>

							<div
								v-if="
									directionsmodel &&
									directionsmodel !== 'undefined'
								"
								class="itinerary-switcher">
								<ul class="tabs">
									<li class="tab">
										<a
											class="button is-subtle is-level"
											:class="
												!reverseAccordionActive
													? 'active'
													: ''
											"
											@click.prevent="
												reverseAccordionActive = false
											"
											href="direction1"
											>{{
												directionsmodel?.directions[0]
													.name
											}}</a
										>
									</li>
									<li
										v-if="
											directionsmodel?.directions
												?.length > 1
										"
										class="tab">
										<a
											class="button is-subtle is-level"
											:class="
												reverseAccordionActive
													? 'active'
													: ''
											"
											@click.prevent="
												reverseAccordionActive = true
											"
											href="direction2"
											>{{
												directionsmodel?.directions[1]
													.name
											}}</a
										>
									</li>
								</ul>
							</div>

							<div class="accordion-tabs-container">
								<span
									class="accordion-tab accordion-collapse"
									aria-label="toggle"
									tabindex="0"
									>{{ dictionarymodel.collapseAll }}</span
								>
								<span
									class="accordion-tab accordion-expand"
									tabindex="0"
									aria-label="toggle"
									>{{ dictionarymodel.expandAll }}</span
								>
							</div>
						</div>
						<div
							v-if="directionsmodel"
							class="accordion-wrap">
							<div
								v-show="!reverseAccordionActive"
								id="direction1">
								<itinerary-accordion
									v-for="(day, index) in directionsmodel
										?.directions[0].itineraries"
									:key="index"
									:day="day"
									:index="index"
									:uniworld="true"></itinerary-accordion>
								<!-- <itinerary-accordion
									v-for="(day, index) in directionsmodel
										?.directions[0].itineraries"
									:key="index"
									:item="day"
									:day="day"
									:index="index"
									:uniworld="true"></itinerary-accordion> -->
							</div>
							<div
								v-if="directionsmodel?.directions?.length > 1"
								v-show="reverseAccordionActive"
								id="direction2">
								<itinerary-accordion
									v-for="(day, index) in directionsmodel
										?.directions[1].itineraries"
									:key="index"
									:day="day"
									:index="index"
									:uniworld="true"></itinerary-accordion>
								<!-- <itinerary-accordion
									v-for="(day, index) in directionsmodel
										?.directions[1].itineraries"
									:key="index"
									:item="day"
									:day="day"
									:index="index"
									:uniworld="true"></itinerary-accordion> -->
							</div>
						</div>
						<div v-if="!directionsmodel">
							<itinerary-accordion
								v-for="(day, index) in itinerarymodel.itinerary"
								:key="index"
								:itinerarymodel="itinerarymodel"
								:dictionarymodel="dictionarymodel"
								:day="day"
								:index="index"
								:tourapi="raqmodel.tour"></itinerary-accordion>
							<!-- <itinerary-accordion
								v-for="(day, index) in itinerarymodel.itinerary"
								:key="index"
								:item="day"
								:itinerarymodel="itinerarymodel"
								:dictionarymodel="dictionarymodel"
								:day="day"
								:index="index"
								:tourapi="raqmodel.tour"
								@toggle="toggleAccordion"></itinerary-accordion> -->
						</div>
					</section>

					<div
						v-if="directionsmodel"
						class="accordion-body">
						<div class="accordion-content">
							<div class="column">
								<p
									v-text="
										!reverseAccordionActive
											? directionsmodel?.directions[0]
													.dayToDayNote
											: directionsmodel?.directions[1]
													.dayToDayNote
									"></p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				id="itinerary-modal"
				class="itinerary-modal modal wide-modal itinerary-modal">
				<div class="modal-background"></div>
				<div class="modal-card">
					<div class="modal-form">
						<div class="modal-card-head">
							<p class="modal-card-title is-size-6">Title</p>
							<div class="modal-card-close">
								<i class="fa fa-times fa-lg"></i>
							</div>
						</div>
						<form>
							<section class="modal-card-body">
								<div class="modal-card-content"></div>
							</section>
						</form>
					</div>
				</div>
			</div>
		</div>
		<day-highlitght-slider
			v-if="raqmodel.tour.isCmsTour"
			:itinerarymodel="itinerarymodel"></day-highlitght-slider>
		<highlitghts-section
			:dictionarymodel="dictionarymodel"
			:highlitghts="itinerarymodel.tripHighlights"
			:raqmodel="raqmodel">
		</highlitghts-section>
	</div>
</template>
<script>
import ItineraryAccordion from "./itinerary-accordion.vue";
import MapModal from "../../vue-components/map/map-modal.vue";
import MapModalButton from "../../vue-components/map/map-button.vue";
import HighlitghtsSection from "../tour-page/highlitghtsSection.vue";
import DayHighlightsSlider from "../tour-page/dayHighlightsSlider.vue";
export default {
	data() {
		return {
			reverseAccordionActive: false,
			activeItemTop: 0,
		};
	},
	props: ["directionsmodel", "itinerarymodel", "dictionarymodel", "raqmodel"],
	components: {
		"itinerary-accordion": ItineraryAccordion,
		"map-modal": MapModal,
		"map-modal-button": MapModalButton,
		"highlitghts-section": HighlitghtsSection,
		"day-highlitght-slider": DayHighlightsSlider,
	},
	// methods: {
	// 	toggleAccordion(isOpen, top) {
	// 		if (isOpen) {
	// 			this.activeItemTop = top;
	// 			this.scrollIntoView();
	// 		}
	// 	},
	// 	scrollIntoView() {
	// 		window.scrollTo({
	// 			//top: this.activeItemTop - 130,
	// 			top: this.activeItemTop + 130,
	// 			behavior: "smooth",
	// 		});
	// 	},
	// },
};
</script>
