<template>
  <section class="datesprices section">
    <div class="container">
      <div class="level dates-prices-header main-header is-vcentered">
        <div class="dates-prices-header__title">
          <h2 class="header">{{ model.labels.dateRatesTitle }}</h2>
          <div
            class="dates-prices-results-count"
            :class="{ disabled: localappdata.apiError }"
          >
            Results:
            <span v-if="!localappdata.dataAll.length && !localappdata.apiError"
              >loading...</span
            >
            <span v-if="localappdata.dataAll.length"
              >{{ localappdata.visibleList.length }} /
              {{ localappdata.dataAll.length }}</span
            >
            <span v-if="localappdata.apiError">0</span>
          </div>
        </div>
        <div class="level-right">
          <div
            v-if="
              localappdata.dataAll.length !==
                localappdata.dataOnlyAvailableTotal
            "
            class="level-item available-toggle dates-prices-toggle dates-prices-header__item is-3-tablet"
          >
            <dates-prices-filter-available
              :model="model"
              @update-only-available-flag="updateShowAllDataFlag($event)"
            ></dates-prices-filter-available>
          </div>
          <div class="level-item dates-prices-filters">
            <dates-prices-filter-months
              :model="model"
              :months="localappdata.availableMonths"
              :apierror="localappdata.apiError"
              :selectedmonths="localappdata.userActions.selectedMonths"
              @update-selected-months-array="updateSelectedMonths($event)"
            ></dates-prices-filter-months>
            <dates-prices-filter-ships
              :model="model"
              :ships="localappdata.availableShips"
              :shipsmatch="localappdata.allShipsMatch"
              :apierror="localappdata.apiError"
              :selectedship="localappdata.userActions.selectedShip"
              @update-selected-ship="updateSelectedShip($event)"
            ></dates-prices-filter-ships>
            <dates-prices-filter-routes
              :model="model"
              :routes="localappdata.availableRoutes"
              :apierror="localappdata.apiError"
              :selectedroute="localappdata.userActions.selectedRoute"
              @update-selected-route="updateSelectedRoute($event)"
            ></dates-prices-filter-routes>
          </div>
        </div>
      </div>
    </div>
    <div class="dates-prices-item-container is-relative">
      <vue-uniworld-dates-prices-row
        v-for="item in localappdata.visibleList.slice(
          0,
          localappdata.maxShownItems
        )"
        :key="item.index"
        :model="model"
        :row="item"
        :raqmodel="raqmodel"
        :telNumber="telNumber"
        :localappdata="localappdata"
        @update-current-modal-content="updateCurrentDealsHtml($event)"
      >
      </vue-uniworld-dates-prices-row>
      <div v-if="localappdata.isLoadingFresh" class="dates-prices-item-loader">
        <div v-if="!localappdata.apiError" class="loader-bar inverse"></div>
        <h3
          v-if="localappdata.apiError"
          class="has-text-centered has-text-white"
        >
          <br />Trips are unable to load at the moment, please refresh the page
          or try again later.
        </h3>
      </div>
      <div
        v-if="localappdata.maxShownItems < localappdata.visibleList.length"
        class="has-text-centered datesprices-button-holder"
      >
        <button
          @click="showmoreclicked(localappdata.showAllDataFlag)"
          class="button is-subtle cta"
          :class="{ 'is-loading': localappdata.isloadingmore }"
          v-text="!localappdata.isloadingmore ? 'Show more' : ''"
        ></button>
      </div>
    </div>
    <div
      v-if="!localappdata.isLoadingFresh && !localappdata.visibleList.length"
      class="has-text-centered datesprices-noresults-holder"
    >
      <h3
        v-if="
          localappdata.dataAll.length !== localappdata.dataOnlyAvailableTotal
        "
      >
        No results. Try switching the 'Available only' toggle
      </h3>
      <h3
        v-if="
          localappdata.dataAll.length == localappdata.dataOnlyAvailableTotal
        "
      >
        No results. Try removing some filters
      </h3>
    </div>
    <div
      id="currentDealsModal"
      class="modal current-deals-modal wide-modal"
      :class="modalIsActive ? 'is-active' : ''"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <div class="modal-form">
          <div class="modal-card-head">
            <p class="modal-card-title is-size-6">Current Deals</p>
            <div class="modal-card-close" @click.prevent="closeDealsModal()">
              <i class="fa fa-times fa-lg"></i>
            </div>
          </div>
          <form>
            <section class="modal-card-body">
              <div
                class="modal-card-content current-deals-modal-content has-text-black is-size-6"
                v-html="currentModalContent"
              ></div>
            </section>
          </form>
        </div>
      </div>
    </div>
    <div></div>
  </section>
</template>
<script>
import VueUniworldDatesPriceRow from "./uniworld-dates-prices-row.vue";
import DatesPricesFilterAvailable from "./dates-prices-filters/dates-prices-filter-available.vue";
import DatesPricesFilterMonths from "./dates-prices-filters/dates-prices-filter-months.vue";
import DatesPricesFilterShips from "./dates-prices-filters/dates-prices-filter-ships.vue";
import DatesPricesFilterRoutes from "./dates-prices-filters/dates-prices-filter-routes.vue";
import { closeModal } from "../../../js/modals/closeModal";
export default {
  data() {
    return {
      telNumber: 0,
      currentModalContent: "",
      modalIsActive: false,
    };
  },
  props: ["model", "raqmodel", "localappdata"],
  components: {
    "vue-uniworld-dates-prices-row": VueUniworldDatesPriceRow,
    "dates-prices-filter-available": DatesPricesFilterAvailable,
    "dates-prices-filter-months": DatesPricesFilterMonths,
    "dates-prices-filter-ships": DatesPricesFilterShips,
    "dates-prices-filter-routes": DatesPricesFilterRoutes,
  },
  methods: {
    showmoreclicked: function(showAllDataFlag) {
      this.$emit("showmoreclicked", showAllDataFlag);
    },
    getTelNumber() {
      this.telNumber = document.querySelector(
        ".notificationbar__utils__text"
      ).href;
    },
    closeDealsModal() {
      this.modalIsActive = false;
    },
    updateCurrentDealsHtml(updatedCurrentDeals) {
      this.currentModalContent = updatedCurrentDeals;
      this.modalIsActive = true;
    },
    updateShowAllDataFlag(updatedFlag) {
      this.localappdata.userActions.showAllDataFlag = updatedFlag;
      this.populateVisibleList();
    },
    updateSelectedMonths(updatedList) {
      this.localappdata.userActions.selectedMonths = updatedList;
      this.populateVisibleList();
    },
    updateSelectedShip(updatedShip) {
      this.localappdata.userActions.selectedShip = updatedShip;
      this.populateVisibleList();
    },
    updateSelectedRoute(updatedRoute) {
      this.localappdata.userActions.selectedRoute = updatedRoute;
      this.populateVisibleList();
    },
    populateVisibleList() {
      this.localappdata.isLoadingMore = true;
      setTimeout(() => {
        let visibleListArray = [];
        for (let i = 0; i < this.localappdata.dataAll.length; i++) {
          let dealsAvailable = [];
          for (
            let a = 0;
            a < this.localappdata.dataAll[i].categories.length;
            a++
          ) {
            dealsAvailable.push(
              this.localappdata.dataAll[i].categories[a].isAvailable
            );
          }
          if (
            (this.localappdata.userActions.showAllDataFlag ||
              (!this.localappdata.userActions.showAllDataFlag &&
                dealsAvailable.toString().includes("true"))) &&
            (!this.localappdata.userActions.selectedMonths.length ||
              this.localappdata.userActions.selectedMonths.includes(
                this.localappdata.dataAll[i].startMonth +
                  " " +
                  this.localappdata.dataAll[i].startDateData.slice(0, 4)
              )) &&
            (!this.localappdata.userActions.selectedShip ||
              this.localappdata.dataAll[i].shipNames.includes(
                this.localappdata.userActions.selectedShip
              ) ||
              this.localappdata.userActions.selectedShip === "all-ships") &&
            (!this.localappdata.userActions.selectedRoute ||
              this.localappdata.dataAll[i].cruiseItinerary
                .replace(/\(.*?\)/g, "")
                .replace(/\bdepart\b/gi, "")
                .replace(/\bembark\b/gi, "")
                .includes(this.localappdata.userActions.selectedRoute) ||
              this.localappdata.userActions.selectedRoute === "all-routes")
          ) {
            visibleListArray.push(this.localappdata.dataAll[i]);
          }
        }
        this.localappdata.visibleList = visibleListArray;
        this.localappdata.isLoadingMore = false;
      }, 200);
    },
    toggleDropdownFilters() {
      const dropdownEls = $(".dropdown");
      dropdownEls.click(function(event) {
        event.stopPropagation();
        $(this).toggleClass("is-active");
        $(document).click(function(event) {
          dropdownEls.removeClass("is-active");
        });
      });
    },
    closeModal,
  },
  created() {
    this.getTelNumber();
    this.populateVisibleList();
  },
  mounted() {
    this.toggleDropdownFilters();
    this.closeModal(this.closeDealsModal);
  },
};
</script>
