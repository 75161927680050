var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.ubyincluded.length
      ? _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("h4", { staticClass: "is-size-6 is-marginless mb-1" }, [
              _vm._v("\n      Included Excursions:\n    "),
            ]),
            _vm._v(" "),
            _vm._l(_vm.ubyincluded, function (excursion) {
              return _c("div", { key: excursion.index }, [
                _c(
                  "a",
                  {
                    staticClass: "itinerary-modal-trigger is-size-6",
                    class: {
                      "no-pointer-events no-underline": !excursion.details,
                    },
                    attrs: {
                      href: "#",
                      "data-excursion-title": excursion.title,
                      "data-excursion-details": excursion.details,
                    },
                  },
                  [_vm._v(_vm._s(excursion.title))]
                ),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.ubyplus.length
      ? _c(
          "div",
          { staticClass: "is-inline-block border-1 u-plus-container" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "py-2" },
              _vm._l(_vm.ubyplus, function (excursion) {
                return _c(
                  "span",
                  { key: excursion.index, staticClass: "is-block px-1" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "itinerary-modal-trigger is-size-6 pl-2 pr-3 is-block",
                        class: {
                          "no-pointer-events no-underline": !excursion.details,
                        },
                        attrs: {
                          href: "#",
                          "data-excursion-title": excursion.title,
                          "data-excursion-details": excursion.details,
                        },
                      },
                      [_vm._v(_vm._s(excursion.title))]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass:
          "has-background-black has-text-white p-2 is-block is-size-6 is-flex",
      },
      [_c("span", { staticClass: "pl-1" }, [_vm._v("U+ EXCURSIONS:")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }