var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.datamodal
    ? _c(
        "div",
        {
          staticClass: "modal aboutus-modal modal-geq",
          class: { "is-active": _vm.active },
          attrs: {
            role: "dialog",
            "aria-labelledby": "aboutus_dialog",
            "aria-modal": "true",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              $event.preventDefault()
              return _vm.closeForm()
            },
          },
        },
        [
          _c("div", {
            staticClass: "modal-background",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeForm()
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-card" }, [
            _c("div", { staticClass: "modal-form" }, [
              _c("div", { staticClass: "modal-card-head" }, [
                _c(
                  "a",
                  {
                    staticClass: "modal-card-close bg-transparent",
                    attrs: { href: "#", "aria-label": "close" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.closeForm()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-times fa-lg" })]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-card-body" }, [
                _c("div", { staticClass: "aboutus-logo-image" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.datamodal.logoUrl,
                      alt: _vm.datamodal.logoAlt,
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "aboutus-info",
                  domProps: { innerHTML: _vm._s(_vm.datamodal.aboutUsText) },
                }),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }