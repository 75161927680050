var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal modal-geq",
      class: { "is-active": _vm.active },
      attrs: { id: "ftcmodal" },
    },
    [
      _c("div", {
        staticClass: "modal-background",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.closeForm()
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-card" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.submitted,
                expression: "!submitted",
              },
            ],
            staticClass: "modal-form",
          },
          [
            _c("div", { staticClass: "modal-card-head" }, [
              _c("p", { staticClass: "modal-card-title" }, [
                _vm._v(_vm._s(_vm.labels.modalTitle)),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "modal-card-close bg-transparent",
                  attrs: { href: "#", "aria-label": "close" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeForm()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-times fa-lg" })]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-card-image" }, [
              _c("img", {
                staticClass: "is-background",
                attrs: {
                  src: _vm.labels.backgroundImage,
                  alt: _vm.labels.backgroundImageAltText
                    ? _vm.labels.backgroundImageAltText
                    : "modal-background-image",
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.validateBeforeSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c("section", { staticClass: "modal-card-body" }, [
                  _c("h2", {
                    domProps: { textContent: _vm._s(_vm.labels.modalHeading) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label",
                            class: {
                              "required-label": _vm.labels.isFirstNameMandatory,
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.labels.labelFirstname) +
                                "\n                  "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quoterequest.firstname,
                                expression: "quoterequest.firstname",
                              },
                            ],
                            staticClass: "input input-subtle",
                            attrs: {
                              "data-vv-as": _vm.labels.labelFirstname,
                              id: "ftcfirstname",
                              "aria-label": "labelfirstname",
                              name: "firstname",
                              "v-validate": _vm.firstNameValidationRules,
                              type: "text",
                            },
                            domProps: { value: _vm.quoterequest.firstname },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.quoterequest,
                                  "firstname",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has("firstname")
                            ? _c(
                                "span",
                                { staticClass: "field-validation-error" },
                                [_vm._v(_vm._s(_vm.errors.first("firstname")))]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", { staticClass: "label required-label" }, [
                          _vm._v(_vm._s(_vm.labels.labelLastname)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quoterequest.lastname,
                                expression: "quoterequest.lastname",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "input input-subtle",
                            attrs: {
                              "data-vv-as": _vm.labels.labeLastname,
                              id: "ftclastname",
                              "aria-label": "labellastname",
                              name: "lastname",
                              type: "text",
                            },
                            domProps: { value: _vm.quoterequest.lastname },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.quoterequest,
                                  "lastname",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has("lastname")
                            ? _c(
                                "span",
                                { staticClass: "field-validation-error" },
                                [_vm._v(_vm._s(_vm.errors.first("lastname")))]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        id: "toemailaddress",
                        name: "toemailaddress",
                        type: "hidden",
                      },
                      domProps: {
                        textContent: _vm._s(_vm.quoterequest.toemailaddress),
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        id: "ccemailaddress",
                        name: "ccemailaddress",
                        type: "hidden",
                      },
                      domProps: {
                        textContent: _vm._s(_vm.quoterequest.ccemailaddress),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", { staticClass: "label required-label" }, [
                          _vm._v(_vm._s(_vm.labels.labelEmail)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quoterequest.email,
                                expression: "quoterequest.email",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|email",
                                expression: "'required|email'",
                              },
                            ],
                            staticClass: "input input-subtle",
                            attrs: {
                              "data-vv-as": _vm.labels.labelEmail,
                              id: "ftcEmail",
                              "aria-label": "labelEmail",
                              name: "Email",
                              type: "email",
                              novalidate: "",
                            },
                            domProps: { value: _vm.quoterequest.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.quoterequest,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has("Email")
                            ? _c(
                                "span",
                                { staticClass: "field-validation-error" },
                                [_vm._v(_vm._s(_vm.errors.first("Email")))]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", {
                          staticClass: "label",
                          domProps: {
                            textContent: _vm._s(_vm.labels.labelPhone),
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quoterequest.phone,
                                expression: "quoterequest.phone",
                              },
                            ],
                            staticClass: "input input-subtle",
                            attrs: {
                              placeholder: _vm.labels.placeholderOptional,
                              id: "ftcPhone",
                              "aria-label": "labelPhone",
                              name: "Phone",
                              type: "text",
                            },
                            domProps: { value: _vm.quoterequest.phone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.quoterequest,
                                  "phone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.labels.showZipCode,
                          expression: "labels.showZipCode",
                        },
                      ],
                      staticClass: "columns",
                    },
                    [
                      _c("div", { staticClass: "column" }, [
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label required-label" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.labels.labelZipOrPostalCode) +
                                "\n                  "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.quoterequest.zipCode,
                                  expression: "quoterequest.zipCode",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: _vm.zipCodeValidationRules,
                                  expression: "zipCodeValidationRules",
                                },
                              ],
                              staticClass: "input input-subtle",
                              attrs: {
                                id: "zipCode",
                                name: "zipCode",
                                "aria-label": "labelZipCode",
                              },
                              domProps: { value: _vm.quoterequest.zipCode },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.quoterequest,
                                    "zipCode",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: {
                                id: "showZipCode",
                                name: "showZipCode",
                                type: "hidden",
                              },
                              domProps: {
                                textContent: _vm._s(_vm.labels.showZipCode),
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("zipCode")
                              ? _c(
                                  "span",
                                  { staticClass: "field-validation-error" },
                                  [_vm._v(_vm._s(_vm.errors.first("zipCode")))]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.labels.labelComments)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quoterequest.comments,
                                expression: "quoterequest.comments",
                              },
                            ],
                            staticClass: "input input-subtle",
                            attrs: {
                              type: "text",
                              "aria-label": "labels.placeholderComments",
                              placeholder: _vm.labels.placeholderComments,
                            },
                            domProps: { value: _vm.quoterequest.comments },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.quoterequest,
                                  "comments",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-card-foot" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-secondary show-confirm",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.labels.submitButton) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.submitted,
                expression: "submitted",
              },
            ],
            staticClass: "modal-confirm",
            attrs: { id: "model-confirm-ftc" },
          },
          [
            _c("div", { staticClass: "modal-card-head" }, [
              _c("p", { staticClass: "modal-card-title" }, [
                _vm._v(_vm._s(_vm.labels.modalTitle)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-card-image" }, [
              _c("img", {
                staticClass: "is-background",
                attrs: {
                  src: _vm.labels.submittedBackgroundImage,
                  alt: _vm.labels.submittedBackgroundImageAltText
                    ? _vm.labels.submittedBackgroundImageAltText
                    : "modal-confirm-image",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "card-content" }, [
                _c(
                  "h2",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.submittederror,
                        expression: "!submittederror",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.labels.submittedTitle))]
                ),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.submittederror,
                        expression: "submittederror",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.labels.submittedErrorTitle))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-card-body" }, [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.submittederror,
                      expression: "!submittederror",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.labels.submittedText))]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.submittederror,
                      expression: "submittederror",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.labels.submittedErrorText))]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }