var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal modal-geq",
      class: { "is-active": _vm.active },
      attrs: { id: "geqmodalterms" },
    },
    [
      _c("div", {
        staticClass: "modal-background",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.closeForm()
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-card" }, [
        _c("div", { staticClass: "modal-form" }, [
          _c("div", { staticClass: "modal-card-head" }, [
            _c("p", {
              staticClass: "modal-card-title",
              domProps: { textContent: _vm._s(_vm.title) },
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "modal-card-close bg-transparent",
                attrs: { href: "#", "aria-label": "close" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeForm()
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-times fa-lg" })]
            ),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("section", { staticClass: "modal-card-body" }, [
                _c("h3", {
                  domProps: { textContent: _vm._s(_vm.labels.labelTerms) },
                }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { domProps: { innerHTML: _vm._s(_vm.terms) } }),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }