import axios from 'axios';
import * as searchConstants from '../search-constants';
const debounce = require('lodash.debounce');

function autoCompleteSearch () {
    const searchWrapper = document.querySelectorAll('.search-field');
    
    searchWrapper.forEach(function (value, index) {
        
        if( window.Cms.searchPage  || !value ) {
            return false;
        }
        let searchClickOutside = function(event) {
        let isClickInside = value.contains(event.target);
            if (!isClickInside) {
                resetResults();
            }
        };
        document.addEventListener('click',searchClickOutside);
        const searchField = document.querySelectorAll('.searchInput');
        let searchUrl = value.dataset.searchUrl;
        const AUTOCOMPLETEURL = searchConstants.LOCATION_STRING + searchConstants.AUTO_COMPLETE_PATH;
        let resultContainer = document.querySelectorAll('.searchAutoCompleteContent');
        let searchBtn = value.querySelector('button');
        let userInput =  '';
        let searchTerm = '';
        function goToSearch(searchTermValue, searchValue) {
            window.location = searchUrl + '?' + searchTermValue + searchValue;
        }
        function freeSearch () {
            userInput = searchField[index].value;
            searchTerm = searchConstants.FREETEXTSEARCHTERM;
            goToSearch(searchTerm,userInput);
        }
        searchBtn.addEventListener('click', () => {
            freeSearch();
        });
        searchField[index].addEventListener('keyup', (event) => {
            let keyCode = event.which;
            if(keyCode === 13) {
            freeSearch();
            } else {
                let currentSearchValue = searchField[index].value;
                if(currentSearchValue.length > 2 ) {
                    let ssUrl = AUTOCOMPLETEURL + currentSearchValue;
                    const autoSearch = debounce(() => {
                        axios.get( ssUrl, {timeout: 10000})
                            .then(response => {
                                let suggestions = response.data;
                                renderResults(suggestions);
                            })
                            .catch(error => {
                                //console.log(error);
                            });
                    },200);
                    autoSearch();
                }
                else {
                    resetResults();
                }
            }
        });
        function resetResults () {
            resultContainer[index].innerHTML ='';
            resultContainer[index].classList.remove('is-active');
        }
        function renderResults(autoCompleteData) {
            resultContainer[index].innerHTML = autoCompleteData;
            resultContainer[index].classList.add('is-active');
            resultActions();
        }
        function resultActions () {
            let buttons = resultContainer[index].querySelectorAll('button');

            for(let i =0; i< buttons.length; i ++) {
                let currentBtn = buttons[i];

                currentBtn.addEventListener('click', () => {
                    let btnSearchVal = '';

                    if(currentBtn.dataset.country) {

                        btnSearchVal = currentBtn.dataset.country;
                        searchTerm = searchConstants.DEFAULTSEARCHTERM;
                    }

                    if(currentBtn.dataset.continent) {

                        btnSearchVal = currentBtn.dataset.continent;
                        searchTerm = searchConstants.REGIONSSEARCHTERM;
                    }

                    if(currentBtn.dataset.tour) {

                        btnSearchVal = currentBtn.dataset.tour;
                        searchTerm = searchConstants.TOURSEARCHTERM;
                    }
                    goToSearch(searchTerm,btnSearchVal);
                });
            }
        }
    });
}
module.exports = autoCompleteSearch;