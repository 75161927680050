<template>
	<div
		v-bind:class="{ 'is-active': filterVisibility.showDestinationFilter }"
		class="dropdown">
		<div class="dropdown-trigger">
			<a
				href="#"
				class="button is-filter"
				aria-haspopup="true"
				aria-controls="dropdown-destination"
				:disabled="settings.loading"
				:class="{
					'is-filter-active': settings.filtersText.destinationsText,
				}"
				@click.prevent="
					$emit('destination-filter-actions', '#dropdown-destination')
				">
				<span
					v-if="
						embeddedsearchcontent &&
						!settings.filtersText.destinationsText
					"
					v-text="
						embeddedsearchcontent.destinationPlaceHolderText
					"></span>
				<span
					v-if="
						!settings.filtersText.destinationsText &&
						!embeddedsearchcontent
					"
					>{{
						settings.filterTripsContent.destination
							.destinationMenuText
					}}</span
				>
				<span v-if="settings.filtersText.destinationsText">{{
					settings.filtersText.destinationsText
				}}</span>
				<span
					v-if="!settings.filtersLocked"
					class="icon is-small">
					<i
						class="fas fa-chevron-down"
						aria-hidden="true"></i>
					<i
						class="fas fa-chevron-up"
						aria-hidden="true"></i>
				</span>
			</a>
		</div>
		<div
			class="dropdown-menu dropdown-destination"
			id="dropdown-destination"
			role="presentation">
			<div class="dropdown-header">
				<div class="level dropdown-header-wrap">
					<div class="is-mobile dropdown-header-data">
						<h2 class="dropdown-subheading navbar-item_title">
							{{
								settings.filterTripsContent.destination
									.regionDisplayText
							}}
						</h2>
					</div>
					<div class="dropdown-header-close">
						<button
							class="dropdown-close"
							@click.prevent="setFilterVisibility()">
							<span class="is-sr-only">Close Menu</span>
							<span
								class="dropdown-close__icon delete"
								aria-hidden="true"></span>
						</button>
					</div>
				</div>
			</div>
			<keep-alive>
				<search-destination-filters
					:countriestext="
						settings.filterTripsContent.destination
							.mustSeeCountriesDisplayText
					"
					ref="destinations"
					:locked="settings.filtersLocked"
					:searchtype="settings.currentSearchPrefix"
					:destinations="settings.currentDestinations"
					:continent="settings.currentContinent"
					:filterTripsContent="settings.filterTripsContent"
					:filterActions="settings.filterActions"
					:currentRegionSelected="settings.currentContinent">
				</search-destination-filters>
			</keep-alive>
			<div class="search-footer">
				<div class="buttonholder search-clear-apply">
					<button
						@click="clearDestinations"
						class="btn-clear"
						href="#">
						Clear Selection
					</button>
					<button
						@click.prevent="
							handleApplyClick(
								settings.filterActions.destinationActionClicked,
							)
						"
						class="btn-apply">
						Apply Selection
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import searchDestinationFilters from "../../vue-components/search/search-destination-filters.vue";
import handleApplyClick from "../../search-functions/global-filter-functions/handle-apply-click";

export default {
	data() {
		return {
			regionActive: "",
		};
	},
	props: {
		settings: {
			type: Object,
		},
		filterVisibility: {
			type: Object,
		},
		embeddedsearchcontent: {
			type: Object,
		},
	},
	components: {
		searchDestinationFilters,
	},
	methods: {
		handleApplyClick,
		setFilterVisibility: function (target) {
			this.$emit("set-filter-visibility", target);
		},
		filterSearch: function () {
			this.$emit("filter-search");
		},
		clearDestinations: function () {
			this.$emit("reset-destination-filters");
		},
	},
};
</script>
