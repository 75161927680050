var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass:
        "section hero has-spacing hero-tour-detail hero-section test2",
      class: !_vm.heromodel?.heroWidth ? "hero-full" : "container",
      attrs: { id: "top-section-tour" },
    },
    [
      _c("div", { staticClass: "hero has-spacing hero-tour-detail" }, [
        _c("div", {
          staticClass: "hero-overlay",
          style: { opacity: _vm.opacityValue + "%" },
        }),
        _vm._v(" "),
        _vm.heromodel && _vm.heromodel?.heroImageUrl
          ? _c("img", {
              staticClass: "is-background",
              attrs: {
                src: _vm.heromodel.heroImageUrl,
                alt: _vm.heromodel?.brandLogoAltText,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "hero__content" }, [
          _c("div", { staticClass: "hero__card card" }, [
            _c("div", { staticClass: "hero__card__image" }, [
              _vm.heromodel?.brandLogoUrl && _vm.generatedmodel?.brandLogo
                ? _c("img", {
                    staticClass:
                      "generatedmodel.isSquareLogo ? 'is-square' : '')",
                    attrs: {
                      src: _vm.heromodel?.brandLogoUrl,
                      alt: _vm.heromodel?.brandLogoAltText,
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hero__card__content card-content" }, [
              _vm.heromodel?.tourName != ""
                ? _c("h2", { staticClass: "hero-title" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.heromodel?.tourName) +
                        "\n\t\t\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.heromodel?.tourSubName &&
              (_vm.raqmodel.tour.isV4Tour || _vm.raqmodel.tour.isC1Tour)
                ? _c("p", { staticClass: "hero-subTitle" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.heromodel?.tourSubName) +
                        "\n\t\t\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.heromodel?.fromPrice != ""
                ? _c("p", { staticClass: "pricefrom" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.heromodel?.priceFromLabel) +
                        "\n\t\t\t\t\t\t" +
                        _vm._s(
                          _vm.heroavailability.airfareIncluded ? "*" : ""
                        ) +
                        "\n\t\t\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "details" }, [
                _c(
                  "div",
                  { staticClass: "columns priceholder details-first" },
                  [
                    _vm.heromodel?.fromPrice != ""
                      ? _c("p", { staticClass: "price column" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.heromodel?.fromPrice) +
                              "\n\t\t\t\t\t\t\t\t"
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.heroavailability.airfareIncluded ? "*" : ""
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.heromodel?.originalAdultPrice &&
                    _vm.heromodel?.originalAdultPrice !=
                      _vm.heromodel?.fromPrice
                      ? _c("p", { staticClass: "pricewas column" }, [
                          _c("span", { staticClass: "strikethrough" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.heromodel?.originalAdultPrice)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "days details-last" }, [
                  _c("p", [_vm._v(_vm._s(_vm.heromodel?.days) + " days")]),
                ]),
              ]),
              _vm._v(" "),
              _vm.heroavailability.airfareIncluded &&
              _vm.heroavailability.includesAirfareText !== ""
                ? _c("p", { staticClass: "terms" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.heroavailability.includesAirfareText) +
                        "\n\t\t\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.generatedmodel?.showLoginCallToAction &&
              !_vm.raqmodel.isLoggedIn
                ? _c("p", { staticClass: "login-for-price-call-to-action" }, [
                    _c("img", {
                      staticClass: "logo-image",
                      attrs: {
                        src: _vm.raqmodel.loginForPriceModel.logoUrl,
                        alt: _vm.raqmodel.loginForPriceModel.logoAltText,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "login-link customer-price-link",
                        attrs: {
                          href: _vm.raqmodel.loginForPriceModel.loginUrl,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.dataLayerLoginForPriceClicked()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.raqmodel.loginForPriceModel.text) +
                            "\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("footer", { staticClass: "hero__card__footer card-footer" }, [
              _c("div", { staticClass: "card-footer-item" }, [
                _c("div", {
                  staticClass: "hero-cta",
                  attrs: { id: "feature-hero_cta" },
                }),
                _vm._v(" "),
                _vm.generatedmodel?.tdlButton2Text &&
                !_vm.raqmodel.tour.useWidget
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "button is-secondary hero-cta js-scroll-link cta",
                        attrs: { href: "#availability-section" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.generatedmodel.tdlButton2Text) +
                            "\n\t\t\t\t\t\t"
                        ),
                      ]
                    )
                  : _vm.raqmodel?.tour.useWidget
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "button is-secondary hero-cta cta hero-widget-button",
                        attrs: { id: "show-ttc-widget-button-hero" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.raqmodel.tour.widgetButtonText) +
                            "\n\t\t\t\t\t\t"
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }