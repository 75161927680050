import Vue from 'vue';
import MapModalButton from '../vue-components/map/map-button.vue';
import MapModal from '../vue-components/map/map-modal.vue';
function websiteMapModal() {
    new Vue({
        el: '#app-mapModal',
        data: window.Cms,
        components: {
            'map-modal-button': MapModalButton,
            'map-modal': MapModal
        }
    });
};
module.exports = websiteMapModal;