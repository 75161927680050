<template>
    <div class="dropdown">
        <div class="dropdown-trigger">
            <button class="button" :class="{'filters-active' : selectedRoute && selectedRoute !== 'all-routes', 'no-dropdown' : routes.length == 1}" :disabled="apierror">
                <span v-if="!routes.length && !apierror"><i class="fas fa-lg fa-spin fa-circle-notch"></i></span>
                <span v-if="routes.length > 1 && selectedRoute === 'all-routes'">Filter by Route</span>
                <span v-if="selectedRoute !== 'all-routes'">{{ selectedRoute }}</span>
                <span v-if="routes.length == 1" v-text="routes[0]"></span>
                <span v-if="routes.length > 1" class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
            </button>
        </div>        
        <ul v-if="routes.length > 1" class="dropdown-menu dropdown-content">
            <li class="dropdown-item is-paddingless is-flex" :class="selectedRoute === 'all-routes' ? 'selected' : ''">                
                <label class="dropdown-label" for="all-routes">
                    <input type="radio" id="all-routes" value="all-routes" v-model="selectedRoute" @change="updateSelectedRoute(selectedRoute)">
                    All Routes
                </label>                
            </li>
            <li v-for="route in routes" :key="route.index" class="dropdown-item is-paddingless is-flex" :class="route === selectedRoute ? 'selected' : ''">
                <label class="dropdown-label" :for="route.index">
                    <input type="radio" :id="route.index" :value="route" v-model="selectedRoute" @change="updateSelectedRoute(selectedRoute)">
                    {{ route }}
                </label>
            </li>
        </ul>
    </div>
</template>
<script>    
    export default {
        data() {
            return {                    
                selectedRoute: 'all-routes'
            };
        },
        props: ['model', 'routes', 'selectedroute', 'apierror'],
        methods: {
            updateSelectedRoute() {
                this.$emit('update-selected-route', this.selectedRoute);
            }
        }
    }
</script>