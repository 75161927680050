function checkSearchResultHeight() {
    const searchResult = '.searchlistings__listing';
    const searchResultsDesc = $('.searchlistings__listing__desc');
    searchResultsDesc.each(function() {
       const searchResultHeight = this.clientHeight;
       const searchResultScrollHeight = this.scrollHeight;
       if (searchResultHeight <= (searchResultScrollHeight - 10 )) {
           $(this).parents(searchResult).addClass('read-more');
       }
       this.addEventListener('click', () => {
           $(this).parents(searchResult).addClass('open')
       })
    })
}

module.exports = checkSearchResultHeight;