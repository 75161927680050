var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "search-brand-container" }, [
    _c(
      "div",
      { staticClass: "check-link-holder search-brand-wrapper is-centered" },
      _vm._l(
        _vm.filterTripsContent.travelType.travelTypes,
        function (type, typeIndex) {
          return _c(
            "button",
            {
              key: "traveltype" + typeIndex,
              staticClass: "check-link search-brand-item is-block",
              class: {
                "is-active": _vm.isActive(type),
                hidden: !type.availableType,
              },
              attrs: {
                "data-type-name": _vm.typeNameConcat(type),
                value: _vm.typeNameConcat(type),
                "aria-label": type.travelTypeName,
              },
              on: {
                click: function ($event) {
                  return _vm.selectedTypes(type)
                },
              },
            },
            [
              _c("div", [
                type.availableType
                  ? _c("img", {
                      class: { "is-square": type.isSquare },
                      attrs: {
                        src:
                          _vm.constants.LOCATION_STRING +
                          type.travelTypeLogoUrl,
                        alt: type.travelTypeName + " image",
                      },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              type.availableType
                ? _c("span", { staticClass: "icon icon-check is-small" }, [
                    type.availableType
                      ? _c("i", {
                          staticClass: "fas fa-check",
                          attrs: { "aria-hidden": "true" },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              type.availableType
                ? _c("span", { staticClass: "has-text-center" }, [
                    _vm._v(_vm._s(type.travelTypeName)),
                  ])
                : _vm._e(),
            ]
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }