<template>
	<div class="hero has-spacing hero-tour-detail">
		<div
			class="hero-overlay"
			:style="{ opacity: opacityValue + '%' }"></div>
		<img
			v-if="heromodel && heromodel.heroImageUrl"
			:src="heromodel.heroImageUrl"
			class="is-background"
			:alt="heromodel.brandLogoAltText" />
		<div class="hero__content">
			<div
				class="hero__card card"
				:style="[
					raqmodel?.tour?.isCmsTour && heromodel.preTitle != ''
						? { 'padding-top': '4rem' }
						: '',
				]">
				<div
					class="hero__card__preTitle"
					v-if="heromodel.preTitle != ''">
					{{ heromodel.preTitle }}
				</div>
				<div class="hero__card__image">
					<img
						v-if="
							heromodel.brandLogoUrl && generatedmodel.brandLogo
						"
						class="generatedmodel.isSquareLogo ? 'is-square' : '')"
						:src="heromodel.brandLogoUrl"
						:alt="heromodel.brandLogoAltText" />
				</div>
				<div class="hero__card__content card-content">
					<h2
						class="hero-title"
						v-if="heromodel.tourName != ''">
						{{ heromodel.tourName }}
					</h2>
					<p
						class="pricefrom"
						v-if="heromodel.fromPrice != ''">
						{{ heromodel.priceFromLabel }}
						{{ availabilitiesmodel.airfareIncluded ? "*" : "" }}
					</p>

					<div class="details">
						<div class="columns priceholder details-first">
							<p
								class="price column"
								v-if="heromodel.fromPrice != ''">
								{{ heromodel.fromPrice }}
								<span>{{
									availabilitiesmodel.airfareIncluded
										? "*"
										: ""
								}}</span>
							</p>
							<p
								class="pricewas column"
								v-if="
									heromodel.originalAdultPrice &&
									heromodel.originalAdultPrice !=
										heromodel.fromPrice
								">
								<span class="strikethrough">
									{{ heromodel.originalAdultPrice }}
								</span>
							</p>
						</div>
						<div class="days details-last">
							<p>{{ heromodel.days }} days</p>
						</div>
					</div>
					<p
						class="terms"
						v-if="
							availabilitiesmodel.labels.airfareIncluded &&
							availabilitiesmodel.labels.includesAirfareText !==
								''
						">
						{{ availabilitiesmodel.labels.includesAirfareText }}
					</p>
					<p
						class="login-for-price-call-to-action"
						v-if="
							generatedmodel.showLoginCallToAction &&
							!raqmodel.isLoggedIn
						">
						<img
							:src="raqmodel.loginForPriceModel.logoUrl"
							:alt="raqmodel.loginForPriceModel.logoAltText"
							class="logo-image" />
						<a
							:href="raqmodel.loginForPriceModel.loginUrl"
							class="login-link customer-price-link"
							@click="dataLayerLoginForPriceClicked()">
							{{ raqmodel.loginForPriceModel.text }}
						</a>
					</p>
				</div>
				<footer class="hero__card__footer card-footer">
					<div class="card-footer-item">
						<div
							id="feature-hero_cta"
							class="hero-cta"></div>
						<a
							v-if="
								generatedmodel.tdlButton2Text &&
								!raqmodel.tour.useWidget
							"
							class="button hero-cta js-scroll-link cta"
							href="#availability-section">
							{{ generatedmodel.tdlButton2Text }}
						</a>
						<button
							v-else-if="raqmodel.tour.useWidget"
							id="show-ttc-widget-button-hero"
							class="button is-secondary hero-cta cta hero-widget-button">
							{{ raqmodel.tour.widgetButtonText }}
						</button>
					</div>
				</footer>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			opacityValue: 50,
		};
	},
	props: ["heromodel", "raqmodel", "availabilitiesmodel", "generatedmodel"],
	methods: {
		dataLayerLoginForPriceClicked() {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ event: "Login Clicked From Trip Page" });
		},
	},
	mounted() {
		this.opacityValue =
			this.heromodel.opacityHeroValue != ""
				? this.heromodel.opacityHeroValue
				: this.opacityValue;
	},
};
</script>
