<template>
    <div v-if="content.validationActiveCheckbox" class="modal modal-validate" id="validateModal" :class="{ 'is-active': active }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <div class="modal-form">
                <div class="modal-card-head">
                    <p class="modal-card-title" v-text="content.validationHeaderText"></p>
                </div>
                <section class="modal-card-body">
                    <p v-text="content.validationMessage"></p>
                    <p v-html="content.validationInstructionText"></p>
                    <p class="countdown-text">{{content.validationReDirectText}}&nbsp;<strong>{{countdownTimerValue}}</strong>&nbsp;seconds.</p>
                </section>
                <div class="modal-card-foot">
                    <button type="button" class="button is-primary width-auto" v-text="content.validationButtonText" v-on:click="redirect(content.validationRedirectUrl)"></button>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
    export default {
        props: ['content'],        
        data() {
            return {
                active: false,
                countdownTimerValue: this.content.validationCountdownSeconds
            };
        },
        methods: {
            redirect(url) {
                window.location.href = url;
            }
        },
        created: function() {
            if (this.content.validationActiveCheckbox) {
                let self = this;
                let memberNumberValid = sessionStorage.getItem('memberValid');                
                let currentUrl = window.location.href;
                let url = new URL(currentUrl);
                let memberIdParam = url.searchParams.get('memberId');    

                if (!memberNumberValid) {

                    function showInvalidModal() {
                        self.active = true;
                        
                        setInterval(function() {
                            if (self.countdownTimerValue > 0) {
                                self.countdownTimerValue = self.countdownTimerValue - 1;
                            } else {
                                self.redirect(self.content.validationRedirectUrl);
                            }
                        }, 1000);
                    }

                    if (memberIdParam == null) {
                        showInvalidModal();
                    }
                    else {
                        const regexNumbers = /\d{4}/;
                        const regexTestLetters = memberIdParam.startsWith('LGW');
                        let regexTestNumbers = regexNumbers.test(memberIdParam);
                        let paramsAreValid = regexTestLetters && regexTestNumbers;

                        if (!paramsAreValid) {
                            showInvalidModal();
                        } else {
                            sessionStorage.setItem('memberValid', true);
                            sessionStorage.setItem('memberId', memberIdParam);
                        }
                    }
                }
            }
        }
    };
</script>