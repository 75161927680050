function exitToMainSearch () {
    let parentSearchUrl = window.Cms.searchAppParams.searchUrl;
    let filterString =  this.constructSearchTerm();
    let activeFilters = this.makeSearchFilters();
    
    if(activeFilters.length > 0) {
        for(let i =0; i < activeFilters.length ; i ++) {
            if(activeFilters[i][activeFilters[i].length-1] != '='){
                filterString += '&' + activeFilters[i];
            }
        }
    }
    window.location.href = parentSearchUrl + '?' + filterString;
}
module.exports = exitToMainSearch;