import Vue from 'vue';
import appEmbeddedSearch from '../vue-components/apps/embedded-search.vue';

function embeddedSearch() {
    new Vue({
        el: '#app-embeddedSearch',
        data: {
            appId: 'app-embeddedSearch'
        },
        components: {
            appEmbeddedSearch,
        }
    });
};
module.exports = embeddedSearch;