var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content.validationActiveCheckbox
    ? _c(
        "div",
        {
          staticClass: "modal modal-validate",
          class: { "is-active": _vm.active },
          attrs: { id: "validateModal" },
        },
        [
          _c("div", { staticClass: "modal-background" }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-card" }, [
            _c("div", { staticClass: "modal-form" }, [
              _c("div", { staticClass: "modal-card-head" }, [
                _c("p", {
                  staticClass: "modal-card-title",
                  domProps: {
                    textContent: _vm._s(_vm.content.validationHeaderText),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body" }, [
                _c("p", {
                  domProps: {
                    textContent: _vm._s(_vm.content.validationMessage),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.content.validationInstructionText),
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "countdown-text" }, [
                  _vm._v(_vm._s(_vm.content.validationReDirectText) + " "),
                  _c("strong", [_vm._v(_vm._s(_vm.countdownTimerValue))]),
                  _vm._v(" seconds."),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-card-foot" }, [
                _c("button", {
                  staticClass: "button is-primary width-auto",
                  attrs: { type: "button" },
                  domProps: {
                    textContent: _vm._s(_vm.content.validationButtonText),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.redirect(_vm.content.validationRedirectUrl)
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }