function scrollToFilterSmallScreen (scrollTarget) {
    let isSmallScreen = this.getCurrentScreenSize() === 'ss';
    if(!isSmallScreen) {
        return;
    }
    if(isSmallScreen) {
        setTimeout(() => {
            this.scrollToElement(scrollTarget);
        }, 300);
    }
}
module.exports = scrollToFilterSmallScreen;