<template>
  <div
    v-if="citiesCount > 0"
    class="searchlisting-tour-data-item"
  >
    <i class="fas fa-location-arrow"></i>
    <span class="searchlisting__item">{{result.locationsVisited.split(',').slice(0,2).join(',')}} <a v-if="citiesCount > 2" :href="`${result.url}/#overview-section`"
                    target="_blank" >... +{{citiesCount -2}} more</a></span>
  </div>
</template>
<script>
import * as searchConstants from "../../search-constants.js";
export default {
  data: function () {
    return {
      shownLocations: 0,
      hasLocations: false,
      locationsLength: 0,
      totalLocations: 0,
      lengthLimit: searchConstants.LOCATIONSDISPLAYCHARACTERLIMIT,
      locationString: "",
    };
  },
  mounted() {
    let locationList = this.result.locationsVisited;
    if (locationList == null) {
      return;
    }
    // this is to generate the truncated string
    if (locationList !== "") {
      this.hasLocations = true;
      // get size of string
      let locationListLength = locationList.length;
      // if string is larger than limit
      if (locationListLength > this.lengthLimit) {
        // trim the string to a substring after filtering out the bad data
        // substring truncates to desired length
        // replace removes any last commas from string, or they are counted in the list length
        let trimmedList = locationList
          .substring(0, this.lengthLimit)
          .replace(/,\s*$/, "");
        // get a new array from the trimmed list to calculate the list of locations in the array
        let trimmedListArray = trimmedList.split(",").length;
        this.shownLocations = trimmedListArray;
        let locationArray = locationList.split(",");
        this.totalLocations = locationArray.length;
        this.locationsLength = this.totalLocations - trimmedListArray;
        trimmedList += "...";
        this.locationString = trimmedList;
      } else {
        this.locationString = locationList;
      }
    }
  },
  methods: {},
  props: ["settings", "result","citiesCount"],
  filters: {
    trimUnicodes: function (string) {
      // this filter is used to replace unicode spaces in json nodes with a nice space
      return string.replace(/_x0020_/g, " ");
    },
  },
};
</script>
