import Vue from 'vue';
import HeroView from '../vue-components/tour-cms/hero.vue';
function heroView() {
    new Vue({
        el: '#top-section-tour',
        data() {
            return {
                windowCms: window.Cms,
                heroModel: window.Cms.heroModel,
                availabilitiesModel: window.Cms.availabilitiesModel,
                raqModel: window.Cms.raqModal,
                generatedModel: window.Cms.generatedModelSection
            };
        },
        components: {
            'hero-view': HeroView,
        }
    });
};
module.exports = heroView;