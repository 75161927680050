<template>
	<div
		class="modal modal-geq modal-map"
		id="mapmodal"
		:class="{ 'is-active': active }">
		<div
			class="modal-background"
			@click.prevent="closeForm()"></div>
		<div
			v-if="!mapTypeImage"
			class="modal-card modal-map modal-map-image"
			v-bind:style="{ backgroundImage: 'url(' + mapurl + ')' }"
			alt=""></div>

		<img
			v-if="mapTypeImage"
			:src="mapurl"
			class="fullimage"
			:class="
				tourtype.isV4Tour ? 'v4map' : tourtype.isC1Tour ? 'c1Map' : ''
			"
			:alt="itinerarymodel?.mapImageAltTag" />

		<a
			@click.prevent="closeForm()"
			href="#"
			aria-label="close"
			class="modal-card-head modal-card-close">
			<i class="fa fa-times fa-3x"></i>
		</a>
	</div>
</template>
<script>
import EventBus from "../../vue-functions/event-bus";
import { closeModal } from "../../../js/modals/closeModal";
export default {
	data() {
		return {
			active: false,
			modal: false,
		};
	},
	props: ["mapurl", "mapTypeImage", "itinerarymodel", "tourtype"],
	methods: {
		show() {
			this.active = true;
		},
		closeForm() {
			document.documentElement.classList.remove("is-clipped");
			this.active = false;
		},
		closeModal,
	},
	created: function () {
		EventBus.$on("TOGGLE_MAP_MODAL", () => {
			document.documentElement.classList.add("is-clipped");
			this.show();
		});
	},
	mounted() {
		this.closeModal(this.closeForm);
	},
};
</script>
