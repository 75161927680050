function getFeatureSection() {
    
    let featuresCard = document.querySelectorAll('.feature-listing');
    let featureCardInner;

    if(featuresCard.length>0){
        featuresCard.forEach(function (card, index) {
            featureCardInner = document.querySelector('.feature__card').clientHeight;
            card.style.height = featureCardInner;
        });
    }

    //If menu doesn't exist feature should go over hero
    let featureList= document.querySelectorAll('.featured-content');
    let pageHasMenu = document.querySelector('.subnav-placeholder');

    if(pageHasMenu && pageHasMenu.length > 0){
        featureList.forEach((feature) => {
            feature.classList.add('hasMenu');
        });
    }
    
}


module.exports = getFeatureSection;
