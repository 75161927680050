var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { "is-active": _vm.filterVisibility.showBrandFilter },
    },
    [
      _c("div", { staticClass: "dropdown-trigger" }, [
        _c(
          "a",
          {
            staticClass: "button is-filter",
            class: { "is-filter-active": _vm.settings.filtersText.brandsText },
            attrs: {
              href: "#",
              "aria-haspopup": "true",
              "aria-controls": "dropdown-brands",
              disabled: _vm.settings.loading,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleFilter()
              },
            },
          },
          [
            !_vm.settings.filterTripsContent.vacationBrand
              .vacationBrandMenuText && !_vm.settings.filtersText.brandsText
              ? _c("span", [_vm._v("Vacation Brand")])
              : _vm._e(),
            _vm._v(" "),
            _vm.settings.filterTripsContent.vacationBrand
              .vacationBrandMenuText && !_vm.settings.filtersText.brandsText
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.settings.filterTripsContent.vacationBrand
                        .vacationBrandMenuText
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.settings.filtersText.brandsText
              ? _c("span", [
                  _vm._v(_vm._s(_vm.settings.filtersText.brandsText)),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.settings.filtersLocked
              ? _c("span", { staticClass: "icon is-small" }, [
                  _c("i", {
                    staticClass: "fas fa-chevron-down",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fas fa-chevron-up",
                    attrs: { "aria-hidden": "true" },
                  }),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu dropdown-brands",
          attrs: { id: "dropdown-brands", role: "presentation" },
        },
        [
          _c(
            "div",
            { staticClass: "dropdown-header" },
            [
              _c("div", { staticClass: "dropdown-header-wrap" }, [
                _c("div", { staticClass: "dropdown-header-data" }, [
                  _vm.settings.filterTripsContent.vacationBrand
                    .vacationBrandsDisplayText
                    ? _c("h4", { staticClass: "dropdown-title" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              _vm.settings.filterTripsContent.vacationBrand
                                .vacationBrandsDisplayText
                            ) +
                            "\n\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.settings.filterTripsContent.vacationBrand
                    .vacationBrandsDisplayText
                    ? _c("h4", { staticClass: "dropdown-title" }, [
                        _vm._v("\n\t\t\t\t\t\tVacation Brands\n\t\t\t\t\t"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "brandsmore",
                      attrs: {
                        href: _vm.settings.filterTripsContent.vacationBrand
                          .learnAboutBrandsDisplayLink,
                        "aria-label": "brandsmore",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.settings.filterTripsContent.vacationBrand
                            .learnAboutBrandsDisplayText
                        )
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-header-close" }, [
                  _c(
                    "button",
                    {
                      staticClass: "dropdown-close",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.setFilterVisibility()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "is-sr-only" }, [
                        _vm._v("Close Menu"),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "dropdown-close__icon delete",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "keep-alive",
                [
                  _c("search-brand-filters", {
                    ref: "brandselector",
                    attrs: {
                      filterTripsContent: _vm.settings.filterTripsContent,
                      locked: _vm.settings.filtersLocked,
                      brandsVal: _vm.settings.filtersValues.brandsVal,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-footer search-footer" }, [
            _c("div", { staticClass: "buttonholder search-clear-apply" }, [
              _c(
                "button",
                {
                  staticClass: "btn-clear",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("reset-brand-filters")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tClear Selection\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-apply",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("filter-search")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tApply Selection\n\t\t\t\t")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }