<template>
	<section
		class="hero-carousel"
		:class="!herocarouseldata?.heroWidth ? 'hero-full' : 'container'">
		<carousel
			class="hero-carousel__wrap"
			:items="1"
			:loop="true"
			:nav="false"
			:dots="true"
			:autoplay="true"
			:autoplaySpeed="500"
			:autoplayTimeout="5000">
			<div
				class="hero-carousel__slide"
				v-for="(
					{
						carouselImageUrl,
						carouselText,
						carouselImageAltTag,
						leftFocalPoint,
						topFocalPoint,
					},
					index
				) in herocarouseldata.carouselItems"
				:key="index">
				<div
					class="hero-overlay"
					:style="{ opacity: opacityValue + '%' }"></div>
				<div
					v-if="carouselImageUrl"
					class="hero-carousel__image is-background"
					:style="{
						backgroundImage: 'url(\'' + carouselImageUrl + '\')',
						backgroundPosition:
							'' + leftFocalPoint + ' ' + topFocalPoint + '',
					}"
					:alt="carouselImageAltTag"></div>
				<h1
					v-if="carouselText"
					class="hero-carousel__title"
					:class="!herocarouseldata.heroWidth ? 'container' : ''">
					{{ carouselText }}
				</h1>
			</div>
		</carousel>
	</section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
	name: "heroCarousel",
	data() {
		return {
			opacityValue: 50,
		};
	},
	props: ["herocarouseldata"],
	components: { carousel },
	mounted() {
		this.opacityValue =
			this.herocarouseldata?.opacityHeroValue != ""
				? this.herocarouseldata?.opacityHeroValue
				: this.opacityValue;
	},
};
</script>
