import Vue from 'vue';
import FeaturedParentSection from '../vue-components/featured/featured-parent-section.vue';
function featuredParentSectionView() {
    new Vue({
        el: '#featured-parent-section',
        data () {
            return {
                featuredsection: window.Cms.featuredSection
            };
        },
        components: {
            'featured-parent-section': FeaturedParentSection,
        }
    });
};
module.exports = featuredParentSectionView;