const $ = require('jquery');

function scrollTo() {
    let scrollModifier = 0;
    const subNavClass = 'subnav__link';
    const subNavClasses = subNavClass + ', .js-scroll-link';
    let $root = $('html, body');

    $('.' + subNavClasses).click(function() {
        scrollModifier = 64;
        let href = $.attr(this, 'href');
        let scrollTo =  ($(href).offset().top) - scrollModifier;
     
        $root.animate({
            scrollTop: scrollTo
        }, 500, function () {
            if(history.pushState) {
                history.pushState(null, null, href);
            }
            else {
                location.hash = href;
            }
        });
        return false;
    });
}
module.exports = scrollTo;