<template>
    <div class="dropdown">
        <div class="dropdown-trigger">
            <button class="button" aria-haspopup="true" :class="{'filters-active' : selectedMonths.length, 'no-dropdown' : months.length == 1}" :disabled="apierror">
                <span v-if="!months.length && !apierror"><i class="fas fa-lg fa-spin fa-circle-notch"></i></span>
                <span v-if="months.length == 1" v-text="months.length == 1 ? months[0] : 'Loading'"></span>
                <span v-if="months.length > 1 && !selectedMonths.length">Filter by Start Date</span>
                <span v-if="selectedMonths.length == 1">{{ selectedMonths[0] }}</span>
                <span v-if="selectedMonths.length > 1">{{ selectedMonths.length }} months selected</span>
                <span v-if="months.length > 1" class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
            </button>
        </div>        
        <ul v-if="months.length > 1" class="dropdown-menu dropdown-content" role="presentation">
            <li class="is-flex columns is-marginless has-text-grey-dark is-size-7 has-text-centered">
                <span class="column cursor-pointer clear-all" :class="{'disabled' : !selectedMonths.length}" @click="clearAllMonths()">Clear All</span>
            </li>
            <li v-for="month in months" :key="month.index" class="dropdown-item is-paddingless is-flex" :class="selectedMonths.includes(month) ? 'selected' : ''">
                <label :for="month.index" class="dropdown-label">
                    <input type="checkbox" :id="month.index" :value="month" v-model="selectedMonths" @change="updateSelectedMonths(selectedMonths)">
                    {{ month }}
                </label>          
            </li>
        </ul>
    </div>
</template>
<script>
    import eventBus from '../../../vue-functions/event-bus';
    export default {
        data() {
            return {                    
                selectedMonths: []
            };
        },
        props: ['model', 'months', 'selectedmonths', 'apierror'],
        methods: {
            updateSelectedMonths() {
                this.$emit('update-selected-months-array', this.selectedMonths);
            },
            clearAllMonths(e) {
                this.selectedMonths = [];
                this.$emit('update-selected-months-array', this.selectedMonths);
            }
        }
    }
</script>