import Vue from 'vue';
import FeatureIntroSection from '../vue-components/featured/featured-intro-section.vue';
function featureIntroSectionView() {
    new Vue({
        el: '#featured-intro-section',
        data () {
            return {
                featuredsection: window.Cms.featuredSection
            };
        },
        components: {
            'featured-intro-section': FeatureIntroSection,
        }
    });
};
module.exports = featureIntroSectionView;