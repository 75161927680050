var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { "is-active": _vm.filterVisibility.showDestinationFilter },
    },
    [
      _c("div", { staticClass: "dropdown-trigger" }, [
        _c(
          "a",
          {
            staticClass: "button is-filter",
            class: {
              "is-filter-active": _vm.settings.filtersText.destinationsText,
            },
            attrs: {
              href: "#",
              "aria-haspopup": "true",
              "aria-controls": "dropdown-destination",
              disabled: _vm.settings.loading,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit(
                  "destination-filter-actions",
                  "#dropdown-destination"
                )
              },
            },
          },
          [
            _vm.embeddedsearchcontent &&
            !_vm.settings.filtersText.destinationsText
              ? _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.embeddedsearchcontent.destinationPlaceHolderText
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.settings.filtersText.destinationsText &&
            !_vm.embeddedsearchcontent
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.settings.filterTripsContent.destination
                        .destinationMenuText
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.settings.filtersText.destinationsText
              ? _c("span", [
                  _vm._v(_vm._s(_vm.settings.filtersText.destinationsText)),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.settings.filtersLocked
              ? _c("span", { staticClass: "icon is-small" }, [
                  _c("i", {
                    staticClass: "fas fa-chevron-down",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fas fa-chevron-up",
                    attrs: { "aria-hidden": "true" },
                  }),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu dropdown-destination",
          attrs: { id: "dropdown-destination", role: "presentation" },
        },
        [
          _c("div", { staticClass: "dropdown-header" }, [
            _c("div", { staticClass: "level dropdown-header-wrap" }, [
              _c("div", { staticClass: "is-mobile dropdown-header-data" }, [
                _c(
                  "h2",
                  { staticClass: "dropdown-subheading navbar-item_title" },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(
                          _vm.settings.filterTripsContent.destination
                            .regionDisplayText
                        ) +
                        "\n\t\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-header-close" }, [
                _c(
                  "button",
                  {
                    staticClass: "dropdown-close",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setFilterVisibility()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "is-sr-only" }, [
                      _vm._v("Close Menu"),
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "dropdown-close__icon delete",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "keep-alive",
            [
              _c("search-destination-filters", {
                ref: "destinations",
                attrs: {
                  countriestext:
                    _vm.settings.filterTripsContent.destination
                      .mustSeeCountriesDisplayText,
                  locked: _vm.settings.filtersLocked,
                  searchtype: _vm.settings.currentSearchPrefix,
                  destinations: _vm.settings.currentDestinations,
                  continent: _vm.settings.currentContinent,
                  filterTripsContent: _vm.settings.filterTripsContent,
                  filterActions: _vm.settings.filterActions,
                  currentRegionSelected: _vm.settings.currentContinent,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "search-footer" }, [
            _c("div", { staticClass: "buttonholder search-clear-apply" }, [
              _c(
                "button",
                {
                  staticClass: "btn-clear",
                  attrs: { href: "#" },
                  on: { click: _vm.clearDestinations },
                },
                [_vm._v("\n\t\t\t\t\tClear Selection\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-apply",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleApplyClick(
                        _vm.settings.filterActions.destinationActionClicked
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tApply Selection\n\t\t\t\t")]
              ),
            ]),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }