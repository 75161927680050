var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "secondarySubnav-wrapper" }, [
    _c(
      "div",
      { staticClass: "subnav-list" },
      [
        _c(
          "vue-horizontal",
          {
            staticClass: "subnav-list-wrap horizontal",
            scopedSlots: _vm._u([
              {
                key: "btn-prev",
                fn: function () {
                  return [_c("span", { staticClass: "direction-btn btn-prev" })]
                },
                proxy: true,
              },
              {
                key: "btn-next",
                fn: function () {
                  return [_c("span", { staticClass: "direction-btn btn-next" })]
                },
                proxy: true,
              },
            ]),
          },
          [
            _vm._v(" "),
            _vm._v(" "),
            _vm._l(_vm.datamenu.navItems, function (item, index) {
              return _c("div", { key: index, staticClass: "subnav-listItem" }, [
                _c(
                  "a",
                  {
                    staticClass: "subnav-listItem__link",
                    attrs: {
                      href: "#" + item.url,
                      "data-name": "in-page-menu",
                      "data-target": item.url,
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
              ])
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }