<template>
  <div>
    <div v-if="ubyincluded.length" class="mb-4">
      <h4 class="is-size-6 is-marginless mb-1">
        Included Excursions:
      </h4>
      <div v-for="excursion in ubyincluded" :key="excursion.index">
        <a
          href="#"
          class="itinerary-modal-trigger is-size-6"
          :data-excursion-title="excursion.title"
          :data-excursion-details="excursion.details"
          :class="{
            'no-pointer-events no-underline': !excursion.details,
          }"
          >{{ excursion.title }}</a
        >
      </div>
    </div>
    <div
      v-if="ubyplus.length"
      class="is-inline-block border-1 u-plus-container"
    >
      <span
        class="has-background-black has-text-white p-2 is-block is-size-6 is-flex"
        ><span class="pl-1">U+ EXCURSIONS:</span>
      </span>
      <div class="py-2">
        <span
          v-for="excursion in ubyplus"
          :key="excursion.index"
          class="is-block px-1"
        >
          <a
            href="#"
            class="itinerary-modal-trigger is-size-6 pl-2 pr-3 is-block"
            :data-excursion-title="excursion.title"
            :data-excursion-details="excursion.details"
            :class="{
              'no-pointer-events no-underline': !excursion.details,
            }"
            >{{ excursion.title }}</a
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["ubyincluded", "ubyplus"],
  methods: {},
};
</script>
