var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "small-groups-filter is-flex slider-toggle",
      class: { disabled: _vm.settings.loading },
    },
    [
      _c(
        "label",
        {
          staticClass: "switch inline-center is-relative",
          attrs: { for: "small-groups", "aria-labelledby": "small-groups" },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isSmallGroups,
                expression: "isSmallGroups",
              },
            ],
            attrs: {
              type: "checkbox",
              id: "small-groups",
              name: "small-groups",
              role: "switch",
            },
            domProps: {
              checked: Array.isArray(_vm.isSmallGroups)
                ? _vm._i(_vm.isSmallGroups, null) > -1
                : _vm.isSmallGroups,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.isSmallGroups,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isSmallGroups = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isSmallGroups = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isSmallGroups = $$c
                  }
                },
                function ($event) {
                  return _vm.setSmallGroupsFlag($event)
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "slider round" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "inline-center",
          class: { filterChecked: _vm.filterCheckbox },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.settings.filterTripsContent.smallGroupsDisplayText
                  ? _vm.settings.filterTripsContent.smallGroupsDisplayText
                  : "Small Groups"
              ) +
              "\n\t"
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }