<template>
  <div class="map-expand-button">
    <a @click.prevent="toggleModal()" href="#" class="level expand" aria-label="map Expand">
      <span class="icon expand-btn">
        <i class="fas fa-expand"></i>
      </span>
    </a>
  </div>
</template>
<script>
import EventBus from "../../vue-functions/event-bus";
export default {
  methods: {
    toggleModal() {
      EventBus.$emit("TOGGLE_MAP_MODAL");
    },
  },
};
</script>
