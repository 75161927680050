<template>
  <div class="container">
    <h3 class="section-header">Ship Details</h3>
    <div class="row is-marginless">
      <div class="col s12 ships-tabs-container">
        <ul class="tabs">
          <li
            v-for="(ship, shipTabIndex) in shipsmodel.ships"
            :key="ship.shipTabIndex"
            class="tab"
          >
            <a
              class="button is-subtle is-level"
              @click.prevent="initShipCarousel(shipTabIndex, 0)"
              :class="shipTabIndex == 0 ? 'active' : ''"
              :href="'#ship-' + (shipTabIndex + 1)"
              >{{ ship.title }}</a
            >
          </li>
        </ul>
      </div>
      <div
        v-for="(ship, shipIndex) in shipsmodel.ships"
        :key="ship.shipIndex"
        :id="'ship-' + (shipIndex + 1)"
        :class="shipIndex == 0 ? 'active' : ''"
        class="col s12 no-padding"
      >
        <div class="ship-content-container">
          <ul class="tabs sub-tabs">
            <li
              v-for="(room, roomTabIndex) in ship.deckPlans[0].staterooms"
              :key="room.roomTabIndex"
              class="tab"
            >
              <a
                class="button btn-small is-filter is-level"
                @click.prevent="initShipCarousel(shipIndex, roomTabIndex)"
                :class="roomTabIndex == 0 ? 'active' : ''"
                :href="'#shiproom-' + shipIndex + '-' + roomTabIndex"
                >{{ room.stateroomName }}</a
              >
            </li>
          </ul>
          <div
            v-for="(room, roomIndex) in ship.deckPlans[0].staterooms"
            :key="room.roomIndex"
            class="room"
            :class="roomIndex == 0 ? 'active' : ''"
            :id="'shiproom-' + shipIndex + '-' + roomIndex"
          >
            <div class="room-container">
              <div class="carousel-container col s12 l7">
                <div class="carousel carousel-slider ship-carousel">
                  <a
                    href="#"
                    v-for="(photo, photoIndex) in room.photos"
                    :key="photo.photoIndex"
                    class="carousel-item"
                    :class="photoIndex == 0 ? 'is-active' : ''"
                  >
                    <img
                      class="is-background stateroom"
                      :src="photo"
                      :alt="room.stateroomName + ' image ' + (photoIndex + 1)"
                    />
                  </a>
                  <a
                    @click.prevent="showFloorPlanModal(room.floorPlanImage)"
                    class="carousel-item floorplan"
                    :class="!room.photos ? 'is-active' : ''"
                  >
                    <img
                      class="is-background stateroom"
                      :src="room.floorPlanImage"
                      :alt="room.stateroomName + ' floor plan image'"
                    />
                  </a>
                  <a
                    v-if="!room.photos && !room.floorPlanImage"
                    class="carousel-item image-placeholder"
                  ></a>
                </div>
                <div
                  v-if="room.photos || (room.photos && room.floorPlanImage)"
                  class="carousel-trigger prev"
                >
                  <i class="fa fa-chevron-left fa-2x"></i>
                </div>
                <div
                  v-if="room.photos || (room.photos && room.floorPlanImage)"
                  class="carousel-trigger next"
                >
                  <i class="fa fa-chevron-right fa-2x"></i>
                </div>
              </div>
              <div class="room-description-container col s12 l5">
                <span class="room-header">
                  {{ room.stateroomName }}
                </span>
                <div class="room-description" v-html="room.description"></div>
              </div>
            </div>
          </div>
          <div
            v-for="(deckPlan, deckPlanIndex) in ship.deckPlans"
            :key="deckPlanIndex"
            class="ship-map"
          >
            <img :src="deckPlan.image" :alt="ship.title" />
          </div>
        </div>
      </div>
    </div>
    <div
      id="ship-modal"
      class="modal ship-modal"
      :class="shipModalActive ? 'is-active' : ''"
    >
      <div
        class="modal-background"
        @click.prevent="closeFloorPlanModal()"
      ></div>
      <div class="modal-card">
        <div
          class="ship-modal__image-container background-image-center contain"
          :style="{ backgroundImage: 'url(\'' + shipModalImgHref + '\')' }"
        ></div>
      </div>
      <div class="modal-card-close" @click.prevent="closeFloorPlanModal()">
        <i class="fa fa-times fa-3x"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      shipModalActive: false,
      shipModalImgHref: "",
    };
  },
  props: ["shipsmodel"],
  methods: {
    initShipCarousel(shipId, roomId) {
      let shipRoomId = "#shiproom-" + shipId + "-" + roomId;
      let shipCarousel = $(shipRoomId).find(".ship-carousel");
      shipCarousel.carousel({
        fullWidth: true,
        indicators: true,
        duration: 0,
      });
      shipCarousel.siblings(".prev").click(function() {
        shipCarousel.carousel("prev");
      });
      shipCarousel.siblings(".next").click(function() {
        shipCarousel.carousel("next");
      });
    },
    showFloorPlanModal(href) {
      this.shipModalActive = true;
      this.shipModalImgHref = href;
    },
    closeFloorPlanModal() {
      this.shipModalActive = false;
    },
  },
  mounted() {
    this.initShipCarousel(0, 0);
  },
};
</script>
