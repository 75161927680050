var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "datesprices section" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "level dates-prices-header main-header is-vcentered" },
        [
          _c("div", { staticClass: "dates-prices-header__title" }, [
            _c("h2", { staticClass: "header" }, [
              _vm._v(_vm._s(_vm.model.labels.dateRatesTitle)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dates-prices-results-count",
                class: { disabled: _vm.localappdata.apiError },
              },
              [
                _vm._v("\n          Results:\n          "),
                !_vm.localappdata.dataAll.length && !_vm.localappdata.apiError
                  ? _c("span", [_vm._v("loading...")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.localappdata.dataAll.length
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.localappdata.visibleList.length) +
                          " /\n            " +
                          _vm._s(_vm.localappdata.dataAll.length)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.localappdata.apiError
                  ? _c("span", [_vm._v("0")])
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "level-right" }, [
            _vm.localappdata.dataAll.length !==
            _vm.localappdata.dataOnlyAvailableTotal
              ? _c(
                  "div",
                  {
                    staticClass:
                      "level-item available-toggle dates-prices-toggle dates-prices-header__item is-3-tablet",
                  },
                  [
                    _c("dates-prices-filter-available", {
                      attrs: { model: _vm.model },
                      on: {
                        "update-only-available-flag": function ($event) {
                          return _vm.updateShowAllDataFlag($event)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "level-item dates-prices-filters" },
              [
                _c("dates-prices-filter-months", {
                  attrs: {
                    model: _vm.model,
                    months: _vm.localappdata.availableMonths,
                    apierror: _vm.localappdata.apiError,
                    selectedmonths: _vm.localappdata.userActions.selectedMonths,
                  },
                  on: {
                    "update-selected-months-array": function ($event) {
                      return _vm.updateSelectedMonths($event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("dates-prices-filter-ships", {
                  attrs: {
                    model: _vm.model,
                    ships: _vm.localappdata.availableShips,
                    shipsmatch: _vm.localappdata.allShipsMatch,
                    apierror: _vm.localappdata.apiError,
                    selectedship: _vm.localappdata.userActions.selectedShip,
                  },
                  on: {
                    "update-selected-ship": function ($event) {
                      return _vm.updateSelectedShip($event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("dates-prices-filter-routes", {
                  attrs: {
                    model: _vm.model,
                    routes: _vm.localappdata.availableRoutes,
                    apierror: _vm.localappdata.apiError,
                    selectedroute: _vm.localappdata.userActions.selectedRoute,
                  },
                  on: {
                    "update-selected-route": function ($event) {
                      return _vm.updateSelectedRoute($event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dates-prices-item-container is-relative" },
      [
        _vm._l(
          _vm.localappdata.visibleList.slice(0, _vm.localappdata.maxShownItems),
          function (item) {
            return _c("vue-uniworld-dates-prices-row", {
              key: item.index,
              attrs: {
                model: _vm.model,
                row: item,
                raqmodel: _vm.raqmodel,
                telNumber: _vm.telNumber,
                localappdata: _vm.localappdata,
              },
              on: {
                "update-current-modal-content": function ($event) {
                  return _vm.updateCurrentDealsHtml($event)
                },
              },
            })
          }
        ),
        _vm._v(" "),
        _vm.localappdata.isLoadingFresh
          ? _c("div", { staticClass: "dates-prices-item-loader" }, [
              !_vm.localappdata.apiError
                ? _c("div", { staticClass: "loader-bar inverse" })
                : _vm._e(),
              _vm._v(" "),
              _vm.localappdata.apiError
                ? _c(
                    "h3",
                    { staticClass: "has-text-centered has-text-white" },
                    [
                      _c("br"),
                      _vm._v(
                        "Trips are unable to load at the moment, please refresh the page\n        or try again later.\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.localappdata.maxShownItems < _vm.localappdata.visibleList.length
          ? _c(
              "div",
              { staticClass: "has-text-centered datesprices-button-holder" },
              [
                _c("button", {
                  staticClass: "button is-subtle cta",
                  class: { "is-loading": _vm.localappdata.isloadingmore },
                  domProps: {
                    textContent: _vm._s(
                      !_vm.localappdata.isloadingmore ? "Show more" : ""
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showmoreclicked(
                        _vm.localappdata.showAllDataFlag
                      )
                    },
                  },
                }),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    !_vm.localappdata.isLoadingFresh && !_vm.localappdata.visibleList.length
      ? _c(
          "div",
          { staticClass: "has-text-centered datesprices-noresults-holder" },
          [
            _vm.localappdata.dataAll.length !==
            _vm.localappdata.dataOnlyAvailableTotal
              ? _c("h3", [
                  _vm._v(
                    "\n      No results. Try switching the 'Available only' toggle\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.localappdata.dataAll.length ==
            _vm.localappdata.dataOnlyAvailableTotal
              ? _c("h3", [
                  _vm._v("\n      No results. Try removing some filters\n    "),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal current-deals-modal wide-modal",
        class: _vm.modalIsActive ? "is-active" : "",
        attrs: { id: "currentDealsModal" },
      },
      [
        _c("div", { staticClass: "modal-background" }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-card" }, [
          _c("div", { staticClass: "modal-form" }, [
            _c("div", { staticClass: "modal-card-head" }, [
              _c("p", { staticClass: "modal-card-title is-size-6" }, [
                _vm._v("Current Deals"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal-card-close",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.closeDealsModal()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-times fa-lg" })]
              ),
            ]),
            _vm._v(" "),
            _c("form", [
              _c("section", { staticClass: "modal-card-body" }, [
                _c("div", {
                  staticClass:
                    "modal-card-content current-deals-modal-content has-text-black is-size-6",
                  domProps: { innerHTML: _vm._s(_vm.currentModalContent) },
                }),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }