const $ = require('jquery');
function backToTop() {
    
    function showBackToTopButton() {
        const windowHeight = $(window).height();
        let scrollTop = $(window).scrollTop();
        const backToTopButton = $('.back-to-top');
        const htmlBody = $("html, body");

        if (scrollTop >= windowHeight) {
            backToTopButton.addClass('visible');
        } else {
            backToTopButton.removeClass('visible');
        }

        backToTopButton.unbind().click(function() {
            htmlBody.animate({ scrollTop: 0 }, '300');
            backToTopButton.removeClass('visible');
            return false;
        });
    }
    
    $(window).scroll(function() {
        showBackToTopButton();
    });    

}
module.exports = backToTop;