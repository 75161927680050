var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "level-right columns is-vcentered" }, [
    _c("div", { staticClass: "level-item field" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.showAllDataFlag,
            expression: "showAllDataFlag",
          },
        ],
        staticClass: "switch is-large is-rtl is-rounded",
        attrs: { id: "showAllDataFlag", type: "checkbox", name: "showAllData" },
        domProps: {
          checked: Array.isArray(_vm.showAllDataFlag)
            ? _vm._i(_vm.showAllDataFlag, null) > -1
            : _vm.showAllDataFlag,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.showAllDataFlag,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.showAllDataFlag = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.showAllDataFlag = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.showAllDataFlag = $$c
              }
            },
            function ($event) {
              return _vm.updateShowAllDataFlag(_vm.showAllDataFlag)
            },
          ],
        },
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "is-relative", attrs: { for: "showAllDataFlag" } },
        [_vm._v("Available only")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }