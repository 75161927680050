var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.brandsdata, function (brandSection) {
      return _c("section", { staticClass: "brands subsection" }, [
        _c("div", { staticClass: "site-section-header" }, [
          _c("div", { staticClass: "site-section-headerTop" }, [
            brandSection.title
              ? _c("h2", { staticClass: "site-section-title" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(brandSection.title) + "\n\t\t\t\t"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            brandSection.subTitle
              ? _c("p", { staticClass: "site-section-subtitle" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(brandSection.subTitle) +
                      "\n\t\t\t\t"
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "brands__cta" }, [
            _c(
              "a",
              {
                staticClass: "button is-primary is-fixed-width cta",
                attrs: {
                  "data-type": "general interaction",
                  "data-name": "button",
                  target: "_self",
                  href: brandSection.linkUrl,
                },
              },
              [_vm._v(_vm._s(brandSection.linkName))]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "brands__content" },
          _vm._l(brandSection.brands, function (brand) {
            return _c(
              "div",
              {
                staticClass: "brands__content-item is-full-mobile",
                attrs: { "data-type": "navigation", "data-name": "brand-pod" },
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href: brand.brandLinkUrl,
                      "aria-label": brand.brandLinkName,
                      target: "_self",
                    },
                  },
                  [
                    _c("div", { staticClass: "brands__content-section" }, [
                      _c("img", {
                        staticClass: "brands__content-image",
                        attrs: {
                          src: brand.brandLogoUrl,
                          alt: brand.brandLogoAltText
                            ? brand.brandLogoAltText
                            : brand.brandLinkName,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary brands__content-cta",
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(brandSection.hoverButton) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    brand.brandDescriptionText
                      ? _c("div", { staticClass: "brands__content-copy" }, [
                          _c("p", [_vm._v(_vm._s(brand.brandDescriptionText))]),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }