var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.raqmodel.tour.isUniworldTour
      ? _c("div", { staticClass: "container" }, [
          _c("h3", { staticClass: "section-header" }, [
            _vm._v(_vm._s(_vm.dictionarymodel.whatsIncluded)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "highlights is-relative" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "included-column" },
                [
                  _vm._l(
                    _vm.includedmodel.whatsIncludedCol1,
                    function ({ type, highlitghts }, index) {
                      return _c(
                        "ul",
                        { key: index },
                        [
                          highlitghts[0].imageUrl
                            ? _c(
                                "li",
                                { staticClass: "included-image-container" },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "imageurl included-image" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: highlitghts[0].imageUrl,
                                          alt: "What's Included image",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          type
                            ? _c("li", [
                                _c("p", { staticClass: "subtitle" }, [
                                  _c("b", [_vm._v(_vm._s(type))]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(highlitghts, function ({ title, text }, ind) {
                            return _c("li", {
                              key: ind,
                              staticClass: "included-list-item",
                              domProps: { innerHTML: _vm._s(text) },
                            })
                          }),
                        ],
                        2
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.includedmodel.whatsIncludedCol2,
                    function ({ type, highlitghts }, index) {
                      return _c(
                        "ul",
                        { key: index },
                        [
                          highlitghts[0].imageUrl
                            ? _c(
                                "li",
                                { staticClass: "included-image-container" },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "imageurl included-image" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: highlitghts[0].imageUrl,
                                          alt: "What's Included image",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          type
                            ? _c("li", [
                                _c("p", { staticClass: "subtitle" }, [
                                  _c("b", [_vm._v(_vm._s(type))]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(highlitghts, function ({ title, text }, ind) {
                            return _c("li", {
                              key: ind,
                              staticClass: "included-list-item",
                              domProps: { innerHTML: _vm._s(text) },
                            })
                          }),
                        ],
                        2
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.raqmodel.tour.isUniworldTour
      ? _c("div", [
          _c("div", { staticClass: "included__wrapper" }, [
            _c("h3", { staticClass: "included__wrapper-title" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.dictionarymodel.whatsIncluded) +
                  "\n\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "included__box",
                class: {
                  columnOne:
                    _vm.includedmodel.whatsIncludedCol1.length === 0 ||
                    _vm.includedmodel.whatsIncludedCol2.length == 0,
                },
              },
              [
                _vm.includedmodel.whatsIncludedCol1.length > 0
                  ? _c(
                      "div",
                      { staticClass: "included__box-column" },
                      _vm._l(
                        _vm.includedmodel.whatsIncludedCol1,
                        function ({ highlitghts }, index) {
                          return _c("div", [
                            _c(
                              "ul",
                              _vm._l(
                                highlitghts,
                                function ({ title, text }, index) {
                                  return _c("li", [
                                    text
                                      ? _c("i", {
                                          staticClass: "fas fa-check",
                                          attrs: { "aria-hidden": "true" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    title
                                      ? _c("h4", { staticClass: "subtitle" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(title) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: { innerHTML: _vm._s(text) },
                                    }),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.includedmodel.whatsIncludedCol2.length > 0
                  ? _c(
                      "div",
                      { staticClass: "included__box-column" },
                      _vm._l(
                        _vm.includedmodel.whatsIncludedCol2,
                        function ({ highlitghts }, index) {
                          return _c("div", [
                            _c(
                              "ul",
                              _vm._l(
                                highlitghts,
                                function ({ title, text }, index) {
                                  return _c("li", [
                                    text
                                      ? _c("i", {
                                          staticClass: "fas fa-check",
                                          attrs: { "aria-hidden": "true" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    title
                                      ? _c("h4", { staticClass: "subtitle" }, [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(title) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: { innerHTML: _vm._s(text) },
                                    }),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }