import Vue from 'vue';
import axios from 'axios';

import VueDeals from '../vue-components/deals/deals-list.vue';
import VueTermsModal from '../vue-components/modals/terms-modal.vue';

function websiteDeals() {
    new Vue({
        el: '#app-deals',
        data: window.Cms.deals,
        components: {
            'vue-deals': VueDeals,
            'vue-terms-modal': VueTermsModal
            
        },
        computed: {
            showMore: function() {
                return this.deals.length < this.total;
            }
        },
        methods:{
            loadMore() {
                let results = [];
                this.currentPage ++;
                this.isLoading = true; 
                this.getDeals(this.currentPage).then(data =>{
                    if(data != ''){
                        this.total = data.total;
                        results.push(data.deals);
                        if(results.length > 0) {
                            let combinedDeals = this.deals.concat(results[0]);
                            this.deals = combinedDeals;
                        } 
                    }
                    this.isLoading = false;
                });
            },        
            getDeals: function (page) {
                let query = this.api + `?page=${page}`;
                return axios.get(query)
                    .then(response => {
                            return(response.data);
                        }).catch(response => {
                            //console.log(response.data);
                            return '';
                        });
            }
        },
        beforeCreate: function () {
            if(typeof this.deals === 'undefined')
            {
                this.deals = {};
            }
        }
    });
};
module.exports = websiteDeals;