function resetSearch() {
    this.$data.searchScrollPosition = 0;
    this.$data.settings.loading = true;
    this.$data.settings.showMore = false;
    this.$data.settings.showMoreLoading = false;
    this.$data.currentPage = 0;
    this.$data.settings.resultsInView = 0;
    if(this.$data.settings.parentSearch) {
        this.doSearchScroll();
    }
}
module.exports = resetSearch;