var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "portal",
    {
      attrs: {
        name: "quote-ftc-footer-button",
        "target-el": "#footerbar_cta",
        slim: "",
      },
    },
    [
      _c("a", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.labels.button,
            expression: "labels.button",
          },
        ],
        staticClass: "button is-secondary modal-button",
        attrs: {
          "data-type": "booking interest",
          "data-name": "free-consultation",
          href: "#",
        },
        domProps: { textContent: _vm._s(_vm.labels.button) },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleModal()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }