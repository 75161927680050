<template>
  <section class="tripoptions-section section" id="trip-options-section">
    <div class="">
      <div>
        <tour-filters-view
          :tourfilters="optionFiltersOnLoad"
          @send-filters-data="filtersSelectedData"
          :show-loader="showLoader"
          :filter-configuration="filterConfiguration"
          :touroptions="tripOptionsData"
        ></tour-filters-view>
      </div>
      <tour-options-view
        :touroptions="tourVariants ?? tripOptionsData"
        :count="tripDisplayLimit"
        @tourId="updateTourId"
        :tour-loading="tourLoading"
      ></tour-options-view>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import TourFilters from "./tourFilters.vue";
import TourOptions from "./tourOptions.vue";
import * as searchConstants from "../../search-constants.js";
export default {
  props: [
    "selectedOption",
    "tripOptions",
    "optionFiltersOnLoad",
    "searchFilterUrl",
    "filterConfiguration",
    "tourLoading"
  ],
  data() {
    return {
      tourIdSelected: "",
      tour: {},
      tourfilters: null,
      tourVariants: null,
      tripDisplayLimit: searchConstants.TRIPDISPLAYLIMIT,
      showLoader: false,
    };
  },
  components: {
    "tour-filters-view": TourFilters,
    "tour-options-view": TourOptions,
  },
  computed: {
    activeTabComponent: function () {
      return "tab-" + this.activeTab;
    },
    tripOptionsData: function () {
      return [...this.tripOptions];
    },
  },

  methods: {
    isTypeOptions(obj,tagsArray){
    let objLength = Object.keys(obj).length;
  let resultLength = tagsArray.filter(({type,options})=>options.includes(obj[type])).length;
    return objLength ==resultLength;
},
    getTourOptions(searchData) {
      this.tourVariants = [...this.tripOptions].filter(({tags})=>this.isTypeOptions(searchData,tags));
    },
    filtersSelectedData(data) {
      this.getTourOptions(data);
    },
    updateTourId(id) {
      this.tourIdSelected = id;
      this.$emit("update-tour", this.tourIdSelected);
    },
  },
};
</script>
