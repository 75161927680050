<template>
  <section class="search-brand-container">
    <div class="check-link-holder search-brand-wrapper">
      <a
        class="check-link search-brand-item"
        v-for="(brand, brandIndex) in filterTripsContent.vacationBrand.brands"
        :data-brand-name="brand.brandId"
        :value="brand.brandName"
        :key="'brand' + brandIndex"
        :class="{ 'is-active': isActive(brand) }"
        @click.prevent="selectedBrands(brand)"
        :aria-label="brand.brandName"
        href="#"
      >
        <div
          :class="{ 'is-square': brand.isSquare }"
          class="background-image-center contain logo-container"
          :style="{
            backgroundImage:
              'url(\'' + constants.LOCATION_STRING + brand.brandLogoUrl + '\')',
          }"
        ></div>
        <span class="icon icon-check is-small">
          <i class="fas fa-check" aria-hidden="true"></i>
        </span>
      </a>
    </div>
  </section>
</template>
<script>
import * as searchConstants from "../../search-constants.js";
import eventBus from "../../vue-functions/event-bus.js";

export default {
  props: ["filterTripsContent", "locked", "brandsVal"],
  data: function() {
    return {
      buttonActiveClass: "is-active",
      brandsSelected: [],
      constants: searchConstants,
    };
  },
  watch: {
    brandsVal(val) {
      this.brandsSelected = val;
    },
  },
  methods: {
    isActive(brand) {
      let result = this.brandsSelected.includes(brand.brandName);
      return result;
    },
    selectedBrands(brand) {
      if (this.brandsSelected.includes(brand.brandName)) {
        let spliceKey = this.brandsSelected.indexOf(brand.brandName);
        this.brandsSelected.splice(spliceKey, 1);
      } else {
        this.brandsSelected.push(brand.brandName);
      }
      this.$parent.$emit("get-selected-brands", this.brandsSelected);
    },
  },
  created() {
    eventBus.$on("brands-clear-values", () => {
      this.clearValues();
    });
    //this.brandsSelected = this.brandsVal;
  },
};
</script>
