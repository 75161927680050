var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown",
      class: { "is-active": _vm.filterVisibility.showDurationFilter },
    },
    [
      _c("div", { staticClass: "dropdown-trigger" }, [
        _c(
          "a",
          {
            staticClass: "button is-filter",
            class: {
              "is-filter-active":
                _vm.settings.filtersText.monthsText ||
                _vm.settings.filtersText.durationText,
            },
            attrs: {
              "aria-haspopup": "true",
              "aria-controls": "dropdown-date",
              href: "#",
              disabled: _vm.settings.loading,
            },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("duration-filter-actions", "#dropdown-date")
              },
            },
          },
          [
            _c("div", [
              _vm.embeddedsearchcontent &&
              !_vm.settings.filtersText.monthsText &&
              !_vm.settings.filtersText.durationText
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.embeddedsearchcontent.datesPlaceHolderText
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.settings.filtersText.monthsText &&
              !_vm.settings.filtersText.durationText &&
              !_vm.embeddedsearchcontent
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm.settings.filterTripsContent.dateMenuText)
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.settings.filtersText.monthsText &&
              !_vm.settings.filtersText.durationText
                ? _c("span", [
                    _vm._v(_vm._s(_vm.settings.filtersText.monthsText)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.settings.filtersText.durationText &&
              !_vm.settings.filtersText.monthsText
                ? _c("span", [
                    _vm._v(_vm._s(_vm.settings.filtersText.durationText)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.settings.filtersText.monthsText &&
              _vm.settings.filtersText.durationText
                ? _c("span", { staticClass: "duration-filter-multi-values" }, [
                    _vm._v(_vm._s(_vm.settings.filtersText.monthsText) + ", "),
                    _c("br"),
                    _vm._v(
                      _vm._s(_vm.settings.filtersText.durationText) +
                        "\n\t\t\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.settings.filtersLocked
                ? _c("span", { staticClass: "icon is-small" }, [
                    _c("i", {
                      staticClass: "fas fa-chevron-down",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fas fa-chevron-up",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu dropdown-date",
          attrs: { id: "dropdown-date", role: "presentation" },
        },
        [
          _c(
            "div",
            { staticClass: "dropdown-header" },
            [
              _c("div", { staticClass: "dropdown-header-wrap" }, [
                _c("div", { staticClass: "dropdown-header-close" }, [
                  _c(
                    "button",
                    {
                      staticClass: "dropdown-close",
                      on: { click: _vm.setFilterVisibility },
                    },
                    [
                      _c("span", { staticClass: "is-sr-only" }, [
                        _vm._v("Close Menu"),
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "dropdown-close__icon delete",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "keep-alive",
                [
                  _c("search-calendar", {
                    ref: "monthpicker",
                    attrs: { urlmonths: _vm.settings.filtersValues.monthsVal },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rangesliderholder" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("search-duration-slider", {
                    ref: "durationslider",
                    attrs: {
                      dslider: _vm.filterVisibility.showDurationFilter,
                      minval: _vm.settings.filtersValues.minDurationVal,
                      maxval: _vm.settings.filtersValues.maxDurationVal,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-footer search-footer" }, [
            _c("div", { staticClass: "buttonholder search-clear-apply" }, [
              _c(
                "button",
                {
                  staticClass: "btn-clear",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$emit("reset-duration-filters")
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tClear Selection\n\t\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-apply",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleApplyClick(
                        _vm.settings.filterActions.durationActionClicked
                      )
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t\tApply Selection\n\t\t\t\t")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "level-left" }, [
      _c("h2", { staticClass: "dropdown-subheading" }, [
        _vm._v("\n\t\t\t\t\t\tDuration of Vacation\n\t\t\t\t\t"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }