function resetAllFilters(clearSearchField = false, callNewSearch = false) {
    // resets the search position, and initial vars
    this.resetSearch();
    this.resetDurationFilters();
    this.resetBrandFilters();
    this.resetTypeFilters();
    this.resetDestinationFilters();
    this.resetTypeFilters();
    this.resetPriceSlider();
    this.setFilterVisibility();
    this.resetSmallGroupsFlag();
    this.resetSpecialOffersFlag();
    // if there is a param passed to the function here, clear the text field 
    // this allows for doSearch to reset filters, without clearing the search field for the app.
    if(clearSearchField && this.$data.settings.parentSearch) {
        
        this.$refs.searchfield.resetFreeTextField();
    }
    if(callNewSearch) {
        this.filterSearch();
    }
}
module.exports = resetAllFilters;