var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.showRow
      ? _c("div", { staticClass: "dates-prices-table-row" }, [
          _c(
            "div",
            {
              staticClass:
                "dates-prices-table-col deals-prices-start-date item-col",
            },
            [
              _c("div", { staticClass: "dates-prices-item-wrap" }, [
                _c("span", { staticClass: "mobile-label" }, [
                  _vm._v("Start Date"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "item-text",
                  domProps: { textContent: _vm._s(_vm.row.startDate) },
                }),
              ]),
              _vm._v(" "),
              !_vm.model.labels.hideEndDateHeading
                ? _c(
                    "p",
                    { staticClass: "arrow", attrs: { "aria-hidden": "true" } },
                    [
                      _c("span", { staticClass: "icon" }, [
                        _c("i", { staticClass: "fas fa-arrow-right" }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          !_vm.model.labels.hideEndDateHeading
            ? _c(
                "div",
                {
                  staticClass:
                    "dates-prices-table-col deals-prices-end-date item-col",
                },
                [
                  _c("div", { staticClass: "dates-prices-item-wrap" }, [
                    _c("span", { staticClass: "mobile-label" }, [
                      _vm._v("End Date"),
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "item-text",
                      domProps: { textContent: _vm._s(_vm.row.endDate) },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.model.labels.hideDealsColumn
            ? _c(
                "div",
                {
                  staticClass:
                    "dates-prices-table-col dates-prices-current-deals item-col",
                },
                [
                  _c(
                    "div",
                    { staticClass: "dates-prices-item-wrap" },
                    [
                      _c("span", { staticClass: "mobile-label" }, [
                        _vm._v("Deals"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.deals, function (deal, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "item-text" },
                          [_vm._v("\n          " + _vm._s(deal) + "\n        ")]
                        )
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.model.labels.hideDepartureStatusColumn
            ? _c(
                "div",
                {
                  staticClass:
                    "dates-prices-table-col dates-prices-departure-deals",
                },
                [
                  _c("div", { staticClass: "dates-prices-item-wrap" }, [
                    _c("span", { staticClass: "mobile-label" }, [
                      _vm._v("Departure Status"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "departure-icons" }, [
                      _vm.row.definiteDeparture
                        ? _c(
                            "span",
                            {
                              staticClass: "fa-stack depart-status tooltip",
                              class: {
                                "no-tooltip":
                                  !_vm.model.labels.tasDepartureStatusTooltip,
                              },
                              attrs: {
                                "data-tooltip-text":
                                  _vm.model.labels.tasDepartureStatusTooltip,
                                "data-tooltip-position": "top",
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-circle fa-stack-1x fa-sm definite-departure-icon",
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa fa-shield-alt fa-stack-1x fa-lg definite-departure-icon",
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa fa-shield-alt fa-stack-1x fa-lg fa-flip-horizontal definite-departure-icon",
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass:
                                  "fa fa-check fa-stack-1x fa-xs has-text-white",
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.row.tags && _vm.row.tags.includes("Small Group")
                        ? _c(
                            "div",
                            {
                              staticClass: "mr-1 tooltip",
                              class: {
                                "no-tooltip":
                                  !_vm.model.labels.smallGroupsToolTip,
                              },
                              attrs: {
                                "data-tooltip-text":
                                  _vm.model.labels?.smallGroupsToolTip,
                                "data-tooltip-position": "top",
                              },
                            },
                            [_c("span", { staticClass: "small-groups-icon" })]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.localappdata.siteDiscount
            ? _c(
                "div",
                {
                  staticClass:
                    "dates-prices-table-col dates-prices-discount-price item-col",
                },
                [
                  _c("div", { staticClass: "dates-prices-item-wrap" }, [
                    _vm.raqmodel.loginForPriceModel.isEnabled &&
                    !_vm.raqmodel.loginForPriceModel.isLoggedIn
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "dates-prices-login-for-price-call-to-action",
                          },
                          [
                            _c("span", { staticClass: "mobile-label" }, [
                              _vm._v("Exclusive Costco Member Price"),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "logo-image",
                              attrs: {
                                src: _vm.raqmodel.loginForPriceModel.logoUrl,
                                alt: _vm.raqmodel.loginForPriceModel
                                  .logoAltText,
                              },
                            }),
                            _c(
                              "a",
                              {
                                staticClass: "login-link customer-price-link",
                                attrs: {
                                  href: _vm.raqmodel.loginForPriceModel
                                    .loginUrl,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.dataLayerLoginForPriceClicked()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.raqmodel.loginForPriceModel.text) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.raqmodel.loginForPriceModel.isEnabled ||
                    (_vm.raqmodel.loginForPriceModel.isEnabled &&
                      _vm.raqmodel.loginForPriceModel.isLoggedIn)
                      ? _c(
                          "p",
                          { staticClass: "mobile-price-header tablet-visible" },
                          [
                            _c("small", [
                              _c("em", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.model.labels.exclusivePriceLabel
                                  ),
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.raqmodel.loginForPriceModel.isEnabled ||
                    (_vm.raqmodel.loginForPriceModel.isEnabled &&
                      _vm.raqmodel.loginForPriceModel.isLoggedIn)
                      ? _c("strong", {
                          staticClass: "exclusive-member-price",
                          domProps: {
                            textContent: _vm._s(_vm.discountedPriceFlag),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.model.labels.hideCurrentDealPriceColumn
            ? _c(
                "div",
                {
                  staticClass:
                    "dates-prices-table-col dates-prices-current-deals-price item-col",
                },
                [
                  _c("div", { staticClass: "dates-prices-item-wrap" }, [
                    _c("span", { staticClass: "mobile-label" }, [
                      _vm._v("Price with Current Deals"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "is-inline-block item-text" }, [
                      _vm.row.currentDeals && _vm.row.price != ""
                        ? _c("div", {
                            staticClass: "price",
                            domProps: {
                              textContent: _vm._s(_vm.currentDealPriceFlag),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.row.currentDeals &&
                      _vm.row.originalPrice != _vm.row.price &&
                      _vm.row.price != ""
                        ? _c("div", {
                            staticClass: "price",
                            domProps: {
                              textContent: _vm._s(_vm.currentDealPriceFlag),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.row.price != "" &&
                      _vm.row.originalPrice != _vm.row.price
                        ? _c("div", {
                            class: {
                              strikethrough: !(
                                _vm.row.originalPrice == null ||
                                _vm.row.originalPrice.trim() === ""
                              ),
                            },
                            domProps: {
                              textContent: _vm._s(_vm.row.originalPrice),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.row.currentDeals &&
                      (_vm.row.originalPrice == _vm.row.price ||
                        (_vm.row.price == "" && _vm.row.originalPrice != ""))
                        ? _c("div", {
                            staticClass: "price",
                            domProps: {
                              textContent: _vm._s(_vm.row.originalPrice),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.row.currentDeals &&
                      _vm.row.price == "" &&
                      _vm.row.originalPrice != ""
                        ? _c("div", {
                            staticClass: "price",
                            domProps: {
                              textContent: _vm._s(_vm.row.originalPrice),
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dates-prices-table-col dates-prices-get-quote" },
            [
              _c("quote-raq-button", {
                attrs: {
                  tour: _vm.raqmodel.tour,
                  labels: _vm.raqmodel.labels,
                  tourselecteddate: _vm.row.startDate,
                  tourdepartureid: _vm.row.departureId,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.model.labels.canBook &&
          !_vm.model.labels.isCostco &&
          !_vm.raqmodel.tour.isCmsTour
            ? _c(
                "div",
                {
                  staticClass:
                    "dates-prices-table-col dates-prices-get-quote item-col book-now",
                },
                [
                  _c("book-button", {
                    attrs: {
                      booknowurl: _vm.row.bookNowUrl,
                      labels: _vm.model.labels,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.model.labels.canBook &&
          _vm.model.labels.isCostco &&
          _vm.model.labels.isLoggedIn &&
          !_vm.model.labels.isGB &&
          _vm.model.labels.membershipDetails &&
          !_vm.raqmodel.tour.isCmsTour
            ? _c(
                "div",
                {
                  staticClass:
                    "dates-prices-table-col dates-prices-get-quote item-col book-now",
                },
                [
                  _c("book-button", {
                    attrs: {
                      booknowurl: _vm.row.bookNowUrl,
                      labels: _vm.model.labels,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }