<template>
    <div class="modal modal-geq" id="geqmodalsite" :class="{ 'is-active': active }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <div class="modal-form">
                <div class="modal-card-head">
                    <p class="modal-card-title" v-text="content.title"></p>
                </div>
                <form @submit.prevent="closeForm">
                    <section class="modal-card-body">
                        <h2 v-text="content.heading"></h2>
                        <div v-html="content.content1"></div>
                        <div v-html="content.content2"></div>
                    </section>
                    <div class="modal-card-foot">
                            <button type="submit" class="button is-secondary show-confirm" :class="{'width-auto': showCancelButton()}" v-text="content.submitButtonText"></button>
                            <button type="button" v-if="showCancelButton()" class="button width-auto has-background-white show-confirm" v-text="content.cancelButtonText" v-on:click="redirect(content.cancelRedirectUrl)"></button>                           
                    </div>
                </form>
            </div>
        </div>  
    </div>
</template>
<script>
    import VueCookies from 'vue-cookies';

    export default {
    props: ["display", 'content'],
    data() {
        return {
            active: false,
            agreeTerms: false
        };
    },
    methods: {
        show() {
            document.documentElement.classList.add('is-clipped');
            this.active = true;
        },
        closeForm() {
            VueCookies.set('AgreeTerms', true);
            this.active = false;
            document.documentElement.classList.remove('is-clipped');
            document.querySelector('.modal-card-body').parentElement.classList.remove('scroll-more');
        },
         redirect(url) {
            window.location.href = url;
        },
        showCancelButton() 
        {
            return (this.content.cancelButtonText && this.content.cancelRedirectUrl)
        }
    },
    created: function(){
        if(this.display){
            if(!VueCookies.get('AgreeTerms'))
            this.show();
        }
    }
    };
</script>