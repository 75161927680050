var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dropdown" }, [
    _c("div", { staticClass: "dropdown-trigger" }, [
      _c(
        "button",
        {
          staticClass: "button",
          class: {
            "filters-active":
              _vm.selectedship && _vm.selectedship !== "all-ships",
            "no-dropdown": _vm.ships.length == 1 || _vm.shipsmatch,
          },
          attrs: { disabled: _vm.apierror },
        },
        [
          !_vm.ships.length && !_vm.apierror
            ? _c("span", [
                _c("i", { staticClass: "fas fa-lg fa-spin fa-circle-notch" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.ships.length > 1 &&
          _vm.selectedShip === "all-ships" &&
          !_vm.shipsmatch
            ? _c("span", [_vm._v("Filter by Ship")])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedShip !== "all-ships" && !_vm.shipsmatch
            ? _c("span", [_vm._v(_vm._s(_vm.selectedShip))])
            : _vm._e(),
          _vm._v(" "),
          _vm.ships.length == 1
            ? _c("span", { domProps: { textContent: _vm._s(_vm.ships[0]) } })
            : _vm._e(),
          _vm._v(" "),
          _vm.shipsmatch
            ? _c("span", {
                class: { "is-size-7": _vm.ships.length > 1 },
                domProps: {
                  textContent: _vm._s(_vm.ships.join().replace(/[\,]/g, ", ")),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.ships.length > 1 && !_vm.shipsmatch
            ? _c("span", { staticClass: "icon is-small" }, [
                _c("i", {
                  staticClass: "fas fa-angle-down",
                  attrs: { "aria-hidden": "true" },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.ships.length > 1 && !_vm.shipsmatch
      ? _c(
          "ul",
          { staticClass: "dropdown-menu dropdown-content" },
          [
            _c(
              "li",
              {
                staticClass: "dropdown-item is-paddingless is-flex",
                class: _vm.selectedShip === "all-ships" ? "selected" : "",
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "dropdown-label",
                    attrs: { for: "all-ships" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedShip,
                          expression: "selectedShip",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "all-ships",
                        value: "all-ships",
                      },
                      domProps: {
                        checked: _vm._q(_vm.selectedShip, "all-ships"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            _vm.selectedShip = "all-ships"
                          },
                          function ($event) {
                            return _vm.updateSelectedShip(_vm.selectedShip)
                          },
                        ],
                      },
                    }),
                    _vm._v("\n                All Ships\n            "),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.ships, function (ship) {
              return _c(
                "li",
                {
                  key: ship.index,
                  staticClass: "dropdown-item is-paddingless is-flex",
                  class: ship === _vm.selectedShip ? "selected" : "",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "dropdown-label",
                      attrs: { for: ship.index },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedShip,
                            expression: "selectedShip",
                          },
                        ],
                        attrs: { type: "radio", id: ship.index },
                        domProps: {
                          value: ship,
                          checked: _vm._q(_vm.selectedShip, ship),
                        },
                        on: {
                          change: [
                            function ($event) {
                              _vm.selectedShip = ship
                            },
                            function ($event) {
                              return _vm.updateSelectedShip(_vm.selectedShip)
                            },
                          ],
                        },
                      }),
                      _vm._v(
                        "\n                " + _vm._s(ship) + "\n            "
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }